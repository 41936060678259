import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Footer } from "./footer/Footer";
import { Header } from "./header/Header";
import { Box } from "@material-ui/core";
const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "#E9EEF0",
        backgroundPosition: "50%",
        backgroundSize: "cover"
    },
    main: {
        flex: 1,
        paddingBottom: 20
    }
});
export const Content = (props: React.PropsWithChildren<{ background?: string }>): React.ReactElement => {
    const classes = useStyles();
    return (
        <Box
            className={classes.root}
            style={{ backgroundImage: props.background ? `url(${props.background})` : "none" }}
        >
            <Header />
            <main className={classes.main}>{props.children}</main>
            <Footer />
        </Box>
    );
};
