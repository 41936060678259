import React, { createContext, useReducer, Dispatch } from "react";
import { Actions } from "./actions";
import { userReducer, uiReducer } from "./reducer";
import { Config } from "../config/app";
import moment from "moment";
import { FilterMtrChartHistoricData } from "../components/filter/FilterMtrChartHistoric";
import { FilterMtrChartAnalyticData } from "../components/filter/FilterMtrChartAnalytic";
import { FilterChartStatusLogData } from "../components/filter/FilterChartStatusLog";
import { FilterShutdownData } from "../components/filter/FilterShutdown";
import { FilterRadarImageDisplayData } from "../components/ui/RadarImage/RadarImageDisplay";
import { FilterReportData } from "../components/ui/reporting/FilterReport";

export enum UserRole {
    Reader,
    Operator,
    Analyst,
    AnalystWithDataPermission,
    Admin
}

export interface User {
    name: string;
    token: string;
    isAdmin: boolean;
    role: UserRole;
}

export interface Dialog {
    title: string;
    message: string;
    buttons?: React.ReactElement[];
}
export interface UI {
    dialog?: Dialog;
    filter: {
        historic: FilterMtrChartHistoricData;
        analytic: FilterMtrChartAnalyticData;
        shutdown: FilterShutdownData;
        live: {
            radarImage: FilterRadarImageDisplayData;
        };
        report: FilterReportData;
        status: FilterChartStatusLogData;
    };
}

interface RootStore {
    user?: User;
    ui: UI;
}

const initialUIStore: UI = {
    dialog: undefined,
    filter: {
        historic: {
            startDate: moment(moment().utc()).add(-7, "days"),
            endDate: moment().utc()
        },
        analytic: {
            startDate: moment(moment().utc()).add(-7, "days"),
            endDate: moment().utc(),
            class: 3,
            minHeight: 0,
            maxHeight: 1000,
            resolution: 60
        },
        shutdown: {
            startDate: moment(moment().utc()).add(-7, "days"),
            endDate: moment().utc()
        },
        live: {
            radarImage: {
                date: moment().utc(),
                showOnlyFiltered: true
            }
        },
        report: {
            month: moment().utc().add(-1, "months").month(),
            year: moment().utc().year()
        },
        status: {
            dateFrom: moment().utc(),
            dateTo: moment().utc(),
            statusTypeIdPlot1: -1,
            statusTypeIdPlot2: -1,
        }
    }
};

const storeFromLocalStorage = (): RootStore => {
    const store = localStorage.getItem(Config.storageKey);
    if (store) {
        return { user: JSON.parse(store) as User, ui: initialUIStore };
    } else {
        return { user: undefined, ui: initialUIStore };
    }
};

export const AppContext = createContext<{
    state: RootStore;
    dispatch: Dispatch<Actions>;
}>({
    state: storeFromLocalStorage(),
    dispatch: () => null
});

const rootReducer = ({ user, ui }: RootStore, action: Actions): RootStore => ({
    user: userReducer(user, action),
    ui: uiReducer(ui, action)
});

export const AppProvider = (props: { children: React.ReactNode }): React.ReactElement => {
    const [state, dispatch] = useReducer(rootReducer, storeFromLocalStorage());
    return <AppContext.Provider value={{ state, dispatch }}>{props.children}</AppContext.Provider>;
};

export const getUserRole = (role: string): UserRole => {
    switch (role) {
        case "Admin":
            return UserRole.Admin;
        case "Operator":
            return UserRole.Operator;
        case "Reader":
            return UserRole.Reader;
        case "Analyst":
            return UserRole.Analyst;
        case "AnalystWithDataPermission":
            return UserRole.AnalystWithDataPermission;
    }
    return UserRole.Reader;
};
