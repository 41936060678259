import React, { useState, useEffect, useContext, useCallback } from "react";
import {
    Grid,
    Card,
    CardContent,
    Typography,
    makeStyles,
    useTheme,
    Theme,
    Checkbox,
    FormControlLabel
} from "@material-ui/core";
import { Line } from "react-chartjs-2";
import { ChartData, ChartOptions } from "chart.js";
import moment, { Moment } from "moment";
import { fetchPost } from "../../utils/fetch";
import { Mr1 } from "../../views/ProductMr1";
import { AppContext, UserRole } from "../../store/context";
import { CustomLegend } from "./CustomLegend/CustomLegend";
import { Trans, useTranslation } from "react-i18next";

import 'chart.js/auto'; // ADD THIS

interface ShutdownParameter {
    valueLocal: number;
    name: string;
    id: number;
}

interface Windmill {
    name: string;
    number: number;
}

interface MtrData {
    mtrThresholdShutdown: number[];
    mtrThresholdRestart: number[];
    mtrData: number[];
    time: string[];
    windmillShutdownParameter: ShutdownParameter[] | undefined;
    shutdownParameter: ShutdownParameter[] | undefined;
}
interface ChartFiltersStatus {
    threshold: boolean;
}
interface MtrChartLiveProps {
    radar: Mr1;
    selectedWindmillNumber: number;
    onChangeSelectedDate: (newSelectedDate: string) => void;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    item: {
        marginBottom: theme.spacing(2)
    }
}));

export const MtrChartLive = (props: MtrChartLiveProps): React.ReactElement => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const { state } = useContext(AppContext);

    const [mtrData, setmtrData] = useState<undefined | MtrData>(undefined);
    const [chartFilters, setChartFilters] = useState<ChartFiltersStatus>({
        threshold: false
    });

    useEffect(() => {
        const requestMtrData = (): void => {
            if (state.user) {
                fetchPost("get_mtr_graph_real.php", {
                    params: {
                        token: state.user.token,
                        databaseName: props.radar.name,
                        windmillNumber: props.selectedWindmillNumber.toString(),
                        dateFrom: moment(moment().utc()).add(-7, "days").format("YYYY-MM-DD HH:mm:ss"),
                        dateTo: moment().utc().format("YYYY-MM-DD HH:mm:ss")
                    }
                })
                    .then((response) => response.json())
                    .then((data) => {
                        setmtrData(data);
                    });
            }
        };
        requestMtrData();

        const id = setInterval(() => {
            requestMtrData();
        }, 60000);

        return (): void => clearInterval(id);
    }, [props.radar.name, props.selectedWindmillNumber, state.user, setmtrData]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onClickDataPoint = (event: any, data: any): void => {
        if (data[0]) {
            const dateClicked = data[0]._chart.tooltip._data.labels[data[0]._index];
            props.onChangeSelectedDate(dateClicked);
        }
    };

    const onChartFilterChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setChartFilters({ ...chartFilters, threshold: !chartFilters.threshold });
        },
        [chartFilters, setChartFilters]
    );

    if (mtrData === undefined) {
        return <div />;
    }

    let legendLabel = "Live MTR (" + t("mtr.timezone") + " UTC)";

    if (mtrData.shutdownParameter && 1 < mtrData.shutdownParameter?.length) {
        legendLabel +=
            " " +
            t("shutdownParameters.names." + mtrData.shutdownParameter[0].name) +
            ": " +
            mtrData.shutdownParameter[0].valueLocal +
            ", " +
            t("shutdownParameters.names." + mtrData.shutdownParameter[1].name) +
            ": " +
            mtrData.shutdownParameter[1].valueLocal;
    } else if (mtrData.windmillShutdownParameter && 1 < mtrData.windmillShutdownParameter?.length) {
        legendLabel +=
            " " +
            t("shutdownParameters.names." + mtrData.windmillShutdownParameter[0].name) +
            ": " +
            mtrData.windmillShutdownParameter[0].valueLocal +
            ", " +
            t("shutdownParameters.names." + mtrData.windmillShutdownParameter[1].name) +
            ": " +
            mtrData.windmillShutdownParameter[1].valueLocal;
    }

    const legend = [{ label: legendLabel, color: theme.palette.primary.light }];

    const data: ChartData<"line", { x: Moment, y: number }[]> = {
        datasets: [
            {
                xAxisID: "x",
                label: "Live MTR",
                fill: false,
                spanGaps: false,
                borderColor: theme.palette.primary.light,
                data: mtrData.mtrData.map((value, index) => ({
                    x: moment(mtrData.time[index], "DD.MM.YYYY HH:mm:ss"),
                    y: value
                }))
            }
        ]
    };

    if (chartFilters.threshold) {
        const colorDark = theme.palette.success.dark;
        data.datasets.push({
            xAxisID: "x",
            label: "ThresholdShutdown",
            fill: false,
            borderColor: colorDark,
            data: mtrData.mtrThresholdShutdown.map((value, index) => ({
                x: moment(mtrData.time[index], "DD.MM.YYYY HH:mm:ss"),
                y: value
            }))
        });
        legend.push({ label: t("mtr.thresholdShutdown"), color: colorDark });
        const colorLight = theme.palette.success.light;
        data.datasets.push({
            xAxisID: "x",
            label: "ThresholdRestart",
            fill: false,
            borderColor: colorLight,
            data: mtrData.mtrThresholdRestart.map((value, index) => ({
                x: moment(mtrData.time[index], "DD.MM.YYYY HH:mm:ss"),
                y: value
            }))
        });
        legend.push({ label: t("mtr.thresholdRestart"), color: colorLight });
    }

    const options: ChartOptions<"line"> = {

        elements: { point: { radius: 0 } },
        /*legend:
        {
            display: false,
        },*/
        scales: {
            x:
            {
                type: "time",
                time: {
                    unit: "day",
                    tooltipFormat: "DD.MM.YYYY HH:mm",
                    displayFormats: {
                        day: "DD.MM.YYYY",
                        hour: "DD.MM.YYYY HH:mm"
                    }
                },
                ticks: {
                    maxRotation: 45,
                    padding: 0,
                    labelOffset: 0,
                    maxTicksLimit: 12
                },
            }
        },
        plugins: {
            legend: { display: false },
            datalabels: { display: false }
        },
        onClick: onClickDataPoint
    };

    const showShutdownStuff =
        state.user?.role === UserRole.Operator ||
        state.user?.role === UserRole.Reader ||
        state.user?.role === UserRole.Admin;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={10}>
                <Card className={classes.item}>
                    <CardContent>
                        <Typography variant="overline">
                            Live <Trans>mtr.title</Trans>
                        </Typography>
                        {showShutdownStuff ? (
                            <Grid container direction="row" justifyContent="space-evenly">
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={chartFilters.threshold}
                                                onChange={onChartFilterChange}
                                                color="primary"
                                            />
                                        }
                                        label={t("mtr.threshold")}
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            ""
                        )}
                        <CustomLegend legend={legend} />
                        <Line data={data} options={options} />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};
