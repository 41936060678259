import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import React, {
  useRef,
  useEffect,
  useCallback,
  useState,
  ChangeEvent,
} from "react";
import {
  Button,
  makeStyles,
  Box,
  FormControlLabel,
  Switch,
  useTheme,
  Divider,
  Grid,
} from "@material-ui/core";
import { PdpData, DecimatedDataUnit, SignatureDataUnit } from "./PdpReader";
import { Line } from "react-chartjs-2";
import moment, { Moment } from "moment";
import { RadarImageGrid } from "./RadarImageGrid/RadarImageGrid";
import {
  Gradient,
  RadarImageGradient,
} from "./RadarImageGradient/RadarImageGradient";
import { ChartData, ChartOptions } from "chart.js";
import 'chartjs-adapter-moment';
import { Trans, useTranslation } from "react-i18next";

interface PdpViewerProps {
  data: PdpData;
}

const useStyles = makeStyles({
  root: {
    position: "relative",
  },
  buttonBox: {
    position: "absolute",
    width: 20,
    height: 20,

    borderRadius: "50%",
    border: "solid 2px black",

    backgroundColor: "#ffffff",
    opacity: 0.3,
    transition: "opacity .5s",
  },
  buttonText: {
    fontSize: 10,
    lineHeight: "normal",
    color: "black",
    top: "-50%",
    left: "-50%",
  },
  buttonBoxRed: {
    border: "solid 2px red",
  },
  buttonBoxGreen: {
    border: "solid 2px green",
  },
  button: {
    position: "inherit",
    height: "100%",
  },
});

const defaultGradient: Gradient[] = [
  { value: 0, color: { r: 0x00, g: 0x00, b: 0xff }, label: "-70" },
  { value: 0.2, color: { r: 0x1f, g: 0xb9, b: 0xda }, label: "-75" },
  { value: 0.4, color: { r: 0x7b, g: 0xba, b: 0x41 }, label: "-80" },
  { value: 0.6, color: { r: 0xfc, g: 0xe9, b: 0x0a }, label: "-85" },
  { value: 0.8, color: { r: 0xe4, g: 0x1d, b: 0x18 }, label: "-90" },
  { value: 1.0, color: { r: 0x6d, g: 0x15, b: 0x10 }, label: "-95" },
];

export const PdpViewer = (props: PdpViewerProps): React.ReactElement => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const [isShowingSignatures, setIsShowingSignatures] = useState<boolean>(true);
  const [isShowingClasses, setIsShowingClasses] = useState<boolean>(true);
  const [isShowingProtected, setIsShowingProtected] = useState<boolean>(false);
  const [isShowingOnlyValid, setIsShowingOnlyValid] = useState<boolean>(true);
  const [signatureData, setSignatureData] = useState<ChartData<"line", {x: Moment, y: number}[]>>();
  const [signatureDataOptions, setSignatureDataOptions] =
    useState<ChartOptions<"line">>();

  const maxValue = 65535.0;
  const gradientValues = useCallback(
    (values: number[]): Gradient[] => {
      const bottom = +props.data.decimatedDataUnits[0].signalCodeBottom;
      const top = +props.data.decimatedDataUnits[0].signalCodeTop;
      const g = defaultGradient;
      if (values.length === 2) {
        const min = bottom + values[0] * (top - bottom);
        const max = bottom + values[1] * (top - bottom);
        g[0].label = String(Math.floor(min));
        g[1].label = String(Math.floor((max - min) * 0.2 + min));
        g[2].label = String(Math.floor((max - min) * 0.4 + min));
        g[3].label = String(Math.floor((max - min) * 0.6 + min));
        g[4].label = String(Math.floor((max - min) * 0.8 + min));
        g[5].label = String(Math.floor(max));
      }
      return g;
    },
    [props.data.decimatedDataUnits]
  );
  const [valueBorders, setValueBorders] = React.useState<number[]>([0.25, 0.5]);
  const [gradient, setGradient] = useState<Gradient[]>(
    gradientValues(valueBorders)
  );

  const spaceLeft = 45;
  const spaceBottom = 105;
  const spaceRight = 40;
  const width = 800 - spaceLeft - spaceRight;
  const height = 600 - spaceBottom;

  const nbrDataUnits = +props.data.decimatedDataUnits.length;
  const nbrSignalValues = +props.data.decimatedDataUnits[0].signalValues.length;
  const startTime = +props.data.decimatedDataUnits[0].timestamp;
  const endTime =
    +props.data.decimatedDataUnits[props.data.decimatedDataUnits.length - 1]
      .timestamp;

  let lastUsedHeightIntervalIndex = 0;
  let lastStartHeight = +props.data.decimatedDataUnits[0].altitudeStart;
  let lastSteps = +props.data.decimatedDataUnits[0].altitudeStep;

  const mostUsedHeightIntervalMap = new Map();
  mostUsedHeightIntervalMap.set(lastUsedHeightIntervalIndex, 1);

  props.data.decimatedDataUnits.forEach(
    (dataUnit: DecimatedDataUnit, index: number): void => {
      const currentSteps = +dataUnit.altitudeStep;
      const currentStartHeight = +dataUnit.altitudeStart;
      if (
        currentSteps !== lastSteps ||
        currentStartHeight !== lastStartHeight
      ) {
        lastUsedHeightIntervalIndex = index;
        mostUsedHeightIntervalMap.set(lastUsedHeightIntervalIndex, 1);
      }
      mostUsedHeightIntervalMap.set(
        lastUsedHeightIntervalIndex,
        1 + mostUsedHeightIntervalMap.get(lastUsedHeightIntervalIndex)
      );
      lastSteps = currentSteps;
      lastStartHeight = currentStartHeight;
    }
  );

  let mostUsedHeightIntervalIndex = 0;
  let maxPeriod = -100000;

  mostUsedHeightIntervalMap.forEach((period: number, index: number): void => {
    if (maxPeriod < period) {
      mostUsedHeightIntervalIndex = index;
      maxPeriod = period;
    }
  });

  const steps =
    +props.data.decimatedDataUnits[mostUsedHeightIntervalIndex].altitudeStep;
  const startHeight =
    +props.data.decimatedDataUnits[mostUsedHeightIntervalIndex].altitudeStart;
  const endHeight =
    +props.data.decimatedDataUnits[mostUsedHeightIntervalIndex].altitudeStart +
    steps *
      +props.data.decimatedDataUnits[mostUsedHeightIntervalIndex].signalValues
        .length;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleValueBorderChange = useCallback(
    (event: ChangeEvent<{}>, newValue: number | number[]): void => {
      if (newValue instanceof Array && newValue.length === 2) {
        setGradient(gradientValues(newValue));
      }
      setValueBorders(newValue as number[]);
    },
    [setValueBorders, setGradient, gradientValues]
  );

  const onChangeShowSignatures = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setIsShowingSignatures(event.target.checked);
    setSignatureData(undefined);
  };

  const onChangeShowClasses = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setIsShowingClasses(event.target.checked);
  };

  const onChangeShowProtected = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setIsShowingProtected(event.target.checked);
  };

  const onChangeShowOnlyValid = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setIsShowingOnlyValid(event.target.checked);
  };

  const valueBorderText = useCallback(
    (value: number) => {
      const bottom = +props.data.decimatedDataUnits[0].signalCodeBottom;
      const top = +props.data.decimatedDataUnits[0].signalCodeTop;
      const valueInDbm = Math.floor(bottom + +value * (top - bottom));
      return `${valueInDbm}`;
    },
    [props.data.decimatedDataUnits]
  );

  const getColorForValue = useCallback(
    (value: number): number[] => {
      if (value < valueBorders[0]) {
        value = 0;
      } else if (value > valueBorders[1]) {
        value = 1;
      } else {
        value = (value - valueBorders[0]) / (valueBorders[1] - valueBorders[0]);
      }

      let i = 1;
      for (i = 1; i < gradient.length - 1; i++) {
        if (value < gradient[i].value) {
          break;
        }
      }
      const lower = gradient[i - 1];
      const upper = gradient[i];
      const range = upper.value - lower.value;
      const valueRange = (value - lower.value) / range;
      const valueLower = 1 - valueRange;
      const valueUpper = valueRange;
      const color = {
        r: Math.floor(lower.color.r * valueLower + upper.color.r * valueUpper),
        g: Math.floor(lower.color.g * valueLower + upper.color.g * valueUpper),
        b: Math.floor(lower.color.b * valueLower + upper.color.b * valueUpper),
      };
      return [color.r, color.g, color.b, 255];
    },
    [valueBorders, gradient]
  );

  const onSignatureClicked = useCallback(
    (id: number) => {
      let signatureIndex = -1;
      props.data.signatureDataUnits.forEach(
        (dataUnit: SignatureDataUnit, indexSignature): void => {
          if (id === dataUnit.id) {
            signatureIndex = indexSignature;
          }
        }
      );

      const signatureLabel =
        t("pdp.signature") +
        " " +
        id +
        "\n   " +
        t("pdp.class") +
        ": " +
        t("classifier." + props.data.signatureDataUnits[signatureIndex].class) +
        "\n   " +
        t("pdp.mtrFactor") +
        ": " +
        props.data.signatureDataUnits[signatureIndex].mtrFactCorrected.toFixed(
          2
        );

      const datasets: ChartData<"line", {x: Moment, y: number}[]> = {
        datasets: [
          {
            xAxisID: "x",
            yAxisID: "Mtr",
            label: signatureLabel,
            fill: false,
            borderColor: theme.palette.primary.light,
            borderWidth: 1,
            data: props.data.signatureDataUnits[
              signatureIndex
            ].signalValues.map((value, index) => ({
              x: moment(
                props.data.signatureDataUnits[signatureIndex].timestamp -
                  ((props.data.signatureDataUnits[signatureIndex].altitudeValues
                    .length -
                    index) *
                    1000.0) /
                    props.data.signatureDataUnits[signatureIndex]
                      .samplingFrequency
              ).utc(),
              y: value,
            })),
          },
        ],
      };

      setSignatureData(datasets);

      const options: ChartOptions<"line"> = {
        elements: { point: { radius: 0 } },
        scales: {
          x: {
            display: true,
            type: "time",
            time: {
              unit: "millisecond",
              tooltipFormat: "mm:ss",
              displayFormats: {
                millisecond: "HH:mm:ss",
              },
            },
            ticks: {
              maxRotation: 45,
              padding: 0,
              labelOffset: 0,
              maxTicksLimit: 12,
              autoSkip: false,
            },
          },
        },
        plugins: {
          legend: {
            display: true,
          },
          datalabels: {
            display: false,
          },
        },
      };

      setSignatureDataOptions(options);
    },
    [theme.palette.primary.light, props.data.signatureDataUnits, t]
  );

  const draw = useCallback(
    (context: CanvasRenderingContext2D, frameCount: number) => {
      if (frameCount < 2) {
        const newData = context.getImageData(
          0,
          0,
          context.canvas.width,
          context.canvas.height
        );
        const data = newData.data;

        props.data.decimatedDataUnits.forEach(
          (dataUnit: DecimatedDataUnit, indexX): void => {
            dataUnit.signalValues.forEach((value, indexY): void => {
              const valueFactor = value / maxValue;

              const color = getColorForValue(valueFactor);

              const indexTransformed =
                (+nbrSignalValues - indexY - 1) * nbrDataUnits * 4 + indexX * 4;
              data[indexTransformed] = color[0]; // - data[i]; // red
              data[indexTransformed + 1] = color[1]; //255 - data[i + 1]; // green
              data[indexTransformed + 2] = color[2]; //255 - data[i + 2]; // blue
              data[indexTransformed + 3] = color[3]; // alpha
            });
          }
        );

        if (isShowingSignatures) {
          props.data.signatureDataUnits.forEach(
            (dataUnit: SignatureDataUnit, indexSignature): void => {
              if (
                !isShowingOnlyValid ||
                dataUnit.validationType === "bio scatterer"
              ) {
                dataUnit.signalValues.forEach((value, indexSignal): void => {
                  const time =
                    dataUnit.timestamp -
                    ((dataUnit.signalValues.length - indexSignal) * 1000.0) /
                      dataUnit.samplingFrequency -
                    startTime;
                  const xPosition =
                    (time / (endTime - startTime)) * nbrDataUnits;

                  const altitude = dataUnit.altitudeValues[indexSignal];
                  const yPosition =
                    ((altitude - startHeight) / (endHeight - startHeight)) *
                    nbrSignalValues;

                  const indexTransformed =
                    (+nbrSignalValues - +Math.floor(yPosition) - 1) *
                      nbrDataUnits *
                      4 +
                    +Math.floor(xPosition) * 4;
                  data[indexTransformed] = 0;
                  data[indexTransformed + 1] = 0;
                  data[indexTransformed + 2] = 0;
                  data[indexTransformed + 3] = 0;
                });
              }
            }
          );
        }

        context.putImageData(newData, 0, 0);
      }
    },
    [
      endHeight,
      endTime,
      getColorForValue,
      isShowingSignatures,
      isShowingOnlyValid,
      nbrDataUnits,
      nbrSignalValues,
      props.data,
      startHeight,
      startTime,
    ]
  );

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas !== null) {
      canvas.style.width = width + "px";
      canvas.style.height = height + "px";
      const context = canvas.getContext("2d");

      let frameCount = 0;
      let animationFrameId = 0;

      const render = (): void => {
        frameCount++;
        if (context) {
          draw(context, frameCount);
        }
        animationFrameId = window.requestAnimationFrame(render);
      };
      render();

      return (): void => {
        window.cancelAnimationFrame(animationFrameId);
      };
    }
  }, [draw, height, width]);

  return (
    <div className={classes.root}>
      <div
        style={{
          width: width,
          height: height,
          marginLeft: spaceLeft,
          marginBottom: spaceBottom,
          marginRight: spaceRight,
          position: "relative",
        }}
      >
        <RadarImageGrid
          width={width}
          height={height}
          maxValues={{ x: moment(endTime), y: endHeight }}
          minValues={{ x: moment(startTime), y: startHeight }}
          steps={{ x: moment.duration(0.5, "minutes"), y: steps * 100 }}
          labels={{ y: t("pdp.altitude"), x: t("pdp.time") + " (UTC)" }}
        />
        <canvas width={nbrDataUnits} height={nbrSignalValues} ref={canvasRef} />
      </div>
      <RadarImageGradient
        gradient={gradient}
        width={spaceRight}
        height={height}
        label={t("pdp.intensity")}
      />
      {isShowingSignatures &&
        props.data.signatureDataUnits.map(
          (dataUnit: SignatureDataUnit, index) => {
            if (
              isShowingOnlyValid &&
              dataUnit.validationType !== "bio scatterer"
            ) {
              return <div />;
            }

            const timeAverage =
              dataUnit.timestamp -
              (dataUnit.altitudeValues.length * 500.0) /
                dataUnit.samplingFrequency -
              startTime;
            const xPosition =
              spaceLeft + (timeAverage / (endTime - startTime)) * width;

            let altitudeAverage = 0;
            dataUnit.altitudeValues.forEach((value) => {
              altitudeAverage += value;
            });
            altitudeAverage /= dataUnit.altitudeValues.length;

            const yPosition =
              (1 -
                (altitudeAverage - startHeight) / (endHeight - startHeight)) *
              height;

            let protectedClass = "";
            if (isShowingProtected) {
              if (dataUnit.isProtected) {
                protectedClass = classes.buttonBoxGreen;
              } else {
                protectedClass = classes.buttonBoxRed;
              }
            }

            let className = t("classifier.undefined");
            if ("" !== dataUnit.class) {
              className = t("classifier." + dataUnit.class);
            }

            return (
              <Box
                key={`buttonbox-${index}`}
                className={`${classes.buttonBox} ${protectedClass}`}
                style={{ top: yPosition - 10, left: xPosition - 10 }}
              >
                <Button
                  color="primary"
                  key={`button-${index}`}
                  className={classes.buttonText}
                  onClick={(): void => {
                    onSignatureClicked(dataUnit.id);
                  }}
                >
                  {isShowingClasses ? className : ""}
                </Button>
              </Box>
            );
          }
        )}
      <Typography id="range-slider" gutterBottom>
        <Trans>pdp.intensity</Trans>
      </Typography>
      <Slider
        min={0}
        max={1}
        step={0.01}
        value={valueBorders}
        onChange={handleValueBorderChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={valueBorderText}
        valueLabelFormat={valueBorderText}
      />
      <Grid container direction="row" justifyContent="space-evenly">
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={isShowingSignatures}
                onChange={onChangeShowSignatures}
                name="showSignatures"
                color="primary"
              />
            }
            label={t("pdp.showSignatures")}
          />
        </Grid>
        {isShowingSignatures ? (
          <>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={isShowingClasses}
                    onChange={onChangeShowClasses}
                    name="showClasses"
                    color="primary"
                  />
                }
                label={t("pdp.showClasses")}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={isShowingProtected}
                    onChange={onChangeShowProtected}
                    name="showProtected"
                    color="primary"
                  />
                }
                label={t("pdp.showProtected")}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={isShowingOnlyValid}
                    onChange={onChangeShowOnlyValid}
                    name="showOnlyValid"
                    color="primary"
                  />
                }
                label={t("pdp.showOnlyValid")}
              />
            </Grid>
          </>
        ) : (
          " "
        )}
      </Grid>
      {signatureData && (
        <>
          <br></br>
          <Divider />
          <br></br>
          <Line
            data={signatureData}
            options={signatureDataOptions}
          />
        </>
      )}
    </div>
  );
};
