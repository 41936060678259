import React from "react";
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Collapse,
    Typography,
    Grid,
    FormControlLabel,
    Divider,
    makeStyles,
    Theme,
    CircularProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    ListItemIcon
} from "@material-ui/core";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { emptyRouteParameters, AppRoutesPath, to } from "../../utils/routes";
import { Position } from "../../components/ui/Map";
import { Ac1Event } from "../ProductAc1";
import { DatabaseComment } from "../../components/ui/Dialogs/DatabaseComment";
import { Status, StatusIcon } from "../../components/ui/StatusLight";
import { ProductEvent } from "./Dashboard";

export interface Ac1Internal {
    id: string;
    letter: string;
    nameDatabase: string;
    active: boolean;
    comment: string;
    nameSite: string;
    position: Position;
    displayName: string;
    status: { ac1: Status; ac1Text: string; data: Status; dataText: string };
    events: ProductEvent[];
}

const statusItemWidth = 20;
const buttonItemWidth = 110;
const eventsItemWidth = 600;

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        minHeight: 460,
        alignItems: "center"
    },
    listHeaderStatus: {
        width: statusItemWidth,
        justifyContent: "right"
    },
    listHeaderButton: {
        width: 2 * buttonItemWidth + 20
    },
    listActionsGridItemButton: {
        display: "flex",
        alignItems: "center",
        width: buttonItemWidth,
        justifyContent: "flex-end"
    },
    listActionsGridItemStatus: {
        display: "flex",
        alignItems: "right",
        width: statusItemWidth,
        justifyContent: "left",
        marginTop: 10
    },
    listActionsGridItemEvents: {
        display: "flex",
        alignItems: "center",
        width: eventsItemWidth,
        justifyContent: "left"
    },
    listActionsGridItemEventClearButton: {
        display: "fixed",
        alignItems: "center",
        justifyContent: "flex-end"
    }
}));

interface Ac1DashboardProps {
    ac1s: Ac1Internal[];
    loaded: boolean;
    error: string;
    showOnlyActiveProducts: boolean;
    onHandleEvent: (nameDatabase: string, event: Ac1Event) => void;
    onSetDatabaseActiveClicked: (databaseName: string, active: boolean) => void;
}

export const Ac1Dashboard = (props: Ac1DashboardProps): React.ReactElement => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { loaded, error, ac1s } = props;

    const [openAc1Entries, setOpenAc1Entries] = React.useState(true);

    const handleopenAc1EntriesClick = (): void => {
        setOpenAc1Entries(!openAc1Entries);
    };

    if (loaded === false) {
        return (
            <Box className={classes.loadingContainer}>
                <CircularProgress />
            </Box>
        );
    }
    if (error !== "") {
        return (
            <Typography variant="body2" color="error">
                {error}
            </Typography>
        );
    }
    if (ac1s.length === 0) {
        return <Typography variant="body2"> {t("home.noRadarFound")}</Typography>;
    }

    ac1s.sort((ac1A: Ac1Internal, ac1B: Ac1Internal): number => {
        let ac1AEvents = 0;
        if (ac1A.events) {
            ac1A.events.forEach((event) => {
                if (event.active) {
                    ac1AEvents++;
                }
            });
        }
        if (ac1A.status.ac1 !== Status.Running) {
            ac1AEvents += 1000;
        }
        if (ac1A.status.data !== Status.Running) {
            ac1AEvents += 100;
        }
        let ac1BEvents = 0;
        if (ac1B.events) {
            ac1B.events.forEach((event) => {
                if (event.active) {
                    ac1BEvents++;
                }
            });
        }
        if (ac1B.status.ac1 !== Status.Running) {
            ac1BEvents += 1000;
        }
        if (ac1B.status.data !== Status.Running) {
            ac1BEvents += 100;
        }
        if (ac1AEvents < ac1BEvents) {
            return 1;
        } else if (ac1AEvents > ac1BEvents) {
            return -1;
        }

        return 0;
    });

    const activeEventsMap = new Map();
    const clearingEventsMap = new Map();

    ac1s.forEach((ac1) => {
        if (ac1.events) {
            ac1.events.forEach((event) => {
                if (event.active) {
                    if (event.handledByUser) {
                        if (clearingEventsMap.has(Number(ac1.id))) {
                            clearingEventsMap.get(Number(ac1.id)).push(event);
                        } else {
                            clearingEventsMap.set(Number(ac1.id), new Array<Ac1Event>(event));
                        }
                    } else {
                        if (activeEventsMap.has(Number(ac1.id))) {
                            activeEventsMap.get(Number(ac1.id)).push(event);
                        } else {
                            activeEventsMap.set(Number(ac1.id), new Array<Ac1Event>(event));
                        }
                    }
                }
            });
        }
    });

    return (
        <>
            <Grid container direction="row">
                <Typography variant="overline">{t("home.ac1Title")}</Typography>
                <Grid item className={classes.listHeaderButton}>
                    <Button onClick={handleopenAc1EntriesClick}>
                        {openAc1Entries ? <ExpandLess /> : <ExpandMore />}
                    </Button>
                </Grid>
                <Grid container spacing={10}>
                    <Grid item style={{ flex: 1 }} xs={10} />
                    <Grid item className={classes.listHeaderStatus}>
                        <Typography variant="caption">Radar</Typography>
                    </Grid>
                    <Grid item className={classes.listHeaderStatus}>
                        <Typography variant="caption">Data</Typography>
                    </Grid>
                    <Grid item className={classes.listHeaderButton} />
                </Grid>
            </Grid>
            <Collapse in={openAc1Entries} timeout="auto" unmountOnExit>
                <List>
                    {ac1s.map((ac1, index) => {
                        if (props.showOnlyActiveProducts && !ac1.active) {
                            return <React.Fragment key={index}></React.Fragment>;
                        }

                        return (
                            <>
                                <ListItem alignItems="center" key={index}>
                                    <ListItemAvatar>
                                        <Avatar>{ac1.letter}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={ac1.displayName} />
                                    <ListItemSecondaryAction>
                                        <Grid container spacing={10} direction="row">
                                            <DatabaseComment nameDatabase={ac1.nameDatabase} comment={ac1.comment} />
                                            <Grid item className={classes.listActionsGridItemStatus}>
                                                <StatusIcon status={ac1.status.ac1} tooltipText={ac1.status.ac1Text} />
                                            </Grid>
                                            <Grid item className={classes.listActionsGridItemStatus}>
                                                <StatusIcon
                                                    status={ac1.status.data}
                                                    tooltipText={ac1.status.dataText}
                                                />
                                            </Grid>
                                            <Grid item className={classes.listActionsGridItemButton}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    component={Link}
                                                    to={to(AppRoutesPath.aeroecologycamLive, { ...emptyRouteParameters, ac1id: ac1.id })}
                                                >
                                                    Detail
                                                </Button>
                                            </Grid>
                                            <Grid item className={classes.listActionsGridItemButton}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={ac1.active}
                                                            onClick={(): void => {
                                                                props.onSetDatabaseActiveClicked(
                                                                    ac1.nameDatabase,
                                                                    !ac1.active
                                                                );
                                                            }}
                                                            color="secondary"
                                                        />
                                                    }
                                                    label={"active"}
                                                />
                                            </Grid>
                                        </Grid>
                                    </ListItemSecondaryAction>
                                </ListItem>

                                {activeEventsMap.has(Number(ac1.id)) ? (
                                    <List style={{ marginLeft: 100, marginTop: -20, width: "80%", maxWidth: 600 }}>
                                        {activeEventsMap.get(Number(ac1.id)).map((activeEvent: Ac1Event) => {
                                            return (
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <StatusIcon status={Status.Error} tooltipText={""} />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        <Typography>{activeEvent.eventDescription}</Typography>
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            size="small"
                                                            onClick={(): void => {
                                                                props.onHandleEvent(ac1.nameDatabase, activeEvent);
                                                            }}
                                                        >
                                                            {t("administration.clearEvent")}
                                                        </Button>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                ) : (
                                    ""
                                )}
                                {clearingEventsMap.has(Number(ac1.id)) ? (
                                    <List style={{ marginLeft: 100, marginTop: -20, width: "80%", maxWidth: 574 }}>
                                        {clearingEventsMap.get(Number(ac1.id)).map((clearingEvent: Ac1Event) => {
                                            return (
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <StatusIcon status={Status.Error} tooltipText={""} />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        <Typography>{clearingEvent.eventDescription}</Typography>
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                        <CircularProgress size={12}></CircularProgress>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                ) : (
                                    ""
                                )}
                                {index % 4 === 0 && ac1s.length !== index ? <Divider /> : ""}
                            </>
                        );
                    })}
                </List>
            </Collapse>
        </>
    );
};
