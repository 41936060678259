import moment, { Moment, Duration } from "moment";
import React from "react";

interface RadarImageGridProps {
    width: number;
    height: number;
    maxValues: { x: Moment; y: number };
    minValues: { x: Moment; y: number };
    steps: { x: Duration; y: number };
    labels?: { x?: string; y?: string };
}

export const RadarImageGrid = (props: RadarImageGridProps): React.ReactElement => {
    const { width, height } = props;

    const maxValues: { x: number; y: number } = {
        x: props.maxValues.x.valueOf(),
        y: props.maxValues.y
    };
    const minValues: { x: number; y: number } = {
        x: props.minValues.x.valueOf(),
        y: props.minValues.y
    };
    const steps: { x: number; y: number } = {
        x: props.steps.x.asMilliseconds(),
        y: props.steps.y
    };

    const lineColor = "#979797";
    const lineStrokeWith = 1;

    const horizontalLines: { label: string; value: number }[] = [];
    //0 to max
    let i = 0;
    while (i * steps.y <= maxValues.y) {
        const value = steps.y * i;
        horizontalLines.push({
            label: String(value),
            value: value
        });
        i++;
    }
    //0 to min
    let j = 0;
    while (j * steps.y >= minValues.y) {
        const value = steps.y * j;
        horizontalLines.push({
            label: String(value),
            value: value
        });
        j--;
    }

    const verticalLines: { label: string; value: number }[] = [];
    for (let k = 0; k <= Math.abs(minValues.x - maxValues.x) / steps.x; k++) {
        verticalLines.push({
            label: moment(steps.x * k + minValues.x)
                .utc()
                .format("HH:mm:ss"),
            value: k * steps.x
        });
    }
    const absHorizontal = (input: number): number => (height / Math.abs(minValues.y - maxValues.y)) * input;
    const horizontalCenter = absHorizontal(maxValues.y);
    return (
        <svg
            style={{ position: "absolute", overflow: "visible" }}
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
        >
            {horizontalLines.map((line, index) => {
                const yPos = horizontalCenter - absHorizontal(line.value);
                return (
                    <g key={`h-${index}`} transform={`translate(0,${yPos})`}>
                        <line x1="0" y1="0" x2={width} y2="0" stroke={lineColor} strokeWidth={lineStrokeWith} />
                        <text x="-5" y="6" textAnchor="end">
                            {line.label}
                        </text>
                    </g>
                );
            })}
            {verticalLines.map((line, index) => {
                const xPos = (width / (maxValues.x - minValues.x)) * line.value;
                return (
                    <g key={`v-${index}`} transform={`translate(${xPos},0)`}>
                        <line x1="0" y1="0" x2="0" y2={height} stroke={lineColor} strokeWidth={lineStrokeWith} />
                        <text x="0" y={height + 20} textAnchor="start" transform={`rotate(45,0,${height + 20})`}>
                            {line.label}
                        </text>
                    </g>
                );
            })}
            {props.labels?.y && (
                <text x="-40" y={height / 2} textAnchor="middle" transform={`rotate(-90,-40,${height / 2})`}>
                    {props.labels?.y}
                </text>
            )}
            {props.labels?.x && (
                <text x={width / 2} y={height + 85} textAnchor="middle">
                    {props.labels?.x}
                </text>
            )}
        </svg>
    );
};
