import { User, UI } from "./context";
import { Actions } from "./actions";
import { Config } from "../config/app";

export enum UserTypes {
    Add = "ADD_USER",
    Remove = "REMOVE_USER"
}
export enum UITypes {
    ShowDialog = "SHOW_DIALOG",
    HideDialog = "HIDE_DIALOG",
    SetLiveRadarFilter = "SET_LIVE_RADAR_FILTER",
    SetHistoricFilter = "SET_HISTORIC_FILTER",
    SetAnalyticFilter = "SET_ANALYTIC_FILTER",
    SetShutdownFilter = "SET_SHUTDOWN_FILTER",
    SetReportFilter = "SET_REPORT_FILTER",
    SetStatusFilter = "SET_STATUS_FILTER"
}

export const userReducer = (state: User | undefined, action: Actions): User | undefined => {
    switch (action.type) {
        case UserTypes.Add: {
            const newState = {
                name: action.payload.name,
                token: action.payload.token,
                role: action.payload.role,
                isAdmin: action.payload.isAdmin
            };
            localStorage.setItem(Config.storageKey, JSON.stringify(newState));
            return newState;
        }
        case UserTypes.Remove: {
            localStorage.removeItem(Config.storageKey);
            return undefined;
        }
        default:
            return state;
    }
};
export const uiReducer = (state: UI, action: Actions): UI => {
    switch (action.type) {
        case UITypes.ShowDialog: {
            return {
                ...state,
                dialog: {
                    title: action.payload.title,
                    message: action.payload.message,
                    buttons: action.payload.buttons
                }
            };
        }
        case UITypes.HideDialog: {
            return { ...state, dialog: undefined };
        }
        case UITypes.SetLiveRadarFilter: {
            const newState = { ...state };
            newState.filter.live.radarImage = action.payload;
            return newState;
        }
        case UITypes.SetHistoricFilter: {
            const newState = { ...state };
            newState.filter.historic = action.payload;
            return newState;
        }
        case UITypes.SetAnalyticFilter: {
            const newState = { ...state };
            newState.filter.analytic = action.payload;
            return newState;
        }
        case UITypes.SetShutdownFilter: {
            const newState = { ...state };
            newState.filter.shutdown = action.payload;
            return newState;
        }
        case UITypes.SetReportFilter: {
            const newState = { ...state };
            newState.filter.report = action.payload;
            return newState;
        }
        case UITypes.SetStatusFilter: {
            const newState = { ...state };
            newState.filter.status = action.payload;
            return newState;
        }
        default:
            return state;
    }
};
