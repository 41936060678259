import React, { useEffect, useContext } from "react";
import { Mr1 } from "../../../views/ProductMr1";

import { fetchPost } from "../../../utils/fetch";
import { Moment } from "moment";
import { AppContext } from "../../../store/context";

export interface WindmillShutdownParameter {
    valueLocal: number;
    name: string;
    id: number;
}

export interface WindmillPerformanceValues {
    sumRadarCausedShutdown: number;
    sumPerformance: number;
    sumPowerLoss: number;
    id: number;
}
export interface Windmill {
    observationTime: number;
    shutdownTime: number;
    name: string;
    number: number;
    windmillShutdownParameter: WindmillShutdownParameter[];
    windmillPerformanceValues: WindmillPerformanceValues;
}

export interface OverallRadarData {
    operationTime: number;
    blindTime: number;
    observationTime: number;
    shutdownTime: number;
    shutdownTime2: number;
    averageMtr: number;
    sumMtr: number;
    altitudeStart: number;
    altitudeStop: number;
    mtrThresholdShutdownDay: number;
    mtrThresholdShutdownNight: number;
    mtrThresholdRestartDay: number;
    mtrThresholdRestartNight: number;
    mtrThresholdShutdownDayBadVisibility: number;
    mtrThresholdShutdownNightBadVisibility: number;
    mtrThresholdRestartDayBadVisibility: number;
    mtrThresholdRestartNightBadVisibility: number;
    sumPowerLoss: number;
    windmills: Windmill[];
}

interface ReportDataOverviewProps {
    radar: Mr1;
    dateFrom: Moment;
    dateTo: Moment;
    onChangeData: (newData: OverallRadarData) => void;
}

export const ReportDataOverview = React.memo(
    (props: ReportDataOverviewProps): React.ReactElement => {
        const { state } = useContext(AppContext);

        useEffect(() => {
            const requestOverallRadarData = (): void => {
                if (state.user) {
                    fetchPost("get_overall_radar_status.php", {
                        params: {
                            token: state.user.token,
                            databaseName: props.radar.name,
                            dateFrom: props.dateFrom.format("YYYY-MM-DD HH:mm:ss"),
                            dateTo: props.dateTo.format("YYYY-MM-DD HH:mm:ss")
                        }
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            props.onChangeData(data);
                        });
                }
            };
            requestOverallRadarData();

            return;
        }, [props.radar.name, state.user, props]);

        return <div />;
    }
);
