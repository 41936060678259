import React, { useState, useEffect, useCallback, useContext } from "react";
import { Mr1 } from "../ProductMr1";
import { ReportMonthly } from "../../components/ui/reporting/ReportMonthly";
import { ReportAnnual } from "../../components/ui/reporting/ReportAnnual";
import { FilterReport, FilterReportData, ReportType } from "../../components/ui/reporting/FilterReport";
import { Grid, makeStyles, Card, CardContent, Theme } from "@material-ui/core";
import { AppContext } from "../../store/context";
import { Trans } from "react-i18next";
import { useOutletContext } from "react-router";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    content: {
        overflow: "hidden"
    },
    circularProgress: {
        position: "absolute",
        top: "50%",
        left: "50%"
    },
    item: {
        marginBottom: theme.spacing(2)
    }
}));

export const Reporting = (): React.ReactElement => {
    const { state } = useContext(AppContext);
    const classes = useStyles();
    const radar = useOutletContext< Mr1 >();

    const [isOpen, setisOpen] = useState(false);
    const [isFilterActive, setisFilterActive] = useState<boolean>(true);
    const [filterData, setfilterDate] = useState<FilterReportData>(state.ui.filter.report);
    const [reportType, setreportType] = useState<ReportType>(ReportType.Undefined);

    useEffect(() => {
        setisOpen(false);
        setisOpen(true);
        return (): void => setisOpen(false);
    }, []);

    const changeFilterData = useCallback(
        (newFilterData: FilterReportData, type: ReportType): void => {
            setfilterDate(newFilterData);
            setreportType(type);
            setisFilterActive(false);
        },
        [setfilterDate, setreportType]
    );

    const addFilter = (): React.ReactElement => {
        return (
            <Grid className={classes.content} item xs={12} md={2}>
                <FilterReport radar={radar} onChangeFilterData={changeFilterData} isActive={isFilterActive} />
            </Grid>
        );
    };

    if (reportType === ReportType.Monthly) {
        return (
            <>
                {addFilter()}
                {isOpen && (
                    <Grid className={classes.content} item xs={12} md={8}>
                        <ReportMonthly radar={radar} filterData={filterData} />
                    </Grid>
                )}
            </>
        );
    } else if (reportType === ReportType.Annual) {
        return (
            <>
                {addFilter()}
                {isOpen && (
                    <Grid className={classes.content} item xs={12} md={8}>
                        <ReportAnnual radar={radar} filterData={filterData} />
                    </Grid>
                )}
            </>
        );
    }

    if (isFilterActive === false) {
        setisFilterActive(true);
    }

    return (
        <>
            {addFilter()}
            <Grid className={classes.content} item xs={12} md={8}>
                <Card className={classes.item}>
                    <CardContent>
                        <Trans>reporting.chooseReportType</Trans>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
};
