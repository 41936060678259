import React, { useContext, useState, useCallback } from "react";
import {
    Container,
    Grid,
    CardContent,
    Card,
    makeStyles,
    Theme,
    Typography,
    Button,
    Box,
    TextField,
    InputLabel
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { AppContext } from "../../store/context";
import NativeSelect from "@material-ui/core/Select";
import { Trans, useTranslation } from "react-i18next";
import { fetchPost } from "../../utils/fetch";
import { Config } from "../../config/app";

interface FormData {
    databaseName: { value: string; error: boolean };
    username: { value: string; error: boolean };
    password: { value: string; error: boolean };
    displayName: { value: string; error: boolean };
    type: string;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    root: {
        marginTop: 30
    },
    logo: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: "100%"
    },
    inputContainer: {
        marginBottom: theme.spacing(2),
        width: "100%"
    },
    fullWidth: {
        width: "100%"
    }
}));

export const AddDatabase = (): React.ReactElement => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { state } = useContext(AppContext);

    const [form, setForm] = useState<FormData>({
        databaseName: { value: "", error: false },
        username: { value: "", error: false },
        password: { value: "", error: false },
        displayName: { value: "", error: false },
        type: ""
    });
    const [error, setError] = useState<string>("");
    const [success, setSuccess] = useState<string>("");

    const onChangeInput = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const fieldKey = event.currentTarget.dataset.field;
            if (fieldKey) {
                setForm({ ...form, [fieldKey]: { value: event.target.value, error: false } });
            }

            setSuccess("");
            setError("");
        },
        [setForm, form]
    );

    const handleTypeChange = (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ): void => {
        setForm({ ...form, type: event.currentTarget.value as string });
    };

    const onAddDatabaseClicked = useCallback(() => {
        setSuccess("");
        setError("");
        if (form.databaseName.value === "") {
            setError(t("error.field_missing", { field: t("administration.databaseName") }));
            setForm({ ...form, databaseName: { ...form.databaseName, error: true } });
            return;
        }
        if (form.username.value === "") {
            setError(t("error.field_missing", { field: t("login.username") }));
            setForm({ ...form, username: { ...form.username, error: true } });
            return;
        }
        if (form.password.value === "") {
            setError(t("error.field_missing", { field: t("login.password") }));
            setForm({ ...form, password: { ...form.password, error: true } });
            return;
        }
        if (form.displayName.value === "") {
            setError(t("error.field_missing", { field: t("administration.displayName") }));
            setForm({ ...form, displayName: { ...form.displayName, error: true } });
            return;
        }
        if (form.type === "") {
            setError(t("error.field_missing", { field: t("administration.databaseType") }));
            setForm({ ...form, type: form.type });
            return;
        }
        if (state.user) {
            fetchPost("add_database.php", {
                params: {
                    token: state.user.token,
                    databaseName: form.databaseName.value,
                    username: form.username.value,
                    password: form.password.value,
                    displayName: form.displayName.value,
                    product: form.type
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data) {
                        if (data.success) {
                            setSuccess(t("administration.addDatabaseSuccess"));
                            setForm({
                                databaseName: { value: "", error: false },
                                username: { value: "", error: false },
                                password: { value: "", error: false },
                                displayName: { value: "", error: false },
                                type: ""
                            });
                            return;
                        }
                    }
                    setError(data.error || t("error.couldNotAddDatabase"));
                })
                .catch((error) => {
                    setError(error.message);
                });
        }
    }, [form, t, state.user]);

    return (
        <Container maxWidth="xs" className={classes.root}>
            <Card>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <img
                                src="../../images/swiss-birdradar_logo.png"
                                className={classes.logo}
                                alt={Config.appname}
                            />
                        </Grid>
                        <Box marginBottom={2}>
                            <Grid item xs={12}>
                                <Trans>administration.addDatabaseFirstStep</Trans>
                            </Grid>
                            <Grid item xs={12}>
                                <a
                                    href="https://konsoleh.your-server.de/index.php"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Hetzner console
                                </a>
                            </Grid>
                        </Box>
                        <Grid item xs={12}>
                            <Box marginBottom={2}>
                                <Trans>administration.addDatabaseSecondStep</Trans>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box marginBottom={2}>
                                <Trans>administration.addDatabaseThirdStep</Trans>
                            </Box>
                        </Grid>
                        <Grid item xs={12} className={classes.inputContainer}>
                            <TextField
                                label={t("administration.databaseName")}
                                inputProps={{ "data-field": "databaseName" }}
                                value={form.databaseName.value}
                                onChange={onChangeInput}
                                fullWidth
                                error={form.databaseName.error}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.inputContainer}>
                            <TextField
                                label={t("login.username")}
                                inputProps={{ "data-field": "username" }}
                                value={form.username.value}
                                onChange={onChangeInput}
                                fullWidth
                                error={form.username.error}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.inputContainer}>
                            <TextField
                                label={t("login.password")}
                                inputProps={{ "data-field": "password" }}
                                value={form.password.value}
                                onChange={onChangeInput}
                                fullWidth
                                error={form.password.error}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.inputContainer}>
                            <TextField
                                label={t("administration.displayName")}
                                inputProps={{ "data-field": "displayName" }}
                                value={form.displayName.value}
                                onChange={onChangeInput}
                                fullWidth
                                error={form.displayName.error}
                            />
                        </Grid>
                        <Grid item className={classes.inputContainer}>
                            <Box marginBottom={1}>
                                <FormControl className={classes.fullWidth}>
                                    <InputLabel htmlFor="typeSelectLabel">
                                        <Trans>administration.databaseType</Trans>
                                    </InputLabel>
                                    <NativeSelect
                                        native
                                        label={t("administration.databaseType")}
                                        value={form.type}
                                        labelId="typeSelectLabel"
                                        id="typeSelectLabel"
                                        onChange={handleTypeChange}
                                    >
                                        <option label="" key={-1} value={""} />
                                        <option label={"MR1"} key={1} value={"mr1"} />
                                        <option label={"AC1"} key={2} value={"ac1"} />
                                    </NativeSelect>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} className={classes.inputContainer}>
                            <Button variant="contained" color="primary" onClick={onAddDatabaseClicked} fullWidth>
                                <Trans>administration.addDatabase</Trans>
                            </Button>
                        </Grid>
                    </Grid>

                    {error && (
                        <Grid container>
                            <Grid item>
                                <Typography variant="body1" color="error">
                                    {error}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    {success && (
                        <Grid container>
                            <Grid item>
                                <Typography variant="body1" color="primary">
                                    {success}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </CardContent>
            </Card>
        </Container>
    );
};
