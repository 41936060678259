import React, { useEffect, useContext, useState } from "react";
import { Card, CardContent, Grid, CircularProgress, makeStyles, Theme, Slider, Typography } from "@material-ui/core";
import { Ac1 } from "../ProductAc1";
import { AppContext } from "../../store/context";
import { fetchPost } from "../../utils/fetch";
import { Trans } from "react-i18next";

interface RegionOfInterests {
    height: number;
    left: number;
    top: number;
    width: number;
    area: number;
    image: string;
}

interface TrackAnimation {
    backgroundWidth: number;
    backgroundHeight: number;
    regionOfInterests: RegionOfInterests[];
    trackDataVersion: number;
}

interface Ac1TrackAnimationProps {
    trackId: number;
    ac1: Ac1;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    card: {
        minWidth: 450,
        minHeight: 510,
        marginBottom: theme.spacing(2)
    },
    circularProgress: {
        position: "inherit",
        top: "40%",
        left: "20%"
    },
    image: {
        top: "40%",
        left: "20%"
    }
}));

export const Ac1TrackAnimation = (props: Ac1TrackAnimationProps): React.ReactElement => {
    const { state } = useContext(AppContext);
    const classes = useStyles();

    const [selectedTrackAnimation, setselectedTrackAnimation] = useState<undefined | TrackAnimation>(undefined);
    const [selectedFrame, setselectedFrame] = useState<number>(0);
    const [animationSpeed, setanimationSpeed] = useState<number>(70);

    useEffect(() => {
        const requestTrackAnimationData = (): void => {
            if (state.user) {
                setselectedTrackAnimation(undefined);
                setselectedFrame(0);

                fetchPost("get_track_animation_ac1.php", {
                    params: {
                        token: state.user.token,
                        databaseName: props.ac1.name,
                        trackId: props.trackId.toString()
                    }
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            setselectedTrackAnimation(data);
                        }
                    });
            }
        };

        requestTrackAnimationData();

        return;
    }, [props.trackId, props.ac1.name, state.user]);

    useEffect(() => {
        const updateSelectedFrame = (): void => {
            if (selectedTrackAnimation && selectedTrackAnimation.regionOfInterests.length > 0) {
                if (selectedFrame < selectedTrackAnimation.regionOfInterests.length - 1) {
                    setselectedFrame(selectedFrame + 1);
                } else {
                    setselectedFrame(0);
                }
            } else {
                setselectedFrame(0);
            }
        };

        let animationInterval = 10 * (101 - animationSpeed);
        if (1000 < animationInterval) {
            animationInterval = 100000000000;
        }
        const id = setInterval(() => {
            updateSelectedFrame();
        }, animationInterval);

        return (): void => clearInterval(id);
    }, [selectedTrackAnimation, selectedFrame, animationSpeed]);

    const handleAnimationSpeedChange = (event: React.ChangeEvent<{}>, value: number | number[]): void => {
        setanimationSpeed(value as number);
    };

    const handleAnimationFrameChange = (event: React.ChangeEvent<{}>, value: number | number[]): void => {
        setselectedFrame(value as number);
    };

    if (selectedTrackAnimation === undefined) {
        return (
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="overline">
                        <Trans>ac1.titleAnimation</Trans>
                    </Typography>
                    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                        <Grid item xs={12}>
                            <CircularProgress></CircularProgress>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }

    if (
        selectedTrackAnimation.regionOfInterests.length === 0 ||
        selectedTrackAnimation.regionOfInterests.length <= selectedFrame
    ) {
        return (
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="overline">
                        <Trans>ac1.titleAnimation</Trans>
                    </Typography>
                    <Typography variant="body1">
                        <Trans>ac1.noTrackAnimation</Trans>
                    </Typography>
                </CardContent>
            </Card>
        );
    }

    return (
        <Card className={classes.card}>
            <CardContent className={classes.circularProgress}>
                <Typography variant="overline">
                    <Trans>ac1.titleAnimation</Trans>
                </Typography>
                <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                        <img
                            className={classes.image}
                            width={450}
                            height={400}
                            alt=""
                            src={
                                "data:image/png;base64," + selectedTrackAnimation.regionOfInterests[selectedFrame].image
                            }
                        />
                        <Slider
                            aria-label="AnimatioFrame"
                            value={selectedFrame}
                            min={0}
                            max={selectedTrackAnimation.regionOfInterests.length - 1}
                            onChange={handleAnimationFrameChange}
                        />

                        <Grid container direction="row">
                            <Typography variant="body1">
                                <Trans>ac1.animationSpeed</Trans>
                            </Typography>
                            <Slider
                                aria-label="AnimationSpeed"
                                value={animationSpeed}
                                onChange={handleAnimationSpeedChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
