import React, { useRef, useState, useLayoutEffect, useMemo, useCallback, useEffect } from "react";
import { Image } from "./RadarImage";
import { ThumbnailImage } from "./ThumbnailImage";
import { Grid, makeStyles, Typography, Theme } from "@material-ui/core";
import { Config } from "../../../config/app";

interface RadarImageSliderThumbnail {
    image: Image;
    visible: boolean;
}

interface RadarImageSliderProps {
    radarName: string;
    radarImages: Image[];
    getPdpViaFtp: boolean;
    showThumbs: number;
    onClickImage(name: string): void;
    currentImage?: string;
}
const useStyles = makeStyles<Theme>((theme: Theme) => ({
    root: {
        height: 120 + 40,
        width: "100%",
        overflowY: "hidden",
        overflowX: "auto",
        padding: 2,
        scrollBehavior: "smooth",
        scrollSnapType: "x mandatory"
    },
    container: {
        display: "flex",
        flexDirection: "row"
    },
    thumbContainer: {
        flex: 1,
        justifyContent: "center"
    },
    image: {
        flexGrow: 0,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        height: 132,
        textAlign: "center",
        cursor: "pointer",
        justifyContent: "space-between",
        borderRadius: 5,
        margin: 0,
        "& img": {
            marginTop: -15,
            width: "100%"
        },
        "& span": {
            minHeight: 38
        }
    },
    selected: {
        border: "2px solid",
        borderColor: theme.palette.primary.main,
        padding: 15
    },
    notSelected: {
        border: "2px solid",
        borderStyle: "none",
        padding: 15
    }
}));

const calculateVisibilityList = (
    images: Image[],
    scrollPos: number,
    imageWidth: number,
    showThumbs: number
): RadarImageSliderThumbnail[] => {
    return images.map((image, index) => {
        let visible = false;
        if (scrollPos - imageWidth <= index * imageWidth && scrollPos + showThumbs * imageWidth >= index * imageWidth) {
            visible = true;
        }
        return { image, visible };
    });
};

export const RadarImageSlider = (props: RadarImageSliderProps): React.ReactElement => {
    const [imageWidth, setImageWidth] = useState(0);
    const [list, setList] = useState<RadarImageSliderThumbnail[]>([]);
    const classes = useStyles();
    const ref = useRef<HTMLDivElement>(null);
    const thumbRef = React.useRef<HTMLDivElement>(null);

    const [currentImage, setCurrentImage] = useState<string | undefined>(undefined);

    useLayoutEffect(() => {
        if (ref.current) {
            setImageWidth((ref.current.offsetWidth - 10) / props.showThumbs);
        }
    }, [setImageWidth, props.showThumbs, ref]);

    useEffect(() => {
        if (thumbRef.current) {
            thumbRef.current.scrollIntoView();
        }
    }, []);

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollLeft = imageWidth * props.radarImages.length + 15;
        }
    }, [imageWidth, props.radarImages]);

    useEffect(() => {
        if (props.currentImage) {
            setCurrentImage(props.currentImage);
        }
    }, [props.currentImage]);

    const onScroll = useCallback(
        (event: React.UIEvent<HTMLElement>) => {
            const scrollPos = event.currentTarget.scrollLeft;
            const visList = calculateVisibilityList(props.radarImages, scrollPos, imageWidth, props.showThumbs);
            setList(visList);
        },
        [setList, props.radarImages, imageWidth, props.showThumbs]
    );

    useMemo(() => {
        setList(calculateVisibilityList(props.radarImages, 0, imageWidth, props.showThumbs));
    }, [setList, props.radarImages, imageWidth, props.showThumbs]);

    const onThumbClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            if (event.currentTarget.dataset.name) {
                props.onClickImage(event.currentTarget.dataset.name);
            }
        },
        [props]
    );

    return (
        <Grid className={`${classes.root} frame`} ref={ref} onScroll={onScroll}>
            <div className={classes.container}>
                {imageWidth !== 0 &&
                    list.map((item, index) => (
                        <div
                            key={item.image.name}
                            ref={currentImage === item.image.name ? thumbRef : undefined}
                            className={`${classes.image} ${
                                currentImage === item.image.name
                                    ? classes.selected
                                    : item.visible
                                    ? classes.notSelected
                                    : ""
                            }`}
                            style={{ flexBasis: imageWidth }}
                            onClick={onThumbClick}
                            data-name={item.image.name}
                        >
                            {item.visible && (
                                <>
                                    {props.getPdpViaFtp ? (
                                        <>
                                            <ThumbnailImage radarName={props.radarName} fileName={item.image.name} />
                                        </>
                                    ) : (
                                        <img
                                            src={`${Config.thumbnails}${props.radarName}/${item.image.name}`}
                                            alt={item.image.name}
                                        />
                                    )}
                                    <Typography variant="caption">
                                        {item.image.dateTime + (item.image.isFiltered ? "" : "")}
                                    </Typography>
                                </>
                            )}
                        </div>
                    ))}
            </div>
        </Grid>
    );
};
