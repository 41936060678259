import React from "react";

import { ShutdownParameters } from "../../components/ui/shutdown/ShutdownParameters";
import { ShutdownTimes } from "../../components/ui/shutdown/ShutdownTimes";
import { Grid } from "@material-ui/core";
import { Mr1 } from "../ProductMr1";
import { useOutletContext } from "react-router";

export const Shutdown = (): React.ReactElement => {
    const radar = useOutletContext< Mr1 >();

    return (
        <Grid item xs={12} md={10}>
            <ShutdownTimes radar={radar}></ShutdownTimes>
            <Grid container direction="row">
                <Grid item md={2} />
                <Grid item md={10}>
                    <ShutdownParameters radar={radar}></ShutdownParameters>
                </Grid>
            </Grid>
        </Grid>
    );
};
