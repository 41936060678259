import React, { useContext, useState, useCallback, useEffect } from "react";
import {
    Box,
    Card,
    CardContent,
    TextField,
    Typography,
    Button,
    Grid,
    makeStyles,
    Theme,
    CircularProgress,
    InputLabel,
    FormControl
} from "@material-ui/core";
import { AppContext, UserRole } from "../../../store/context";
import { Trans, useTranslation } from "react-i18next";
import { fetchPost } from "../../../utils/fetch";
import { Mr1 } from "../../../views/ProductMr1";

import NativeSelect from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

interface Parameters {
    names: string[];
    descriptions: string[];
    valuesRemote: number[];
    valuesLocal: number[];
}

interface WindmillParameter {
    name: string;
    number: number;
    parameters: Parameters;
}

interface ParameterData {
    default: Parameters;
    windmills: WindmillParameter[];
}

interface FormData {
    names: string[];
    valuesRemote: string[];
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    root: {
        marginTop: 150
    },
    item: {
        marginBottom: theme.spacing(2)
    },
    header: {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.common.white
    },
    logo: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: "100%"
    },
    form: {
        width: "100%"
    },
    inputContainer: {
        marginBottom: theme.spacing(2)
    },
    fullWidth: {
        width: "100%"
    }
}));

interface ShutdownParametersProps {
    radar: Mr1;
}

export const ShutdownParameters = (props: ShutdownParametersProps): React.ReactElement => {
    const classes = useStyles();
    const { state } = useContext(AppContext);
    const { t } = useTranslation();
    const [form, setForm] = useState<FormData>({
        names: [],
        valuesRemote: []
    });
    const [error, setError] = useState<string>("");
    const [parameters, setparameters] = useState<undefined | ParameterData>(undefined);
    const [selectedParametersId, setSelectedParametersId] = useState<number>(-1);

    const onChangeInput = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const fieldKey = event.currentTarget.dataset.field;

            if (fieldKey) {
                const found = form.names.find((element) => element === fieldKey);

                const newForm = Object.assign({}, form);

                if (found !== undefined) {
                    const index = newForm.names.indexOf(fieldKey);
                    newForm.valuesRemote[index] = event.target.value;
                } else {
                    newForm.names = newForm.names.concat(fieldKey);
                    newForm.valuesRemote = newForm.valuesRemote.concat(event.target.value);
                }
                setForm(newForm);
            }
        },
        [setForm, form]
    );

    useEffect(() => {
        const requestShutdownParametersData = (): void => {
            if (state.user) {
                fetchPost("get_shutdown_parameters.php", {
                    params: {
                        token: state.user.token,
                        databaseName: props.radar.name
                    }
                })
                    .then((response) => response.json())
                    .then((data) => {
                        setparameters(data);
                    })
                    .catch((error) => {
                        setError(error.message);
                    });
            }
        };
        requestShutdownParametersData();

        const id = setInterval(() => {
            requestShutdownParametersData();
        }, 10000);

        return (): void => clearInterval(id);
    }, [state.user, setparameters, setError, props.radar.name]);

    const sendShutdownParametersData = useCallback((): void => {
        if (state.user) {
            fetchPost("send_shutdown_parameters.php", {
                params: {
                    token: state.user.token,
                    databaseName: props.radar.name,
                    names: JSON.stringify(form.names),
                    valuesRemote: JSON.stringify(form.valuesRemote),
                    windmillId: selectedParametersId.toString()
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log("Successfully sent parameters");
                })
                .catch((error) => {
                    setError(error.message);
                });
        }
    }, [state.user, props.radar.name, form, setError, selectedParametersId]);

    const showError = (error: string): React.ReactElement => {
        return (
            <Card className={classes.item}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box marginBottom={2}>
                                <Typography variant="h6">
                                    <Trans>shutdownParameters.title</Trans>
                                </Typography>
                                <Typography variant="body2">{error}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <CircularProgress></CircularProgress>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    };

    if (state.user === undefined) {
        return showError(t("error.noUser"));
    }

    if (parameters === undefined) {
        return showError(t("error.loading") + "...");
    }
    if (error !== "") {
        return showError(error);
    }

    const handleSelectedParametersChange = (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ): void => {
        const windmillsId = event.currentTarget.value as number;

        setSelectedParametersId(windmillsId);
    };

    let selectedParameters = parameters.default;
    if (0 <= selectedParametersId && selectedParametersId < parameters.windmills.length) {
        const index = parameters.windmills.findIndex(
            (parameter: WindmillParameter) => (parameter.number as number) === selectedParametersId
        );
        selectedParameters = parameters.windmills[index].parameters;
    }

    if (
        selectedParameters.names.length !== selectedParameters.descriptions.length ||
        selectedParameters.names.length !== selectedParameters.valuesLocal.length ||
        selectedParameters.names.length !== selectedParameters.valuesRemote.length
    ) {
        return showError(t("error.invalidData"));
    }

    const listParameters: {
        name: string;
        description: string;
        valueLocal: number;
        valueRemote: number;
    }[] = selectedParameters.names.map((name, index) => {
        return {
            name,
            description: selectedParameters.descriptions[index],
            valueLocal: selectedParameters.valuesLocal[index],
            valueRemote: selectedParameters.valuesRemote[index]
        };
    });

    const hasShutdownPermissions = state.user?.role === UserRole.Operator || state.user?.role === UserRole.Admin;

    return (
        <Card className={classes.item}>
            <CardContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Box marginBottom={2}>
                            <Typography variant="h6">
                                <Trans>shutdownParameters.title</Trans>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        {0 < parameters.windmills.length && (
                            <Box marginTop={1} marginLeft={2} marginBottom={3}>
                                <FormControl className={classes.fullWidth}>
                                    <InputLabel htmlFor="windmillSelect">
                                        <Trans>shutdownParameters.windmills</Trans>
                                    </InputLabel>
                                    <NativeSelect
                                        native
                                        defaultValue={-1}
                                        id="windmillSelect"
                                        onChange={handleSelectedParametersChange}
                                        label={<Trans>shutdownParameters.windmills</Trans>}
                                    >
                                        <option label={t("shutdownParameters.default")} key={"standard"} value={-1} />
                                        {parameters.windmills.map((windmillParameter: WindmillParameter) => {
                                            const className = windmillParameter.name;
                                            return (
                                                <option
                                                    label={className}
                                                    key={className}
                                                    value={windmillParameter.number}
                                                />
                                            );
                                        })}
                                    </NativeSelect>
                                </FormControl>
                            </Box>
                        )}
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead className={classes.header}>
                                <TableRow>
                                    <TableCell>
                                        <Trans>shutdownParameters.name</Trans>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Trans>shutdownParameters.description</Trans>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Trans>shutdownParameters.valueLocal</Trans>
                                    </TableCell>
                                    {hasShutdownPermissions && (
                                        <TableCell align="left">
                                            <Trans>shutdownParameters.valueRemote</Trans>
                                        </TableCell>
                                    )}
                                    {-1 < selectedParametersId && (
                                        <TableCell align="left">
                                            <Trans>shutdownParameters.default</Trans>
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listParameters.map((parameter, index) => {
                                    const hasNewRemoteValue = parameter.valueLocal !== parameter.valueRemote;
                                    return (
                                        <TableRow key={parameter.name}>
                                            <TableCell component="th" scope="row">
                                                {t("shutdownParameters.names." + parameter.name)}
                                            </TableCell>
                                            <TableCell align="left">
                                                {t("shutdownParameters.descriptions." + parameter.name)}
                                            </TableCell>
                                            <TableCell align="right">{parameter.valueLocal}</TableCell>
                                            {state.user &&
                                                hasShutdownPermissions &&
                                                (!hasNewRemoteValue ? (
                                                    <TableCell align="right">
                                                        <TextField
                                                            key={parameter.name}
                                                            type="number"
                                                            variant="filled"
                                                            defaultValue={parameter.valueRemote}
                                                            inputProps={{
                                                                "data-field": parameter.name,
                                                                step: "0.01",
                                                                style: { textAlign: "center", padding: 10 }
                                                            }}
                                                            onChange={onChangeInput}
                                                            fullWidth
                                                        />
                                                    </TableCell>
                                                ) : (
                                                    <TableCell align="right">
                                                        <TableCell
                                                            style={{
                                                                background: hasNewRemoteValue ? "orange" : "transparent"
                                                            }}
                                                        >
                                                            {parameter.valueRemote}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                background: hasNewRemoteValue ? "orange" : "transparent"
                                                            }}
                                                        >
                                                            <CircularProgress></CircularProgress>
                                                        </TableCell>
                                                    </TableCell>
                                                ))}
                                            {-1 < selectedParametersId && (
                                                <TableCell align="right">
                                                    {parameters.default.valuesLocal[index]}
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {hasShutdownPermissions && (
                        <Grid
                            container
                            className={classes.inputContainer}
                            alignItems="flex-start"
                            justifyContent="flex-end"
                            direction="row"
                        >
                            <Box marginTop={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={sendShutdownParametersData}
                                >
                                    <Trans>shutdownParameters.send</Trans>
                                </Button>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};
