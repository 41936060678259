import React, { useState, useEffect, useContext } from "react";
import { Grid, Typography, useTheme } from "@material-ui/core";
import { PolarArea } from "react-chartjs-2";
import { Trans, useTranslation } from "react-i18next";

import { fetchPost } from "../../utils/fetch";
import { Mr1 } from "../../views/ProductMr1";
import { AppContext, UserRole } from "../../store/context";
import {ChartData, ChartOptions} from "chart.js";
import { Moment } from "moment-timezone";
import { CSVLink } from "react-csv";

interface DirectionData {
    values: number[];
}

interface CsvDirectionExportData {
    direction: string;
    encounters: string;
    class: string;
    minHeight: string;
    maxHeight: string;
}

interface ChartDirectionProps {
    radar: Mr1;
    startDate: Moment;
    endDate: Moment;
    filtered: boolean;
    class: number;
    className: string;
    minHeight: number;
    maxHeight: number;
}

export const ChartDirection = (props: ChartDirectionProps): React.ReactElement => {
    const { state } = useContext(AppContext);
    const theme = useTheme();
    const { t } = useTranslation();

    const [directionData, setdirectionData] = useState<undefined | DirectionData>(undefined);

    useEffect(() => {
        const requestDirectionData = (): void => {
            if (state.user) {
                if (props.filtered === true) {
                    fetchPost("get_direction_graph_filtered.php", {
                        params: {
                            token: state.user.token,
                            databaseName: props.radar.name,
                            classId: props.class.toString(),
                            minAltitude: props.minHeight.toString(),
                            maxAltitude: props.maxHeight.toString(),
                            dateFrom: props.startDate.format("YYYY-MM-DD") + " 00:00:00",
                            dateTo: props.endDate.format("YYYY-MM-DD") + " 23:59:59",
                            numberOfDirections: "12"
                        }
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            setdirectionData(data);
                        });
                } else {
                    fetchPost("get_direction_graph.php", {
                        params: {
                            token: state.user.token,
                            databaseName: props.radar.name,
                            dateFrom: props.startDate.format("YYYY-MM-DD") + " 00:00:00",
                            dateTo: props.endDate.format("YYYY-MM-DD") + " 23:59:59",
                            numberOfDirections: "12"
                        }
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            setdirectionData(data);
                        });
                }
            }
        };
        requestDirectionData();
    }, [props, state.user]);

    if (directionData === undefined) {
        return <div />;
    }

    const dataOrientationGraph: ChartData< 'polarArea' > = {
        datasets: [
            {
                label: "Direction",
                //fill: false,
                borderColor: theme.palette.primary.light,
                data: directionData.values
            }
        ]
    };

    const optionsOrientationGraph: ChartOptions< 'polarArea' > = {
        elements: { point: { radius: 0 } },
        animation: {
            animateRotate: false,
            animateScale: false
        },
        plugins: {
            datalabels: {
                display: false
            }
        }
    };

    const showCsvDownloads =
        state.user?.role === UserRole.AnalystWithDataPermission || state.user?.role === UserRole.Admin;

    const csvDirectionHeaders = [
        { label: t("mtr.csvHeaderDirection"), key: "direction" },
        { label: t("mtr.csvHeaderEncounters"), key: "encounters" },
        { label: t("filter.class"), key: "class" },
        { label: t("filter.minHeight") + " [m]", key: "minHeight" },
        { label: t("filter.maxHeight") + " [m]", key: "maxHeight" }
    ];

    const csvDirectionData: CsvDirectionExportData[] = [];
    directionData.values.forEach((value, index): void => {
        const label = (index * 30).toString() + " - " + ((index + 1) * 30).toString();
        if (0 === index) {
            csvDirectionData.push({
                encounters: value.toString(),
                direction: label,
                class: props.className,
                minHeight: props.minHeight.toString(),
                maxHeight: props.maxHeight.toString()
            });
        } else {
            csvDirectionData.push({
                encounters: value.toString(),
                direction: label,
                class: "",
                minHeight: "",
                maxHeight: ""
            });
        }
    });

    return (
        <>
            <Typography variant="overline">
                <Trans>mtr.direction</Trans>
            </Typography>
            <Grid container direction="row" justifyContent="space-evenly">
                <div
                    style={{
                        width: 400,
                        height: 400,
                        marginLeft: 200,
                        marginBottom: 0,
                        marginRight: 200,
                        position: "relative"
                    }}
                >
                    <svg
                        style={{ position: "absolute", overflow: "visible" }}
                        width={400}
                        height={400}
                        viewBox={`200 5 ${400} ${400}`}
                    >
                        <text x="-10" y="215" textAnchor="start">
                            {"W"}
                        </text>
                        <line x1="10" y1="210" x2="390" y2="210" stroke={"black"} strokeWidth={0.5} />
                        <text x="410" y="215" textAnchor="end">
                            {"E"}
                        </text>
                        <text x="200" y="10" textAnchor="middle">
                            {"N"}
                        </text>
                        <line x1="200" y1="20" x2="200" y2="400" stroke={"black"} strokeWidth={0.5} />
                        <text x="200" y="420" textAnchor="middle">
                            {"S"}
                        </text>
                    </svg>
                    <PolarArea data={dataOrientationGraph} options={optionsOrientationGraph} width={400} height={400} />
                </div>
            </Grid>
            {showCsvDownloads ? (
                <>
                    <br></br>
                    <Grid container direction="row" justifyContent="flex-end">
                        <Grid item>
                            <CSVLink
                                style={{ color: theme.palette.primary.main }}
                                data={csvDirectionData}
                                headers={csvDirectionHeaders}
                                separator={";"}
                                filename={"directionValues.csv"}
                            >
                                <Trans>mtr.downloadCsv</Trans>
                            </CSVLink>
                        </Grid>
                    </Grid>
                </>
            ) : (
                ""
            )}
        </>
    );
};
