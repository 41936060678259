import React, { useState, useCallback } from "react";
import { Card, CardContent, CircularProgress, makeStyles } from "@material-ui/core";
import { Mr1 } from "../../../views/ProductMr1";
import { PDFViewer } from "@react-pdf/renderer";
import { Page, Text, View, Document } from "@react-pdf/renderer";

import { styles } from "./ReportStyles";
import moment from "moment";

import { FilterReportData } from "./FilterReport";
import { OverallRadarData, ReportDataOverview } from "./ReportDataOverview";

/*
import { ReportDataChartMtrLive } from "./ReportDataChartMtrLive";
import { ReportDataPieShutdownTime } from "./ReportDataPieShutdownTime";
import { ReportDataChartMtrVsPerformance } from "./ReportDataChartMtrVsPerformance";
import { ReportDataChartMtrVsWindspeed } from "./ReportDataChartMtrVsWindspeed";
import { ReportDataChartMtrPerWindspeed } from "./ReportDataChartMtrPerWindspeed";
import { ReportDataChartMtrPerDayWithPerformance } from "./ReportDataChartMtrPerDayWithPerformance";
*/

const useStyles = makeStyles({
    circularProgressCard: {
        width: "100%",
        height: "100%"
    },
    circularProgress: {
        position: "absolute",
        top: "50%",
        left: "50%"
    }
});

interface ReportYearlyProps {
    radar: Mr1;
    filterData: FilterReportData;
    //onReportDone: () => void;
}

export const ReportAnnual = (props: ReportYearlyProps): React.ReactElement => {
    const [overallRadarData, setoverallRadarData] = useState<undefined | OverallRadarData>(undefined);
    /*    
    const [imageChartMtrLive, setimageChartMtrLive] = useState<undefined | string>(undefined);
    const [imageChartMtrVsPerformance, setimageChartMtrVsPerformance] = useState<undefined | string>(undefined);
    const [imageChartMtrVsWindspeed, setimageChartMtrVsWindspeed] = useState<undefined | string>(undefined);
    const [imageChartMtrPerWindspeed, setimageChartMtrPerWindspeed] = useState<undefined | string>(undefined);
    const [imageChartMtrPerDayWithPerformance, setimageChartMtrPerDayWithPerformance] = useState<undefined | string>(
        undefined
    );
    const [imagePieShutdownTime, setimagePieShutdownTime] = useState<undefined | string>(undefined);
*/
    const classes = useStyles();

    const startOfYear = moment().utc().month(1).year(props.filterData.year).hour(0).minute(0).startOf("year");
    const endOfYear = moment().utc().month(1).year(props.filterData.year).hour(23).minute(59).endOf("year");

    /*
    const onChangeDataImageimageChartMtrLive = useCallback(
        (newImageData: string): void => {
            setimageChartMtrLive(newImageData);
        },
        [setimageChartMtrLive]
    );

    const onChangeDataImageimageChartMtrVsPerformance = useCallback(
        (newImageData: string): void => {
            setimageChartMtrVsPerformance(newImageData);
        },
        [setimageChartMtrVsPerformance]
    );

    const onChangeDataImageimageChartMtrVsWindspeed = useCallback(
        (newImageData: string): void => {
            setimageChartMtrVsWindspeed(newImageData);
        },
        [setimageChartMtrVsWindspeed]
    );

    const onChangeDataImageimageChartMtrPerWindspeed = useCallback(
        (newImageData: string): void => {
            setimageChartMtrPerWindspeed(newImageData);
        },
        [setimageChartMtrPerWindspeed]
    );

    const onChangeDataImageimageChartMtrPerDayWithPerformance = useCallback(
        (newImageData: string): void => {
            setimageChartMtrPerDayWithPerformance(newImageData);
        },
        [setimageChartMtrPerDayWithPerformance]
    );

    const onChangeDataImagePieShutdownTime = useCallback(
        (newImageData: string): void => {
            setimagePieShutdownTime(newImageData);
        },
        [setimagePieShutdownTime]
    );

    // load generated images: use following line(s) to load the generated images in the document
    // {imageChartMtrLive && <Image src={imageChartMtrLive} style={styles.image} />}
    // {imagePieShutdownTime && <Image src={imagePieShutdownTime} style={styles.imagePi} />}
    // <ReportDataChartMtrLive
    //     radar={props.radar}
    //     dateFrom={moment().utc().subtract(1, "week")}
    //     dateTo={moment().utc()}
    //     onChangeDataImage={onChangeDataImageimageChartMtrLive}
    // />
    // <ReportDataPieShutdownTime
    //     radar={props.radar}
    //     dateFrom={moment().utc().subtract(1, "year")}
    //     dateTo={moment().utc()}
    //     onChangeDataImage={onChangeDataImagePieShutdownTime}
    // />

    // {imageChartMtrLive && <Image src={imageChartMtrLive} style={styles.image} />}
    // {imageChartMtrVsPerformance && <Image src={imageChartMtrVsPerformance} style={styles.image} />}
    // {imageChartMtrVsWindspeed && <Image src={imageChartMtrVsWindspeed} style={styles.image} />}
    // {imageChartMtrPerWindspeed && <Image src={imageChartMtrPerWindspeed} style={styles.image} />}
    // {imageChartMtrPerDayWithPerformance && <Image src={imageChartMtrPerDayWithPerformance} style={styles.image} />}
    // {imagePieShutdownTime && <Image src={imagePieShutdownTime} style={styles.imagePi} />}

    //     <ReportDataChartMtrLive
    //     radar={props.radar}
    //     dateFrom={moment().utc().subtract(1, "week")}
    //     dateTo={moment().utc()}
    //     onChangeDataImage={onChangeDataImageimageChartMtrLive}
    // />
    // <ReportDataChartMtrVsPerformance
    //     radar={props.radar}
    //     dateFrom={moment().utc().subtract(1, "week")}
    //     dateTo={moment().utc()}
    //     onChangeDataImage={onChangeDataImageimageChartMtrVsPerformance}
    // />
    // <ReportDataChartMtrVsWindspeed
    //     radar={props.radar}
    //     dateFrom={moment().utc().subtract(1, "week")}
    //     dateTo={moment().utc()}
    //     onChangeDataImage={onChangeDataImageimageChartMtrVsWindspeed}
    // />
    // <ReportDataChartMtrPerWindspeed
    //     radar={props.radar}
    //     dateFrom={moment().utc().subtract(1, "week")}
    //     dateTo={moment().utc()}
    //     onChangeDataImage={onChangeDataImageimageChartMtrPerWindspeed}
    // />
    // <ReportDataChartMtrPerDayWithPerformance
    //     radar={props.radar}
    //     dateFrom={moment().utc().subtract(1, "week")}
    //     dateTo={moment().utc()}
    //     onChangeDataImage={onChangeDataImageimageChartMtrPerDayWithPerformance}
    // />
    // <ReportDataPieShutdownTime
    //     radar={props.radar}
    //     dateFrom={moment().utc().subtract(1, "year")}
    //     dateTo={moment().utc()}
    //     onChangeDataImage={onChangeDataImagePieShutdownTime}
    // />

    //const dataDateRange = "October 2020";
    let operationTime = 730; // radar was running and measuring -> sum of all time-bin duration (?)
    let blindTime = 134.7; // time radar was not measuring due to visibility (precipitation) or mode-change -> from weather-table: sum of (weather-property 5)*(time_bin duration) of each time_bin
    let observationTime = 648.6; // operationTime - blindTime
    let shutdownTime = 38.1; //  shutdown time
    let avgMtr = 254.7;
    let nBirds = 43512;
    let serialNumber = 271;
    const altitudeRangeMin = 25;
    const altitudeRangeMax = 700;
    const energyLoss = 102658.25;

    if (overallRadarData) {
        operationTime = parseFloat((overallRadarData.operationTime / 60).toFixed(0));
        blindTime = parseFloat((overallRadarData.blindTime / 60).toFixed(0));
        observationTime = parseFloat((overallRadarData.observationTime / 60).toFixed(0));
        shutdownTime = parseFloat((overallRadarData.shutdownTime / 60).toFixed(0));
        avgMtr = parseFloat((overallRadarData.averageMtr * 1).toFixed(1));
        nBirds = parseFloat((overallRadarData.sumMtr / 12).toFixed(0));
    }

*/
    //   const dataDateRange = startOfYear.format("DD.MM.") + "-" + endOfYear.format("DD.MM.YYYY");
    const dataDateRangeShort = startOfYear.format("YYYY");

    const onChangeDataOverview = useCallback(
        (newData: OverallRadarData): void => {
            setoverallRadarData(newData);
        },
        [setoverallRadarData]
    );

    /*
    if (imageChartMtrPerDayWithPerformance !== undefined) {
        //        props.onReportDone();
    }
    */

    const Overview = (): React.ReactElement => (
        <ReportDataOverview
            radar={props.radar}
            dateFrom={startOfYear}
            dateTo={endOfYear}
            onChangeData={onChangeDataOverview}
        />
    );

    let reportDone = false;
    if (overallRadarData) {
        reportDone = true;
    }

    return (
        <>
            {reportDone && (
                <PDFViewer width="100%" height="100%">
                    <Document>
                        <Page size="A4" orientation="portrait" style={styles.body} wrap>
                            <View style={styles.headerRow} fixed>
                                <View style={styles.headerCol}>
                                    <Text style={styles.headerTextLeft}>'Customer'</Text>
                                </View>
                                <View style={styles.headerCol}>
                                    <Text style={styles.headerTextCenter}>'Project'</Text>
                                </View>
                                <View style={styles.headerCol}>
                                    <Text style={styles.headerTextRight}>{dataDateRangeShort}</Text>
                                </View>
                            </View>
                            <View style={styles.headerSpacer}></View>

                            <View style={styles.titleContainer}>
                                <View style={styles.titleRow}>
                                    <Text style={styles.titleText}>Collision Risk Management Report</Text>
                                </View>
                                <View style={styles.titleRow}>
                                    <Text style={styles.subTitleText}>{"Annual Report, " + dataDateRangeShort}</Text>
                                </View>
                            </View>

                            <View style={styles.dividingLinePortrait}></View>

                            <View style={styles.headerSpacer}></View>
                            <View style={styles.titleRow}>
                                <Text style={styles.titleText}>Not yet available</Text>
                            </View>
                        </Page>
                    </Document>
                </PDFViewer>
            )}
            {!reportDone && (
                <Card className={classes.circularProgressCard}>
                    <CardContent className={classes.circularProgress}>
                        <CircularProgress></CircularProgress>
                    </CardContent>
                </Card>
            )}
            {!overallRadarData && <Overview />}
        </>
    );
};

/*

                            <View style={styles.table}>
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCellDescription}>Site</Text>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellValue}>{props.radar.siteName}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCellDescription}>Date</Text>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellValue}>{dataDateRange}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellDescription}>Sensor</Text>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellValue}>{props.radar.type}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellDescription}>S/N</Text>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellValue}>{"BS" + serialNumber}</Text>
                                        </View>
                                    </View>
                                </View>

                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCellDescription}>Operation Time</Text>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellValue}>
                                                {operationTime.toLocaleString("de-CH", { maximumFractionDigits: 1 })}
                                            </Text>
                                            <Text style={styles.tableCellUnit}>{"h"}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCellDescription}>Observation Time</Text>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellValue}>
                                                {observationTime.toLocaleString("de-CH", { maximumFractionDigits: 1 })}
                                            </Text>
                                            <Text style={styles.tableCellUnit}>{"h"}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellDescription}>Blind Time</Text>
                                            <Text style={styles.tableFootNote}>1</Text>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellValue}>
                                                {blindTime.toLocaleString("de-CH", { maximumFractionDigits: 1 })}
                                            </Text>
                                            <Text style={styles.tableCellUnit}>{"h"}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellDescription}>Shutdown Time</Text>
                                            <Text style={styles.tableFootNote}>2</Text>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellValue}>
                                                {shutdownTime.toLocaleString("de-CH", { maximumFractionDigits: 1 })}
                                            </Text>
                                            <Text style={styles.tableCellUnit}>{"h"}</Text>
                                        </View>
                                    </View>
                                </View>

                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCellDescription}>Average MTR</Text>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellValue}>
                                                {avgMtr.toLocaleString("de-CH", { maximumFractionDigits: 1 })}
                                            </Text>
                                            <Text style={styles.tableCellUnit}>{"ind./hr/km"}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCellDescription}>Number of Birds per km</Text>
                                        <Text style={styles.tableCellValue}>
                                            {nBirds.toLocaleString("de-CH", { maximumFractionDigits: 0 })}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCellDescription}>Altitude Range</Text>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellValue}>
                                                {altitudeRangeMin.toLocaleString("de-CH", {
                                                    maximumFractionDigits: 0
                                                })}
                                            </Text>
                                            <Text style={styles.tableCellValue}>
                                                {"-" +
                                                    altitudeRangeMax.toLocaleString("de-CH", {
                                                        maximumFractionDigits: 1
                                                    })}
                                            </Text>
                                            <Text style={styles.tableCellUnit}>{"m"}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellDescription}>Estimated Energy Loss</Text>
                                            <Text style={styles.tableFootNote}>3</Text>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellValue}>
                                                {energyLoss.toLocaleString("de-CH", { maximumFractionDigits: 0 })}
                                            </Text>
                                            <Text style={styles.tableCellUnit}>{"kWh"}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
*/
