import React, { useContext, useCallback, useState, useEffect } from "react";
import { Mr1 } from "../ProductMr1";
import {
    Grid,
    Card,
    CardContent,
    Typography,
    CircularProgress,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    ListItemIcon,
    Button
} from "@material-ui/core";
import { fetchPost } from "../../utils/fetch";
import { AppContext } from "../../store/context";
import { Status, StatusIcon } from "../../components/ui/StatusLight";
import { Trans } from "react-i18next";
import { ProductEvent } from "../administration/Dashboard";
import { ChartStatusLog } from "../../components/charts/ChartStatusLog";
import { useOutletContext } from "react-router";


interface EventInformation {
    events: ProductEvent[];
}

export const SystemStatus = (): React.ReactElement => {
    const { state } = useContext(AppContext);
    const radar = useOutletContext< Mr1 >();

    const [eventData, seteventData] = useState<EventInformation | undefined>(undefined);

    useEffect(() => {
        const requestEventData = (): void => {
            if (state.user) {
                fetchPost("get_event_information.php", {
                    params: {
                        token: state.user.token,
                        databaseName: radar.name
                    }
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            seteventData(data);
                        }
                    });
            }
        };

        requestEventData();
        const id = setInterval(() => {
            requestEventData();
        }, 10000);

        return (): void => clearInterval(id);
    }, [radar.name, state.user, seteventData]);

    const onHandleEvent = useCallback(
        (radar: Mr1, event: ProductEvent): void => {
            if (state.user) {
                fetchPost("handle_event.php", {
                    params: { token: state.user.token, databaseName: radar.name, eventId: event.id }
                });
            }
        },
        [state.user]
    );

    if (eventData === undefined) {
        return (
            <Grid item xs={12} md={10}>
                <Grid container direction="column">
                    <Grid item>
                        <Card>
                            <CardContent>
                                <Typography variant="overline">
                                    <Trans>system.eventHandling</Trans>
                                </Typography>
                                <div>
                                    <CircularProgress
                                        style={{
                                            alignContent: "center",
                                            alignItems: "center"
                                        }}
                                    ></CircularProgress>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    const activeEventsMap = new Map();
    const clearingEventsMap = new Map();

    if (eventData?.events) {
        eventData.events.forEach((event) => {
            if (event.active) {
                if (event.handledByUser) {
                    if (clearingEventsMap.has(Number(radar.id))) {
                        clearingEventsMap.get(Number(radar.id)).push(event);
                    } else {
                        clearingEventsMap.set(Number(radar.id), new Array<ProductEvent>(event));
                    }
                } else {
                    if (activeEventsMap.has(Number(radar.id))) {
                        activeEventsMap.get(Number(radar.id)).push(event);
                    } else {
                        activeEventsMap.set(Number(radar.id), new Array<ProductEvent>(event));
                    }
                }
            }
        });
    }

    return (
        <Grid item xs={12} md={10}>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Card>
                        <CardContent>
                            <Typography variant="overline">
                                <Trans>system.eventHandling</Trans>
                            </Typography>

                            {activeEventsMap.has(Number(radar.id)) ? (
                                <List style={{ marginLeft: 100, marginTop: -20, width: "80%", maxWidth: 600 }}>
                                    {activeEventsMap.get(Number(radar.id)).map((activeEvent: ProductEvent) => {
                                        return (
                                            <ListItem>
                                                <ListItemIcon>
                                                    <StatusIcon status={Status.Error} tooltipText={""} />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Typography>{activeEvent.eventDescription}</Typography>
                                                </ListItemText>
                                                <ListItemSecondaryAction>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        size="small"
                                                        onClick={(): void => {
                                                            onHandleEvent(radar, activeEvent);
                                                        }}
                                                    >
                                                        {"Clear"}
                                                    </Button>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            ) : (
                                ""
                            )}
                            {clearingEventsMap.has(Number(radar.id)) ? (
                                <List style={{ marginLeft: 100, marginTop: -20, width: "80%", maxWidth: 574 }}>
                                    {clearingEventsMap
                                        .get(Number(radar.id))
                                        .map((clearingEvent: ProductEvent) => {
                                            return (
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <StatusIcon status={Status.Error} tooltipText={""} />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        <Typography>{clearingEvent.eventDescription}</Typography>
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                        <CircularProgress size={12}></CircularProgress>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            );
                                        })}
                                </List>
                            ) : (
                                ""
                            )}
                            {!activeEventsMap.has(Number(radar.id)) &&
                            !clearingEventsMap.has(Number(radar.id)) ? (
                                <div>
                                    <Typography>
                                        <Trans>system.noEvent</Trans>
                                    </Typography>
                                </div>
                            ) : (
                                ""
                            )}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item>
                    <ChartStatusLog radar={radar} />
                </Grid>
            </Grid>
        </Grid>
    );
};
