import React from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { CardDate } from "./CardDate";
import { CardRadar } from "./CardRadar";
import { CardAc1 } from "./CardAc1";
import { CardTurbine } from "./CardTurbine";
import { CardWeather } from "./CardWeather";
import { Mr1 } from "../../views/ProductMr1";
import { Ac1 } from "../../views/ProductAc1";

interface SidebarProps {
    radar?: Mr1 | undefined;
    ac1?: Ac1 | undefined;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    item: {
        marginBottom: theme.spacing(2)
    }
}));

export const Sidebar = (props: SidebarProps): React.ReactElement => {
    const classes = useStyles();

    const renderMr1Content = (): React.ReactElement => {
        if (!props.radar) {
            return <></>;
        }

        return (
            <Grid container>
                <Grid item xs={12}>
                    <CardDate class={classes.item} />
                </Grid>
                <Grid item xs={12}>
                    <CardWeather
                        class={classes.item}
                        coordinates={{ lat: +props.radar.siteLatitude, long: +props.radar.siteLongitude }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CardRadar class={classes.item} radar={props.radar} />
                </Grid>
                <Grid item xs={12}>
                    <CardTurbine class={classes.item} radar={props.radar} />
                </Grid>
            </Grid>
        );
    };

    const renderAc1Content = (): React.ReactElement => {
        if (!props.ac1) {
            return <></>;
        }

        return (
            <Grid container>
                <Grid item xs={12}>
                    <CardDate class={classes.item} />
                </Grid>
                <Grid item xs={12}>
                    <CardWeather
                        class={classes.item}
                        coordinates={{ lat: +props.ac1.siteLatitude, long: +props.ac1.siteLongitude }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CardAc1 class={classes.item} ac1={props.ac1} />
                </Grid>
            </Grid>
        );
    };

    let content;
    if (props.radar) {
        content = renderMr1Content();
    } else if (props.ac1) {
        content = renderAc1Content();
    }

    if (!content) {
        return <></>;
    }

    return content;
};
