import React, { useContext, useCallback, useState, useEffect } from "react";
import { Grid, Button, TextField, makeStyles, Theme } from "@material-ui/core";
import { Trans } from "react-i18next";
import { fetchPost } from "../../../utils/fetch";
import { AppContext } from "../../../store/context";

interface DatabaseCommentProps {
    nameDatabase: string;
    comment: string;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    commentField: {
        display: "flex",
        alignItems: "center",
        width: 500,
        justifyContent: "flex-end"
    },
    commentFieldButton: {
        display: "flex",
        alignItems: "center",
        width: 80,
        justifyContent: "flex-end",
        marginRight: 100
    }
}));

export const DatabaseComment = (props: DatabaseCommentProps): React.ReactElement => {
    const { state } = useContext(AppContext);
    const classes = useStyles();

    const [comment, setComment] = useState<string>("");
    const [postEnabled, setpostEnabled] = useState<boolean>(false);

    useEffect(() => {
        setComment(props.comment);
    }, [props.comment]);

    const onChangeInput = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setComment(event.target.value);
            setpostEnabled(true);
        },
        [setComment]
    );

    const onChangeComment = useCallback(() => {
        if (state.user) {
            fetchPost("set_database_comment.php", {
                params: {
                    token: state.user.token,
                    databaseName: props.nameDatabase,
                    comment: comment
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        console.log("Successfully sent radar comment");
                        setpostEnabled(false);
                    }
                })
                .catch((error) => {
                    console.log("Could not send radar comment");
                });
        }
    }, [state.user, comment, props.nameDatabase]);

    return (
        <>
            <Grid item className={classes.commentField}>
                <TextField
                    label={props.nameDatabase}
                    defaultValue={comment}
                    value={comment}
                    key={props.nameDatabase}
                    onChange={onChangeInput}
                    variant="filled"
                    size="small"
                    fullWidth
                />
            </Grid>
            <Grid item className={classes.commentFieldButton}>
                <Button variant="contained" color="primary" onClick={onChangeComment} fullWidth disabled={!postEnabled}>
                    <Trans>administration.postDatabaseComment</Trans>
                </Button>
            </Grid>
        </>
    );
};
