export const Config = {
    appname: "Swiss Birdradar MR1 Webapp",
    version: "1.1.3e",
    //    endpoint: "https://cloud.birdradar.com/WebappSmoca/",
    endpoint: "https://cloud.birdradar.com/backend/",
    thumbnails: "https://cloud.birdradar.com/thumbnails/",
    pdpData: "https://cloud.birdradar.com/pdpData/",
    openWeatherKey: "2830a9d69075e3c200e96ba005234fff",
    storageKey: "@SBRUserStore"
};
