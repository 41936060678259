import React, { useState, useContext, useEffect } from "react";
import { CircularProgress, Typography } from "@material-ui/core";
import { fetchPost } from "../../../utils/fetch";
import { AppContext } from "../../../store/context";
import { PdpViewer } from "./PdpViewer";
import { Trans } from "react-i18next";

export interface DecimatedDataUnit {
    signalValues: number[];
    signalCodeBottom: number;
    signalCodeTop: number;
    altitudeStart: number;
    altitudeStep: number;
    timestamp: number;
    northSignal: number;
    isBlind: number;
    isRotating: number;
    pulseType: number;
}

export interface SignatureDataUnit {
    signalValues: number[];
    altitudeValues: number[];
    timestamp: number;
    samplingFrequency: number;
    id: number;
    class: string;
    validationType: string;
    mtrFactCorrected: number;
    isProtected: boolean;
}

export interface PdpData {
    decimatedDataUnits: DecimatedDataUnit[];
    signatureDataUnits: SignatureDataUnit[];
    pdpDataVersion: number;
}

interface PdpReaderProps {
    radarName: string;
    fileName: string;
    getPdpViaFtp: boolean;
}

export const PdpReader = (props: PdpReaderProps): React.ReactElement => {
    const { state } = useContext(AppContext);

    const [pdpData, setpdpData] = useState<PdpData | undefined>(undefined);

    useEffect(() => {
        const requestPdpData = (): void => {
            if (state.user) {
                setpdpData(undefined);

                let getPdpDataBackendFile = "get_pdp_data.php";
                if (props.getPdpViaFtp) {
                    getPdpDataBackendFile = "get_pdp_data_via_ftp.php";
                }

                fetchPost(getPdpDataBackendFile, {
                    params: {
                        token: state.user.token,
                        databaseName: props.radarName,
                        fileName: props.fileName
                    }
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            setpdpData(data);
                        }
                    });
            }
        };

        const timeout = setTimeout(() => {
            requestPdpData();
        }, 500);

        return (): void => clearTimeout(timeout);
    }, [props, state.user]);

    const spaceLeft = 630;
    const spaceTop = 230;
    const width = 600;
    const height = 700 - spaceTop;

    if (pdpData === undefined) {
        return (
            <div
                style={{
                    width: width,
                    height: height,
                    marginLeft: spaceLeft,
                    marginTop: spaceTop,
                    position: "relative",
                    alignContent: "center",
                    alignItems: "center"
                }}
            >
                <CircularProgress
                    style={{
                        alignContent: "center",
                        alignItems: "center"
                    }}
                ></CircularProgress>
            </div>
        );
    }
    if (pdpData.decimatedDataUnits.length === 0) {
        return (
            <Typography variant="body2">
                Pdp Reader: <Trans>error.noData</Trans>
            </Typography>
        );
    }

    return (
        <div>
            <PdpViewer data={pdpData}></PdpViewer>
        </div>
    );
};
