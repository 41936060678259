import React, { useState } from "react";
import { Container, Grid, CardContent, Card, makeStyles, Theme, CircularProgress } from "@material-ui/core";

import { Config } from "../../config/app";
import { UserList } from "./UserList";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    root: {
        marginTop: 30
    },
    logo: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: "100%",
        maxWidth: 360
    },
    inputContainer: {
        marginBottom: theme.spacing(2)
    }
}));

export const ManageUsers = (): React.ReactElement => {
    const classes = useStyles();

    const [isDatabasesLoading] = useState<boolean>(false);

    return (
        <Container maxWidth="md" className={classes.root}>
            <Card>
                <CardContent>
                    {isDatabasesLoading ? (
                        <CircularProgress></CircularProgress>
                    ) : (
                        <Grid container>
                            <Grid item xs={12}>
                                <img
                                    src="../../images/swiss-birdradar_logo.png"
                                    className={classes.logo}
                                    alt={Config.appname}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} className={classes.inputContainer}>
                                <UserList />
                            </Grid>
                        </Grid>
                    )}
                </CardContent>
            </Card>
        </Container>
    );
};
