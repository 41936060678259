import React from "react";
import "./App.css";
import { theme } from "./utils/theme";
import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { AppProvider } from "./store/context";
import "moment/locale/de";
import { AppRoutes } from "./AppRoutes";
import './utils/i18n';

function App(): React.ReactElement {
    //const history = createBrowserHistory();
    return (
        <div className="App">
            <CssBaseline />
            <AppProvider>
                <ThemeProvider theme={theme}>
                      <AppRoutes />
                </ThemeProvider>
            </AppProvider>
        </div>
    );
}
export default App;
