import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import { Trans } from "react-i18next";

interface InfoDialogProps {
    showDialog: boolean;
    onCloseDialog: () => void;
}

export const InfoDialog = (props: InfoDialogProps): React.ReactElement => {
    const { onCloseDialog } = props;
    return (
        <Dialog open={props.showDialog} onClose={onCloseDialog} fullWidth maxWidth="sm">
            <DialogTitle>
                <Trans>info.title</Trans>
            </DialogTitle>
            <DialogContent>
                <DialogContentText style={{ minHeight: 220 }}>
                    <p>
                        <Trans>info.version</Trans>: {require("../../../../package.json").version}, 2021
                    </p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseDialog} color="primary" autoFocus>
                    <Trans>label.ok</Trans>
                </Button>
            </DialogActions>
        </Dialog>
    );
};
