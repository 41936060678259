import { Dictionary } from "./types";
import { Config } from "../config/app";

export const fetchPost = (
    request: string,
    body?: { token?: string; params?: Dictionary<string> }
): Promise<Response> => {
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: body && body.params && JSON.stringify(body.params)
    };
    return fetch(Config.endpoint + request, options);
};
