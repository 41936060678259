import React, { useCallback, useContext, useState } from "react";
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Box,
    Button,
    makeStyles,
    Theme,
    FormControl,
    InputLabel,
    Select
} from "@material-ui/core";
import { AppContext } from "../../../store/context";
import { Mr1 } from "../../../views/ProductMr1";

import { Trans, useTranslation } from "react-i18next";
import { UITypes } from "../../../store/reducer";
import moment from "moment-timezone";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    item: {
        justifyContent: "left",
        marginLeft: 5
    }
}));

export enum ReportType {
    Monthly,
    Annual,
    Undefined
}

export interface FilterReportData {
    month: number;
    year: number;
}

interface FilterReportProps {
    onChangeFilterData: (newFilterData: FilterReportData, type: ReportType) => void;
    radar: Mr1;
    isActive: boolean;
}

export const FilterReport = (props: FilterReportProps): React.ReactElement => {
    const { state } = useContext(AppContext);
    const classes = useStyles();
    const { dispatch } = useContext(AppContext);
    const { t } = useTranslation();
    const [errorText, setErrorText] = useState<string | undefined>(undefined);
    const selectedFilterData = state.ui.filter.report;

    const setSelectedFilterData = useCallback(
        (data: FilterReportData) => {
            dispatch({
                type: UITypes.SetReportFilter,
                payload: data
            });
        },
        [dispatch]
    );

    const months = [
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december"
    ];

    const years: number[] = [];
    let year = 2020;
    while (year <= moment().year()) {
        years.push(year);
        year++;
    }

    const handleMonthChange = (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ): void => {
        const id = event.currentTarget.value as number;
        setSelectedFilterData({ ...selectedFilterData, month: +id });
        setErrorText(undefined);
    };

    const handleYearChange = (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ): void => {
        const id = event.currentTarget.value as number;
        setSelectedFilterData({ ...selectedFilterData, year: +id });
        setErrorText(undefined);
    };

    const { onChangeFilterData } = props;
    const updateFilterData = useCallback(
        (type: ReportType): void => {
            const now = moment().utc();
            const filterDate = moment().utc().month(selectedFilterData.month).year(selectedFilterData.year);

            if (!(now < filterDate)) {
                onChangeFilterData(selectedFilterData, type);
            } else {
                setErrorText(t("reporting.invalidDate"));
            }
        },
        [selectedFilterData, onChangeFilterData, t]
    );

    const onReset = useCallback((): void => {
        onChangeFilterData(selectedFilterData, ReportType.Undefined);
    }, [selectedFilterData, onChangeFilterData]);

    return (
        <Card>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography variant="overline">
                            <Trans>filter.title</Trans>
                        </Typography>
                    </Grid>
                    <Grid container direction="row" spacing={2} className={classes.item} >
                        <Grid item >
                            {months && (
                                <Box marginTop={1} >
                                    <FormControl className={classes.fullWidth}>
                                        <InputLabel htmlFor="monthSelect">
                                            <Trans>filter.month</Trans>
                                        </InputLabel>
                                        <Select
                                            native
                                            defaultValue={selectedFilterData.month}
                                            id="monthSelect"
                                            onChange={handleMonthChange}
                                            label={<Trans>filter.month</Trans>}
                                        >
                                            {months.map((value, index) => {
                                                return (
                                                    <option
                                                        label={t("filter.months." + value)}
                                                        key={value}
                                                        value={index}
                                                    />
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>
                            )}
                        </Grid>
                        <Grid item >
                            {year && (
                                <Box marginTop={1}>
                                    <FormControl className={classes.fullWidth}>
                                        <InputLabel htmlFor="yearSelect">
                                            <Trans>filter.year</Trans>
                                        </InputLabel>
                                        <Select
                                            native
                                            defaultValue={selectedFilterData.year}
                                            id="yearSelect"
                                            onChange={handleYearChange}
                                            label={<Trans>filter.year</Trans>}
                                        >
                                            {years.map((value) => {
                                                return <option label={value.toString()} key={value} value={value} />;
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item className={classes.item}>
                        <Box >
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={!props.isActive}
                                onClick={(): void => {
                                    updateFilterData(ReportType.Monthly);
                                }}
                            >
                                <Trans>reporting.loadMonthlyReport</Trans>
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item className={classes.item}>
                        <Box>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={!props.isActive}
                                onClick={(): void => {
                                    updateFilterData(ReportType.Annual);
                                }}
                            >
                                <Trans>reporting.loadAnnualReport</Trans>
                            </Button>
                        </Box>
                    </Grid>
                    {!props.isActive && (
                        <Grid item className={classes.item}>
                            <Box marginTop={2}>
                                <Button variant="contained" color="primary" type="submit" onClick={onReset}>
                                    <Trans>reporting.resetReport</Trans>
                                </Button>
                            </Box>
                        </Grid>
                    )}
                    {errorText && (
                        <Grid item className={classes.item}>
                            <Box marginTop={2}>{errorText}</Box>
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};
