import React, { useContext, useState, useEffect, useCallback } from "react";
import { Box, Card, CardContent, Typography, Grid, makeStyles, Theme, CircularProgress } from "@material-ui/core";
import { AppContext } from "../../../store/context";
import { Trans, useTranslation } from "react-i18next";
import { fetchPost } from "../../../utils/fetch";
import { Mr1 } from "../../../views/ProductMr1";
import moment from "moment";
import { Doughnut } from "react-chartjs-2";
import { TooltipItem, ChartData, ChartOptions } from "chart.js";
import { FilterShutdown, FilterShutdownData } from "../../filter/FilterShutdown";

export interface Windmill {
    observationTime: number;
    shutdownTime: number;
    name: string;
    number: number;
}

interface ShutdownTimes {
    totalShutdownTime: number;
    lastShutdownTime: number | undefined;
    lastShutdownStart: string | undefined;
    lastShutdownEnd: string | undefined;
    systemTimeStart: string;
    systemTimeEnd: string;
    numberOfWindmills: number;
    totalShutdownTimeForWindmillsInMinutes: number;
    windmills: Windmill[];
}

interface WindparkValueData {
    data: number[];
    time: string[];
    total: number;
}

interface WindparkData {
    windparkData: {
        calculatedLoss: WindparkValueData;
        calculatedPerformance: WindparkValueData;
        calculatedMaxPerformance: WindparkValueData;
    };
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    container: {
        display: "flex"
    },
    item: {
        flex: 1,
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
    },
    itemRight: {
        flex: 1,
        marginBottom: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
    }
}));

interface ShutdownTimesProps {
    radar: Mr1;
}

export const ShutdownTimes = (props: ShutdownTimesProps): React.ReactElement => {
    const classes = useStyles();
    const { state } = useContext(AppContext);
    const { t } = useTranslation();

    const [error, setError] = useState<string>("");
    const [shutdownTimes, setshutdownTimes] = useState<undefined | ShutdownTimes>(undefined);
    const [windparkData, setwindparkData] = useState<undefined | WindparkData>(undefined);

    const [filterData, setfilterDate] = useState<FilterShutdownData>(state.ui.filter.historic);

    const changeFilterData = useCallback(
        (newFilterData: FilterShutdownData): void => {
            setfilterDate(newFilterData);
        },
        [setfilterDate]
    );

    useEffect(() => {
        const requestShutdownTimesData = (): void => {
            if (state.user) {
                fetchPost("get_shutdown_times.php", {
                    params: {
                        token: state.user.token,
                        databaseName: props.radar.name,
                        siteId: props.radar.siteId,
                        dateFrom: filterData.startDate.format("YYYY-MM-DD HH:mm:ss"),
                        dateTo: filterData.endDate.format("YYYY-MM-DD HH:mm:ss")
                    }
                })
                    .then((response) => response.json())
                    .then((data) => {
                        setshutdownTimes(data);
                    })
                    .catch((error) => {
                        setError(error.message);
                    });
            }
        };

        const requestWindparkData = (): void => {
            if (state.user) {
                fetchPost("get_windpark_measurements_data.php", {
                    params: {
                        token: state.user.token,
                        databaseName: props.radar.name,
                        dateFrom: filterData.startDate.format("YYYY-MM-DD HH:mm:ss"),
                        dateTo: filterData.endDate.format("YYYY-MM-DD HH:mm:ss")
                    }
                })
                    .then((response) => response.json())
                    .then((data) => {
                        setwindparkData(data);
                    })
                    .catch((error) => {
                        setError(error.message);
                    });
            }
        };

        requestShutdownTimesData();
        requestWindparkData();

        const id = setInterval(() => {
            requestShutdownTimesData();
            requestWindparkData();
        }, 60000);

        return (): void => clearInterval(id);
    }, [state.user, props.radar, setshutdownTimes, setwindparkData, setError, filterData]);

    const showError = (error: string): React.ReactElement => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                    <FilterShutdown onChangeFilterData={changeFilterData} radar={props.radar} />
                </Grid>
                <Grid item xs={12} md={10}>
                    <Card className={classes.itemRight}>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box marginBottom={2}>
                                        <Typography variant="h6">
                                            <Trans>shutdownTimes.title</Trans>
                                        </Typography>
                                        <Typography variant="body2">{error}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <CircularProgress></CircularProgress>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>{" "}
                </Grid>
            </Grid>
        );
    };

    if (state.user === undefined) {
        return showError(t("error.noUser"));
    }

    if (shutdownTimes === undefined || windparkData === undefined) {
        return showError(t("error.loading") + "...");
    }

    if (error !== "") {
        return showError(error);
    }

    const lastShutdownStartString = moment(shutdownTimes.lastShutdownStart, "YYYY-MM-DD HH:mm:ss").format(
        "DD.MM.YYYY HH:mm:ss"
    );
    const lastShutdownEndString = shutdownTimes.lastShutdownEnd
        ? moment(shutdownTimes.lastShutdownEnd, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm:ss")
        : t("shutdownTimes.now");

    let systemTimeStart = filterData.startDate;
    if (shutdownTimes.systemTimeStart !== "")
        systemTimeStart = moment(shutdownTimes.systemTimeStart, "YYYY-MM-DD HH:mm:ss");

    let systemTimeEnd = filterData.endDate;
    if (shutdownTimes.systemTimeEnd !== "") systemTimeEnd = moment(shutdownTimes.systemTimeEnd, "YYYY-MM-DD HH:mm:ss");

    const totalSystemTime = moment.duration(systemTimeEnd.diff(systemTimeStart));
    const totalSystemTimeHours = totalSystemTime.asHours();

    const shutdownTimePercentage = (shutdownTimes.totalShutdownTime / 3600 / totalSystemTimeHours) * 100;

    const shutdownDoughnutData: ChartData<'doughnut'> = {
        datasets: [
            {
                data: [shutdownTimePercentage, 100 - shutdownTimePercentage],
                backgroundColor: ["red", "green"]
            }
        ],

        labels: [t("shutdownTimes.shutdown"), t("shutdownTimes.running")]
    };

    const performancePercentage =
        (windparkData.windparkData.calculatedPerformance.total /
            (windparkData.windparkData.calculatedLoss.total + windparkData.windparkData.calculatedPerformance.total)) *
        100;

    const performanceDoughnutData: ChartData<'doughnut'> = {
        datasets: [
            {
                data: [100 - performancePercentage, performancePercentage],
                backgroundColor: ["red", "green"]
            }
        ],

        labels: [t("shutdownTimes.totalPerformanceLoss"), t("shutdownTimes.totalPerformance")]
    };

    interface DoughnutData {
        datasets: [
            {
                data: Array<number>;
                backgroundColor: Array<string>;
            }
        ];

        labels: Array<string>;
    }

    const options: ChartOptions<'doughnut'> = {
        responsive: true,

        plugins: {
            tooltip: {
                callbacks: {
                    label: (tooltipItem: TooltipItem< 'doughnut' >): string => {
                        if (tooltipItem.datasetIndex !== undefined) {
                            if (tooltipItem.dataIndex !== undefined) {
                                const dataset = tooltipItem.chart.data.datasets[tooltipItem.datasetIndex];
                                const currentValue = dataset.data[tooltipItem.dataIndex] as number;
                                const percentage = Math.floor(currentValue * 100 + 0.5) / 100;
                                return percentage + "%";
                            } else return "0.0 %";
                        } else return "0.0 %";
                    }
                }
            },
            datalabels: {
                display: false
            }
        }
    };

    return (
        <Grid container direction="row">
            <Grid item xs={12} md={2}>
                <FilterShutdown onChangeFilterData={changeFilterData} radar={props.radar} />
            </Grid>
            <Grid item xs={12} md={4} className={classes.container}>
                <Card className={classes.item}>
                    <CardContent>
                        <Grid container direction="column">
                            <Grid item xs={12}>
                                <Box marginBottom={2}>
                                    <Typography variant="h6">
                                        <Trans>shutdownTimes.title</Trans>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Box marginBottom={2}>
                                <Grid container direction="row">
                                    <Grid item xs={12} md={12}>
                                        <Grid justifyContent="space-between" container>
                                            <Grid item>
                                                <Typography variant="body1">
                                                    <Trans>shutdownTimes.systemStartTime</Trans>:
                                                </Typography>
                                            </Grid>

                                            <Grid item>{systemTimeStart.format("DD.MM.YYYY HH:mm:ss")}</Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row">
                                    <Grid item xs={12} md={12}>
                                        <Grid justifyContent="space-between" container>
                                            <Grid item>
                                                <Typography variant="body1">
                                                    <Trans>shutdownTimes.totalShutdownTime</Trans>:
                                                </Typography>
                                            </Grid>

                                            <Grid item>{(shutdownTimes.totalShutdownTime / 3600).toFixed(2)} h</Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Grid item xs={12} md={12}>
                                <Grid justifyContent="space-between" container>
                                    <Grid item>
                                        <Typography variant="body1">
                                            <Trans>shutdownTimes.lastShutdownPeriod</Trans>:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        {shutdownTimes.lastShutdownStart
                                            ? lastShutdownStartString +
                                            " " +
                                            t("shutdownTimes.until") +
                                            " " +
                                            lastShutdownEndString
                                            : "-"}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Grid justifyContent="space-between" container>
                                    <Grid item>
                                        <Typography variant="body1">
                                            <Trans>shutdownTimes.lastShutdownTime</Trans>:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        {shutdownTimes.lastShutdownTime
                                            ? (shutdownTimes.lastShutdownTime / 60).toFixed(0)
                                            : "-"}
                                        {" min"}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={3} className={classes.container}>
                <Card className={classes.item}>
                    <CardContent>
                        <Doughnut data={shutdownDoughnutData} options={options} />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={3} className={classes.container}>
                <Card className={classes.itemRight}>
                    <CardContent>
                        <Doughnut data={performanceDoughnutData} options={options} />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};
