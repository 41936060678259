import React, { useEffect, useContext } from "react";
import { CircularProgress, Box, makeStyles } from "@material-ui/core";
import { AppContext } from "../store/context";
import { UserTypes } from "../store/reducer";
import { useNavigate } from "react-router";
import { AppRoutesPath } from "../utils/routes";

const useStyles = makeStyles({
    root: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
});
export const Logout = (): React.ReactElement => {
    const classes = useStyles();
    const { dispatch } = useContext(AppContext);
    const navigate = useNavigate();
    
    useEffect(() => {
        dispatch({
            type: UserTypes.Remove
        });
        navigate(AppRoutesPath.login)
    }, [dispatch]);
    return (
        <Box className={classes.root}>
            <CircularProgress />
        </Box>
    );
};
