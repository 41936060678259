import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { Config } from "../../config/app";
const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "row",
        height: 46,
        background: "#c5e4ff"
    },
    logo: {
        padding: 4,
        height: "100%"
    }
});
export const Header = (): React.ReactElement => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <a href="https://www.swiss-birdradar.com">
                <img src="/images/swiss-birdradar_logo.png" className={classes.logo} alt={Config.appname} />
            </a>
        </Box>
    );
};
