import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import {
    Grid,
    Card,
    CardContent,
    Typography,
    makeStyles,
    useTheme,
    Theme,
    CircularProgress,
    Box,
    FormControlLabel,
    Checkbox,
    Divider
} from "@material-ui/core";
import { Line } from "react-chartjs-2";
import { Trans, useTranslation } from "react-i18next";

import { fetchPost } from "../../utils/fetch";
import { Mr1 } from "../../views/ProductMr1";
import { FilterMtrChartHistoric, FilterMtrChartHistoricData } from "../filter/FilterMtrChartHistoric";
import { AppContext, UserRole } from "../../store/context";
import { Chart, ChartData, ChartOptions } from "chart.js";
import annotationPlugin, { AnnotationOptions } from 'chartjs-plugin-annotation';
import moment, { Moment } from "moment-timezone";
import { CustomLegend } from "./CustomLegend/CustomLegend";
import { ChartDirection } from "./ChartDirection";
import { CSVLink } from "react-csv";
import { getSunrise, getSunset } from "sunrise-sunset-js";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";

Chart.register(annotationPlugin);

interface DateRange {
    from: moment.Moment;
    to: moment.Moment;
}

interface ShutdownParameter {
    valueLocal: number;
    name: string;
    id: number;
}

interface MtrData {
    mtrThresholdShutdown: number[];
    mtrThresholdRestart: number[];
    isDay: boolean[];
    shutdownState: boolean[];
    mtrData: number[];
    time: string[];
    windmillShutdownParameter: ShutdownParameter[] | undefined;
    shutdownParameter: ShutdownParameter[] | undefined;
}

interface ChartFiltersStatus {
    threshold: boolean;
    blindTimes: boolean;
    shutdowns: boolean;
    nightCycles: boolean;
}

enum ChartFiltersType {
    threshold = "threshold",
    blindTimes = "blindtimes",
    shutdowns = "shutdowns",
    nightCycles = "nightcycles"
}

interface CsvHistoricExportData {
    mtr: string;
    time: string;
}

interface MtrChartHistoricProps {
    radar: Mr1;
    selectedWindmillNumber: number;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    item: {
        marginBottom: theme.spacing(2)
    },
    circularProgress: {
        position: "absolute",
        top: "50%",
        left: "50%"
    }
}));

export const MtrChartHistoric = (props: MtrChartHistoricProps): React.ReactElement => {
    const { state } = useContext(AppContext);
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const [chartFilters, setChartFilters] = useState<ChartFiltersStatus>({
        threshold: false,
        blindTimes: false,
        shutdowns: false,
        nightCycles: false
    });
    const [mtrData, setmtrData] = useState<undefined | MtrData>(undefined);
    const [maxHeight, setMaxHeight] = useState<number>(15000);
    const [blindTimes, setBlindTimes] = useState<DateRange[] | undefined>([]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const chartRef = useRef<ChartJSOrUndefined<"line", { t: Moment, y: number }[], string>>(null);

    const [isLoading, setisLoading] = useState<boolean>(true);

    const [filterData, setfilterDate] = useState<FilterMtrChartHistoricData>(state.ui.filter.historic);

    const getGraphHeight = useCallback(
        (height: number) => {
            setMaxHeight(height);
        },
        [setMaxHeight]
    );

    useEffect(() => {
        const requestMtrData = (currentFilterData: FilterMtrChartHistoricData): void => {
            if (state.user) {
                setisLoading(true);
                fetchPost("get_mtr_graph_real.php", {
                    params: {
                        token: state.user.token,
                        databaseName: props.radar.name,
                        windmillNumber: props.selectedWindmillNumber.toString(),
                        siteId: props.radar.siteId,
                        dateFrom: currentFilterData.startDate.format("YYYY-MM-DD") + " 00:00:00",
                        dateTo: currentFilterData.endDate.format("YYYY-MM-DD") + " 23:59:59"
                    }
                })
                    .then((response) => response.json())
                    .then((data) => {
                        setmtrData(data);
                        setisLoading(false);
                    });
            }
        };
        requestMtrData(filterData);
    }, [filterData, props, state.user]);

    useEffect(() => {
        if (chartFilters.blindTimes && state.user) {
            //Make Request
            fetchPost("get_blind_times.php", {
                params: {
                    token: state.user.token,
                    databaseName: props.radar.name,
                    siteId: props.radar.siteId,
                    dateFrom: filterData.startDate.format("YYYY-MM-DD") + " 00:00:00",
                    dateTo: filterData.endDate.format("YYYY-MM-DD") + " 23:59:59"
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    setBlindTimes(
                        data.blindTimes.map((time: { blindFrom: string; blindTo: string }) => {
                            return {
                                from: moment(time.blindFrom, "YYYY-MM-DD HH:mm:ss"), //2020-09-15 22:30:06
                                to: moment(time.blindTo, "YYYY-MM-DD HH:mm:ss")
                            };
                        })
                    );
                });
        }
    }, [filterData, setBlindTimes, chartFilters.blindTimes, state.user, props.radar.name, props.radar.siteId]);

    const changeFilterData = useCallback(
        (newFilterData: FilterMtrChartHistoricData): void => {
            setfilterDate(newFilterData);
            setisLoading(true);
        },
        [setfilterDate, setisLoading]
    );

    const onChartFilterChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const newChartFilterSettings = { ...chartFilters };
            switch (event.target.name) {
                case ChartFiltersType.threshold: {
                    newChartFilterSettings.threshold = !chartFilters.threshold;
                    break;
                }
                case ChartFiltersType.blindTimes: {
                    newChartFilterSettings.blindTimes = !chartFilters.blindTimes;
                    if (newChartFilterSettings.blindTimes === false) {
                        setBlindTimes(undefined);
                    }
                    break;
                }
                case ChartFiltersType.shutdowns: {
                    newChartFilterSettings.shutdowns = !chartFilters.shutdowns;
                    break;
                }
                case ChartFiltersType.nightCycles: {
                    newChartFilterSettings.nightCycles = !chartFilters.nightCycles;
                    break;
                }
                default: {
                    break;
                }
            }
            setChartFilters(newChartFilterSettings);
        },
        [chartFilters, setChartFilters, setBlindTimes]
    );

    if (mtrData === undefined) {
        return <div />;
    }

    let legendLabel = "MTR (" + t("mtr.timezone") + " UTC)";

    if (mtrData.shutdownParameter && 1 < mtrData.shutdownParameter?.length) {
        legendLabel +=
            " " +
            t("shutdownParameters.names." + mtrData.shutdownParameter[0].name) +
            ": " +
            mtrData.shutdownParameter[0].valueLocal +
            ", " +
            t("shutdownParameters.names." + mtrData.shutdownParameter[1].name) +
            ": " +
            mtrData.shutdownParameter[1].valueLocal;
    } else if (mtrData.windmillShutdownParameter && 1 < mtrData.windmillShutdownParameter?.length) {
        legendLabel +=
            " " +
            t("shutdownParameters.names." + mtrData.windmillShutdownParameter[0].name) +
            ": " +
            mtrData.windmillShutdownParameter[0].valueLocal +
            ", " +
            t("shutdownParameters.names." + mtrData.windmillShutdownParameter[1].name) +
            ": " +
            mtrData.windmillShutdownParameter[1].valueLocal;
    }

    const legend = [{ label: legendLabel, color: theme.palette.primary.light }];

    const data: ChartData<'line', { t: Moment, y: number }[]> = {
        datasets: [
            {
                xAxisID: "x",
                yAxisID: "Mtr",
                label: "MTR",
                fill: false,
                borderColor: theme.palette.primary.light,
                backgroundColor: theme.palette.primary.light,
                data: mtrData.mtrData.map((value, index) => ({
                    t: moment(mtrData.time[index], "DD.MM.YYYY HH:mm:ss"),
                    y: value
                })),
                parsing:
                {
                    xAxisKey: 't',
                    yAxisKey: 'y'
                }
            }
        ]
    };

    let annotationBoxes: AnnotationOptions[] = [];

    if (blindTimes && blindTimes.length !== 0) {
        const color = theme.palette.warning.dark;
        blindTimes.forEach((dates) => {
            if (5 < dates.to.diff(dates.from, "minutes")) {
                annotationBoxes.push({
                    type: "box",
                    drawTime: "beforeDatasetsDraw",
                    xMin: dates.from.format("YYYY-MM-DD HH:mm:ss"),
                    xMax: dates.to.format("YYYY-MM-DD HH:mm:ss"),
                    xScaleID: "x",
                    yScaleID: "Mtr",
                    borderColor: color,
                    backgroundColor: color
                }
                );
            }
        });
        legend.push({ label: t("mtr.blindtimes"), color: color });
    }

    /*
    if (shutDowns && shutDowns.length !== 0) {
        const color = theme.palette.success.light;
        shutDowns.forEach((dates) => {
            options.annotation?.annotations.push({
                type: "box",
                drawTime: "beforeDatasetsDraw",
                xMin: dates.from,
                xMax: dates.to,
                yMax: 0,
                yMin: maxHeight,
                xScaleID: "x-axis-0",
                yScaleID: "y-axis-0",
                backgroundColor: color
            });
        });
        legend.push({ label: t("mtr.shutdowns"), color: color });
    }*/

    if (chartFilters.shutdowns) {
        const color = theme.palette.error.light;

        interface ShutdownData {
            t: moment.Moment;
            y: number;
        }

        const shutdownData: ShutdownData[] = [];
        let shutdownTimeTotal = 0;
        mtrData.shutdownState.forEach((value, index): void => {
            if (value === true) {
                shutdownTimeTotal += 5 / 60;
                shutdownData.push({ t: moment(mtrData.time[index], "DD.MM.YYYY HH:mm:ss"), y: mtrData.mtrThresholdShutdown[index] });
            } else {
                shutdownData.push({ t: moment(mtrData.time[index], "DD.MM.YYYY HH:mm:ss"), y: 0 });
            }
        });

        data.datasets.push({
            xAxisID: "x",
            yAxisID: "Mtr",
            label: "Shutdowns",
            fill: true,
            borderColor: color,
            data: shutdownData,
            backgroundColor: color,
            parsing:
            {
                xAxisKey: 't',
                yAxisKey: 'y'
            }
        });
        legend.push({ label: t("mtr.shutdowns") + " (Total: " + shutdownTimeTotal.toFixed(2) + "h)", color: color });
    }

    if (chartFilters.nightCycles && chartRef !== null && chartRef.current) {
        const ctx = chartRef.current.ctx;
        const axis = chartRef.current.scales["x"];
        const gradientDawn = ctx.createLinearGradient(axis.left, 0, axis.left + axis.width, 0);
        const colorNight = "rgba(0, 0, 0, 0.15)";
        const colorDay = "transparent";

        const start = moment(mtrData.time[0], "DD.MM.YYYY HH:mm:ss");
        const end = moment(mtrData.time[mtrData.time.length - 1], "DD.MM.YYYY HH:mm:ss");
        const totalTime = +end - +start;
        const current = moment(mtrData.time[0], "DD.MM.YYYY HH:mm:ss");

        let currentDayDawnStartDate = getSunrise(props.radar.siteLatitude, props.radar.siteLongitude, start.toDate());
        let currentDayDawnStart = moment(currentDayDawnStartDate);
        currentDayDawnStart.subtract(1, "hour");
        currentDayDawnStart.add(currentDayDawnStartDate.getTimezoneOffset(), "minutes");

        let currentDayDawnEnd = moment(currentDayDawnStartDate);
        currentDayDawnEnd.add(currentDayDawnStartDate.getTimezoneOffset(), "minutes");

        let currentDayDuskStartDate = getSunset(props.radar.siteLatitude, props.radar.siteLongitude, start.toDate());
        let currentDayDuskStart = moment(currentDayDuskStartDate);
        currentDayDuskStart.add(currentDayDuskStartDate.getTimezoneOffset(), "minutes");

        let currentDayDuskEnd = moment(currentDayDuskStartDate);
        currentDayDuskEnd.add(1, "hour");
        currentDayDuskEnd.add(currentDayDuskStartDate.getTimezoneOffset(), "minutes");

        while (currentDayDawnStart < end) {
            if (start < currentDayDawnStart) {
                const position = (+currentDayDawnStart - +start) / totalTime;
                gradientDawn.addColorStop(position, colorNight);
            }
            if (start < currentDayDawnEnd && currentDayDawnEnd < end) {
                const position = (+currentDayDawnEnd - +start) / totalTime;
                gradientDawn.addColorStop(position, colorDay);
            }
            if (start < currentDayDuskStart && currentDayDuskStart < end) {
                const position = (+currentDayDuskStart - +start) / totalTime;
                gradientDawn.addColorStop(position, colorDay);
            }
            if (start < currentDayDuskEnd && currentDayDuskEnd < end) {
                const position = (+currentDayDuskEnd - +start) / totalTime;
                gradientDawn.addColorStop(position, colorNight);
            }
            current.add(1, "day");

            currentDayDawnStartDate = getSunrise(props.radar.siteLatitude, props.radar.siteLongitude, current.toDate());
            currentDayDawnStart = moment(currentDayDawnStartDate);
            currentDayDawnStart.subtract(1, "hour");
            currentDayDawnStart.add(currentDayDawnStartDate.getTimezoneOffset(), "minutes");

            currentDayDawnEnd = moment(currentDayDawnStartDate);
            currentDayDawnEnd.add(currentDayDawnStartDate.getTimezoneOffset(), "minutes");

            currentDayDuskStartDate = getSunset(props.radar.siteLatitude, props.radar.siteLongitude, current.toDate());
            currentDayDuskStart = moment(currentDayDuskStartDate);
            currentDayDuskStart.add(currentDayDuskStartDate.getTimezoneOffset(), "minutes");

            currentDayDuskEnd = moment(currentDayDuskStartDate);
            currentDayDuskEnd.add(1, "hour");
            currentDayDuskEnd.add(currentDayDuskStartDate.getTimezoneOffset(), "minutes");
        }

        annotationBoxes.push({
            type: "box",
            drawTime: "beforeDatasetsDraw",
            xMin: start.format("YYYY-MM-DD HH:mm:ss"),
            xMax: end.format("YYYY-MM-DD HH:mm:ss"),
            xScaleID: "x",
            yScaleID: "Mtr",
            borderColor: "grey",
            backgroundColor: gradientDawn
        });
    }

    const options: ChartOptions<'line'> = {
        elements: { point: { radius: 0 } },
        scales: {
            x:
            {
                type: "time",
                time: {
                    unit: filterData.endDate.diff(filterData.startDate, "day") > 2 ? "day" : "hour",
                    tooltipFormat: "DD.MM.YYYY HH:mm",
                    displayFormats: {
                        day: "DD.MM.YYYY",
                        hour: "DD.MM.YYYY HH:mm"
                    }
                },
                ticks: {
                    maxRotation: 45,
                    padding: 0,
                    labelOffset: 0,
                    maxTicksLimit: 12
                }
            }
            ,
            Mtr:
            {
                ticks: {
                    //beginAtZero: true,
                    callback: (value, index, ticks): number | string => {
                        if (ticks.length !== 0) {
                            getGraphHeight(ticks[0].value as number);
                        }
                        return value;
                    }
                }
            }
        },
        animation: {
            duration: 0
        },
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                display: false
            },
            annotation:{
                annotations: annotationBoxes
            }
        }
    };

    if (chartFilters.threshold) {
        const colorDark = theme.palette.success.dark;
        data.datasets.push({
            xAxisID: "x",
            yAxisID: "Mtr",
            label: "ThresholdShutdown",
            fill: false,
            borderColor: colorDark,
            backgroundColor: colorDark,
            data: mtrData.mtrThresholdShutdown.map((value, index) => ({
                t: moment(mtrData.time[index], "DD.MM.YYYY HH:mm:ss"),
                y: value
            })),
            parsing:
            {
                xAxisKey: 't',
                yAxisKey: 'y'
            }
        });
        legend.push({ label: t("mtr.thresholdShutdown"), color: colorDark });
        const colorLight = theme.palette.success.light;
        data.datasets.push({
            xAxisID: "x",
            yAxisID: "Mtr",
            label: "ThresholdRestart",
            fill: false,
            borderColor: colorLight,
            backgroundColor: colorLight,
            data: mtrData.mtrThresholdRestart.map((value, index) => ({
                t: moment(mtrData.time[index], "DD.MM.YYYY HH:mm:ss"),
                y: value
            })),
            parsing:
            {
                xAxisKey: 't',
                yAxisKey: 'y'
            }
        });
        legend.push({ label: t("mtr.thresholdRestart"), color: colorLight });
    }

    const showShutdownStuff =
        state.user?.role === UserRole.Operator ||
        state.user?.role === UserRole.Reader ||
        state.user?.role === UserRole.Admin;

    const showCsvDownloads =
        state.user?.role === UserRole.AnalystWithDataPermission || state.user?.role === UserRole.Admin;

    const csvMtrHeaders = [
        { label: t("mtr.csvHeaderTime"), key: "time" },
        { label: t("mtr.csvHeaderMtr"), key: "mtr" }
    ];

    const csvMtrData: CsvHistoricExportData[] = [];
    mtrData.mtrData.forEach((value, index): void => {
        csvMtrData.push({ mtr: value.toString(), time: mtrData.time[index] });
    });

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
                <FilterMtrChartHistoric onChangeFilterData={changeFilterData} radar={props.radar} />
            </Grid>
            <Grid item xs={12} md={10}>
                <Card className={classes.item}>
                    <CardContent style={{ position: "relative" }}>
                        <Typography variant="overline">
                            <Trans>mtr.title</Trans>
                        </Typography>
                        <Grid container direction="row" justifyContent="space-evenly">
                            {showShutdownStuff ? (
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={chartFilters.threshold}
                                                onChange={onChartFilterChange}
                                                name={ChartFiltersType.threshold}
                                                color="primary"
                                            />
                                        }
                                        label={t("mtr.threshold")}
                                    />
                                </Grid>
                            ) : (
                                ""
                            )}
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={chartFilters.blindTimes}
                                            onChange={onChartFilterChange}
                                            name={ChartFiltersType.blindTimes}
                                            color="primary"
                                        />
                                    }
                                    label={t("mtr.blindtimes") + " ( > 5 min )"}
                                />
                            </Grid>
                            {showShutdownStuff ? (
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={chartFilters.shutdowns}
                                                onChange={onChartFilterChange}
                                                name={ChartFiltersType.shutdowns}
                                                color="primary"
                                            />
                                        }
                                        label={t("mtr.shutdowns")}
                                    />
                                </Grid>
                            ) : (
                                ""
                            )}
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={chartFilters.nightCycles}
                                            onChange={onChartFilterChange}
                                            name={ChartFiltersType.nightCycles}
                                            color="primary"
                                        />
                                    }
                                    label={t("mtr.nightcycles")}
                                />
                            </Grid>
                        </Grid>
                        <CustomLegend legend={legend} />
                        <Line ref={chartRef} data={data} options={options} plugins={[]} />
                        <br></br>
                        {showCsvDownloads ? (
                            <>
                                <Grid container direction="row" justifyContent="flex-end">
                                    <Grid item>
                                        <CSVLink
                                            style={{ color: theme.palette.primary.main }}
                                            data={csvMtrData}
                                            headers={csvMtrHeaders}
                                            separator={";"}
                                            filename={"mtrValuesHistoric.csv"}
                                        >
                                            <Trans>mtr.downloadCsv</Trans>
                                        </CSVLink>
                                    </Grid>
                                </Grid>
                                <br></br>
                            </>
                        ) : (
                            ""
                        )}
                        <Divider />
                        <br></br>
                        <ChartDirection
                            radar={props.radar}
                            startDate={filterData.startDate}
                            endDate={filterData.endDate}
                            filtered={false}
                            class={-1}
                            className={""}
                            minHeight={-1}
                            maxHeight={-1}
                        />
                        {isLoading && (
                            <Box className={classes.circularProgress}>
                                <CircularProgress></CircularProgress>
                            </Box>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};
