import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, CircularProgress, Grid, makeStyles } from "@material-ui/core";
import { Trans } from "react-i18next";
import { Config } from "../../config/app";

const useStyles = makeStyles({
    root: {},
    tempContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-center"
    },
    iconContainer: {
        display: "flex",
        justifyContent: "flex-end"
    },
    icon: {
        width: 80,
        height: 80
    }
});

interface CardWeatherProps {
    class: string;
    coordinates: {
        lat: number;
        long: number;
    };
}
interface StringDictionary {
    [key: string]: string;
}
const iconsMap: StringDictionary = {
    "01d": "clear",
    "02d": "mostlysunny",
    "03d": "cloudy",
    "04d": "mostlycloudy",
    "09d": "rain",
    "10d": "chancerain",
    "11d": "tstorms",
    "13d": "snow",
    "50d": "fog",
    "01n": "nt_clear",
    "02n": "nt_mostlysunny",
    "03n": "nt_cloudy",
    "04n": "nt_mostlycloudy",
    "09n": "nt_rain",
    "10n": "nt_chancerain",
    "11n": "nt_tstorms",
    "13n": "nt_snow",
    "50n": "nt_fog"
};

export const CardWeather = (props: CardWeatherProps): React.ReactElement => {
    const classes = useStyles();
    const [weather, setWeather] = useState<{
        temparature?: number;
        iconId?: string;
        loading: boolean;
    }>({ loading: true });

    useEffect(() => {
        const getWeather = (): void => {
            fetch(
                `https://api.openweathermap.org/data/2.5/weather?lat=${props.coordinates.lat}&lon=${props.coordinates.long}&appid=${Config.openWeatherKey}&units=metric`
            )
                .then((response) => response.json())
                .then((data) => {
                    const iconOpenDataWeather = data.weather[0].icon;
                    const icon = iconOpenDataWeather in iconsMap ? iconsMap[iconOpenDataWeather] : "unknown";
                    setWeather({ loading: false, temparature: data.main.temp, iconId: icon });
                })
                .catch((error) => console.log(error));
        };
        const interval = setInterval(() => getWeather(), 1000 * 60 * 5);
        getWeather();
        return (): void => clearInterval(interval);
    }, [setWeather, props.coordinates.lat, props.coordinates.long]);

    return (
        <Card className={props.class}>
            <CardContent>
                <Typography variant="overline">
                    <Trans>sidebar.cardWeather.title</Trans>
                </Typography>
                {weather.loading ? (
                    <Grid container>
                        <Grid item xs={12}>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container>
                        <Grid item xs={1} className={classes.tempContainer} />
                        <Grid item xs={5} className={classes.tempContainer}>
                            <Typography variant="h4">{weather.temparature?.toFixed(0) || 0}°</Typography>
                        </Grid>
                        {weather.iconId && (
                            <>
                                <Grid item xs={5} className={classes.iconContainer}>
                                    <img
                                        className={classes.icon}
                                        src={`/images/weather_icons/${weather.iconId}.svg`}
                                        alt="temp"
                                    />
                                </Grid>
                                <Grid item xs={1} className={classes.tempContainer} />
                            </>
                        )}
                    </Grid>
                )}
            </CardContent>
        </Card>
    );
};
