import React, { useEffect, useContext, useState } from "react";
import { Routes, Route, useParams, Navigate, Outlet } from "react-router";
import { emptyRouteParameters, AppRoutesPath, to } from "../utils/routes";
import { Live } from "./radar/Live";
import { Historic } from "./radar/Historic";
import { Analytic } from "./radar/Analytic";
import { Shutdown } from "./radar/Shutdown";
import { Reporting } from "./radar/Reporting";
import { SystemStatus } from "./radar/SystemStatus";
import { PageSettings } from "./Page";
import { Grid } from "@material-ui/core";
import { Sidebar } from "../components/sidebar/Sidebar";
import { AppContext, UserRole } from "../store/context";
import { fetchPost } from "../utils/fetch";
import { useTranslation } from "react-i18next";
import { Font } from "@react-pdf/renderer";
import { ProductEvent } from "./administration/Dashboard";

export interface Mr1 {
    id: number;
    name: string;
    active: boolean;
    getPdpViaFtp: boolean;
    comment: string;
    type: string;
    siteId: string;
    siteName: string;
    siteCode: string;
    siteLongitude: number;
    siteLatitude: number;
    serialNumber: string;
    lastContact: string;
    customer: string;
    projectName: string;
    displayName: string;
    lastDataTimestamp: string;
    numberOfWindmills: number;
    events: ProductEvent[];
}

interface ProductMr1Props {
    onEnterPage(settings: PageSettings): void;
}

export const ProductMr1 = (props: ProductMr1Props): React.ReactElement => {
    const { radarid } = useParams<{ radarid: string }>();
    const { t } = useTranslation();
    const { onEnterPage } = props;

    const { state } = useContext(AppContext);
    const [radar, setRadar] = useState<Mr1 | undefined>(undefined);

    useEffect(() => {
        //ms this is needed for reporting. Done here, so Font is always available and is not reloaded
        Font.register({ family: "calibri", src: "/fonts/calibri.ttf" });
        Font.register({ family: "calibriItalic", src: "/fonts/calibrii.ttf" });
        Font.register({ family: "calibriBold", src: "/fonts/calibrib.ttf" });
        Font.register({ family: "calibriBoldItalic", src: "/fonts/calibriz.ttf" });

        if (state.user) {
            fetchPost("get_all_databases.php", { params: { token: state.user.token } })
                .then((response) => response.json())
                .then((data) => {
                    if (data.databases.mr1 && data.databases.mr1.length !== 0) {
                        data.databases.mr1.forEach((radar: Mr1, index: number) => {
                            if (radar.id.toString() === radarid) {
                                setRadar({ ...radar });

                                const tabs = [
                                    { label: "Live", to: to(AppRoutesPath.radarLive, { ...emptyRouteParameters, radarid: radarid }) },
                                    {
                                        label: t("historic.title"),
                                        to: to(AppRoutesPath.radarHistoric, { ...emptyRouteParameters, radarid: radarid })
                                    }
                                ];
                                if (
                                    state.user?.role === UserRole.Analyst ||
                                    state.user?.role === UserRole.AnalystWithDataPermission ||
                                    state.user?.isAdmin
                                ) {
                                    tabs.push({
                                        label: t("analytic.title"),
                                        to: to(AppRoutesPath.radarAnalytic, { ...emptyRouteParameters, radarid: radarid })
                                    });
                                }
                                if (
                                    state.user?.role === UserRole.Operator ||
                                    state.user?.role === UserRole.Reader ||
                                    state.user?.isAdmin
                                ) {
                                    tabs.push({
                                        label: t("shutdownParameters.tab"),
                                        to: to(AppRoutesPath.radarShutdown, { ...emptyRouteParameters, radarid: radarid })
                                    });
                                    tabs.push({
                                        label: t("reporting.title"),
                                        to: to(AppRoutesPath.radarReporting, { ...emptyRouteParameters, radarid: radarid })
                                    });
                                }
                                if (
                                    state.user?.role === UserRole.Analyst ||
                                    state.user?.role === UserRole.AnalystWithDataPermission ||
                                    state.user?.isAdmin
                                ) {
                                    tabs.push({
                                        label: t("system.title"),
                                        to: to(AppRoutesPath.radarSystem, { ...emptyRouteParameters, radarid: radarid })
                                    });
                                }

                                onEnterPage({
                                    title: radar.siteName,
                                    backRoute: AppRoutesPath.root,
                                    tabs: {
                                        tabs: tabs
                                    }
                                });
                            }
                        });
                    }
                });
        }
    }, [radarid, setRadar, onEnterPage, state.user, t]);

    const showShutdownStuff =
        state.user?.role === UserRole.Operator || state.user?.role === UserRole.Reader || state.user?.isAdmin;

    const showAnalyticStuff =
        state.user?.role === UserRole.Analyst ||
        state.user?.role === UserRole.AnalystWithDataPermission ||
        state.user?.isAdmin;

    if (!radar) {
        return <Grid container>Loading</Grid>;
    } else {
        return (
            <Grid container spacing={2}>
                <Outlet context={radar}></Outlet>
                <Grid item xs={12} md={2}>
                    <Sidebar radar={radar} />
                </Grid>
            </Grid>
        );
    }
};
