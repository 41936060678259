import React, { useCallback, useContext } from "react";
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Grid,
    Card,
    CardContent,
    Container,
    Button,
    makeStyles,
    Theme,
    CircularProgress,
    Checkbox
} from "@material-ui/core";
import { Trans } from "react-i18next";
import { AppContext } from "../../store/context";
import { fetchPost } from "../../utils/fetch";
import { User } from "./UserList";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    root: {
        marginTop: 10,
        marginBottom: 10
    },
    list: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: "relative",
        overflow: "auto",
        maxHeight: 1000
    },
    item: {
        padding: 0
    }
}));

export interface Database {
    id: number;
    name: string;
    displayName: string;
    siteName: string;
    siteId: number;
}

interface DatabaseListProps {
    databases: Database[] | undefined;
    databasesUser: Database[] | undefined;
    user: User | undefined;
    onChangeDatabasePermissionsConfirmed: () => void;
}

export const DatabaseList = (props: DatabaseListProps): React.ReactElement => {
    const classes = useStyles();
    const { state } = useContext(AppContext);
    const [databasesChanges, setdatabasesChanges] = React.useState(new Map());

    const handleListItemClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>, databaseId: number) => {
            const newDatabasesChanges = new Map();

            let hasBeenChanged = false;
            if (databasesChanges.size !== 0) {
                databasesChanges.forEach((value, key, map) => {
                    if (key === databaseId) {
                        const wasOriginalySelected = props.databasesUser
                            ? props.databasesUser.findIndex((databaseUser) => databaseUser.id === databaseId) !== -1
                            : false;
                        if (wasOriginalySelected === value) {
                            newDatabasesChanges.set(databaseId, !value);
                        }
                        hasBeenChanged = true;
                    } else {
                        newDatabasesChanges.set(key, value);
                    }
                });
            }

            if (!hasBeenChanged) {
                const wasSelected = props.databasesUser
                    ? props.databasesUser.findIndex((databaseUser) => databaseUser.id === databaseId) !== -1
                    : false;
                newDatabasesChanges.set(databaseId, !wasSelected);
            }

            setdatabasesChanges(newDatabasesChanges);
        },
        [setdatabasesChanges, databasesChanges, props.databasesUser]
    );

    const onChangeDatabasePermissionsClicked = useCallback(() => {
        if (state.user && props.user && databasesChanges.size !== 0) {
            fetchPost("send_database_permissions.php", {
                params: {
                    token: state.user.token,
                    permissions: JSON.stringify(Array.from(databasesChanges.entries())),
                    userId: props.user.id.toString()
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data) {
                        console.log("database permissions sent");
                    }
                });
        }

        props.onChangeDatabasePermissionsConfirmed();
    }, [databasesChanges, props, state.user]);

    if (!props.databases) {
        return <CircularProgress></CircularProgress>;
    }

    return (
        <Container maxWidth="xs" className={classes.root}>
            <Card>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <List component="nav" aria-label="databases" className={classes.list}>
                                {props.databases.map((database) => {
                                    let hasPermission = props.databasesUser
                                        ? props.databasesUser.findIndex(
                                              (databaseUser) => databaseUser.id === database.id
                                          ) !== -1
                                        : false;

                                    if (databasesChanges.has(database.id)) {
                                        hasPermission = databasesChanges.get(database.id);
                                    }

                                    if (props.user?.role === "Admin") {
                                        hasPermission = true;
                                    }

                                    return (
                                        <ListItem
                                            dense
                                            button
                                            onClick={(event): void => handleListItemClick(event, database.id)}
                                            key={database.id}
                                            classes={{ root: classes.item }}
                                        >
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={hasPermission}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={database.siteName + " (" + database.siteId + ")"}
                                                secondary={database.name}
                                            />
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={onChangeDatabasePermissionsClicked}
                                fullWidth
                            >
                                <Trans>administration.changeDatabasePermissions</Trans>
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Container>
    );
};
