import React, { useState, useContext, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { fetchPost } from "../../../utils/fetch";
import { AppContext } from "../../../store/context";
import { Config } from "../../../config/app";

interface ThumbnailImageProps {
    radarName: string;
    fileName: string;
}
const tryRequire = (path: string): boolean => {
    try {
        return require(`${path}`);
    } catch (err) {
        return false;
    }
};

export const ThumbnailImage = (props: ThumbnailImageProps): React.ReactElement => {
    const { state } = useContext(AppContext);

    const [imageData, setimageData] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        const requestThumbnailData = (): void => {
            if (state.user && imageData === undefined) {
                if (tryRequire(`${Config.thumbnails}${props.radarName}/${props.fileName}`)) {
                    console.log("allready fetched " + props.fileName);
                } else {
                    fetchPost("get_thumbnail_via_ftp.php", {
                        params: {
                            token: state.user.token,
                            databaseName: props.radarName,
                            fileName: props.fileName
                        }
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.success) {
                                setimageData(true);
                            }
                        });
                }
            }
        };

        requestThumbnailData();

        return;
    }, [props, state.user, imageData]);

    const spaceLeft = 50;
    const spaceTop = 30;
    const width = 100;
    const height = 100 - spaceTop;

    if (imageData === undefined) {
        return (
            <div
                style={{
                    width: width,
                    height: height,
                    marginLeft: spaceLeft,
                    marginTop: spaceTop,
                    position: "relative",
                    alignContent: "center",
                    alignItems: "center"
                }}
            >
                <CircularProgress
                    style={{
                        alignContent: "center",
                        alignItems: "center"
                    }}
                ></CircularProgress>
            </div>
        );
    }

    return <img src={`${Config.thumbnails}${props.radarName}/${props.fileName}`} alt={props.fileName} />;
};
