import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 45,
        paddingHorizontal: 35
    },
    overviewRow: {
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        marginTop: 15,
        marginBottom: 20
    },
    overviewCol50: {
        width: "50%",
        flexDirection: "column",
        alignItems: "center"
    },
    overviewCol100: {
        width: "100%",
        flexDirection: "column",
        alignItems: "center"
    },
    titleContainer: {
        width: "100%",
        alignItems: "center",
        marginBottom: 25,
        marginTop: 10
    },
    titleRow: {
        width: "93.3%",
        flexDirection: "row"
    },
    titleText: {
        width: "100%",
        fontSize: 22,
        textAlign: "center",
        fontFamily: "calibri",
        marginBottom: 0
    },
    subTitleText: {
        width: "100%",
        fontSize: 14,
        textAlign: "center",
        fontFamily: "calibri"
    },
    logo: {
        height: 28,
        width: 150,
        marginLeft: 5,
        marginBottom: 4
    },
    headerRow: {
        position: "absolute",
        top: 15,
        left: 35,
        right: 35,
        width: "100%",
        marginBottom: 15,
        flexDirection: "row",
        borderBottomColor: "black",
        borderBottomWidth: 0.5
    },
    headerCol: {
        width: "33.3%",
        flexDirection: "row",
        alignItems: "flex-end"
    },
    headerTextLeft: {
        width: "100%",
        fontSize: 10,
        color: "grey",
        fontFamily: "calibri",
        textAlign: "left"
    },
    headerTextRight: {
        width: "100%",
        fontSize: 10,
        color: "grey",
        fontFamily: "calibri",
        textAlign: "right"
    },
    headerTextCenter: {
        width: "100%",
        fontSize: 10,
        color: "grey",
        fontFamily: "calibri",
        textAlign: "center"
    },
    headerSpacer: {
        height: 30
    },
    footNote: {
        position: "absolute",
        bottom: "7%",
        left: 35,
        right: 35
    },
    footNoteRow: {
        flexDirection: "row"
    },
    footNoteCol: {
        width: "60%",
        flexDirection: "column",
        fontFamily: "calibri",
        fontSize: 7,
        textAlign: "left"
    },
    footNoteColBold: {
        flexDirection: "column",
        fontFamily: "calibriBold",
        fontSize: 7,
        textAlign: "left"
    },
    footNoteLine: {
        width: "20%",
        height: 0,
        borderTop: 0.5,
        borderColor: "black",
        marginBottom: 2
    },
    footerRow: {
        position: "absolute",
        width: "100%",
        bottom: 15,
        left: 35,
        right: 35,
        flexDirection: "row",
        borderTopColor: "black",
        borderTopWidth: 0.5
    },
    footerColEdge: {
        width: "15%",
        flexDirection: "row",
        alignItems: "flex-start",
        marginTop: 4
    },
    footerColCenter: {
        width: "70%",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 4
    },
    footerDateText: {
        fontFamily: "calibri",
        fontSize: 10,
        color: "grey",
        textAlign: "left"
    },
    footerPageText: {
        fontFamily: "calibri",
        fontSize: 10,
        color: "grey",
        textAlign: "right"
    },
    footerContactText: {
        fontFamily: "calibri",
        fontSize: 10,
        color: "grey"
    },
    table: {
        width: "100%",
        //display: "table",
        borderStyle: "solid",
        alignItems: "center",
        marginBottom: 10
    },
    tableRow: {
        margin: 0,
        flexDirection: "row"
    },
    tableCol: {
        width: "23%",
        flexDirection: "column",
        borderStyle: "solid",
        backgroundColor: "#f0f0f0",
        margin: 1
    },
    tableCellDescription: {
        marginTop: 2,
        marginRight: 1,
        marginLeft: 1,
        padding: 1,
        fontSize: 9,
        fontFamily: "calibri"
    },
    tableCellValue: {
        marginBottom: 0,
        marginLeft: 1,
        padding: 0,
        fontSize: 15,
        fontFamily: "calibri"
    },
    tableColWindmill: {
        width: "23%",
        flexDirection: "column",
        borderStyle: "solid",
        backgroundColor: "#f0f0f0",
        margin: 1
    },
    tableColParameter: {
        width: "80%",
        flexDirection: "column",
        margin: 0
    },
    tableColParameterValue: {
        width: "25%",
        flexDirection: "column",
        textAlign: "right",
        margin: 1
    },
    tableCellParameterTitle: {
        marginBottom: 0,
        marginTop: 3,
        marginLeft: 2,
        padding: 0,
        fontSize: 8,
        fontFamily: "calibri"
    },
    tableCellParameter: {
        marginBottom: 0,
        marginLeft: 5,
        padding: 0,
        fontSize: 7,
        fontFamily: "calibri"
    },
    tableCellParameterValue: {
        width:'105%',
        textAlign: "right",
        marginBottom: 0,
        marginLeft: 68,
        padding: -1,
        fontSize: 7,
        fontFamily: "calibri"
    },
    tableCellUnit: {
        marginTop: 4.5,
        marginLeft: 1,
        padding: 0,
        fontSize: 9,
        fontFamily: "calibri"
    },
    tableFootNote: {
        marginTop: 2,
        marginLeft: 0,
        padding: 0,
        fontSize: 6,
        fontFamily: "calibri"
    },
    graphTableContainerPortrait: {
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "flex-start",
        marginTop: 10,
        marginLeft: 40
    },
    graphTableCol: {
        height: 100,
        flexDirection: "column",
        alignItems: "center"
    },
    graphTableRow: {
        flexDirection: "row",
        alignItems: "center",
        width: "100%"
    },
    graphLegendText: {
        marginTop: 8,
        fontSize: 11,
        fontFamily: "calibriItalic"
    },
    graphLegendFigure: {
        fontSize: 11,
        fontFamily: "calibriBoldItalic"
    },
    graphTableText: {
        fontSize: 12,
        fontFamily: "calibri",
        textAlign: "justify",
        marginTop: 4,
        marginLeft: 3
    },
    imageLandscape: {
        width: "97%"
    },
    imagePortrait: {
        width: "150%"
    },
    pieChartContainer: {
        flexDirection: "row",
        justifyContent: "center",
        marginLeft: "5%",
        width: "95%"
    },
    pieChartContainerWindmills: {
        flexDirection: "column",
        justifyContent: "center",
        marginLeft: "5%",
        width: "95%"
    },
    pieChartRow: {
        flexDirection: "row"
    },
    pieChartCol: {
        position: "relative",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 50,
        marginRight: 50
    },
    pieChart: {
        height: 150
    },
    pieChartColWindmill: {
        position: "relative",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 20,
        marginBottom: 0,
        marginRight: -25,
        marginLeft: -25
    },
    pieChartWindmill: {
        height: 85
    },
    pieChartTitle: {
        position: "relative",
        fontSize: 10,
        fontFamily: "calibri",
        textAlign: "center",
        top: "-45%",
        left: "0%"
    },
    pieLegendText: {
        marginTop: 8,
        fontSize: 11,
        fontFamily: "calibriItalic"
    },
    dividingLinePortrait: {
        width: "80%",
        height: 0,
        borderTop: 0.2,
        borderColor: "#606060",
        alignSelf: "center"
    }
});
