import React from "react";
import { makeStyles, Theme, Typography, Box } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
const useStyles = makeStyles<Theme>((theme: Theme) => ({
    root: {
        flex: 0,
        background: theme.palette.secondary.main,
        textAlign: "center",
        padding: theme.spacing(1)
    },
    link: {
        color: "inherit"
    }
}));

export const Footer = (): React.ReactElement => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Box className={classes.root}>
            <Typography variant="body2">
                <Trans>footer.title</Trans> | <Trans>footer.address</Trans> | <Trans>footer.city</Trans>
            </Typography>
            <Typography variant="body2">
                <a href={`tel: ${t("footer.phone")}`} className={classes.link}>
                    <Trans>footer.phone</Trans>
                </a>{" "}
                |{" "}
                <a href={`mailto: ${t("footer.mail")}`} className={classes.link}>
                    <Trans>footer.mail</Trans>
                </a>
            </Typography>
        </Box>
    );
};
