import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import { useTheme } from "@material-ui/core";
import { Mr1 } from "../../../views/ProductMr1";

//import { useTranslation } from "react-i18next";
import { Doughnut } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import { fetchPost } from "../../../utils/fetch";
import { Moment } from "moment";
import { AppContext } from "../../../store/context";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";

interface PieOperationTimeData {
    operationTime: number;
    blindTime: number;
    observationTime: number;
}

interface ReportDataPieOperationTimeProps {
    radar: Mr1;
    dateFrom: Moment;
    dateTo: Moment;
    onChangeDataImage: (newImageData: string) => void;
}

export const ReportDataPieOperationTime = React.memo(
    (props: ReportDataPieOperationTimeProps): React.ReactElement => {
        const { state } = useContext(AppContext);
        const chartRef = useRef<ChartJSOrUndefined<"doughnut", number[],string>>(null);
        const theme = useTheme();
        //        const { t } = useTranslation();

        const [isLoading, setisLoading] = useState(true);
        const [operationTimes, setoperationTimes] = useState<undefined | PieOperationTimeData>(undefined);

        const getImage = useCallback((): string => {
            if (chartRef.current) {
                const base64Image = chartRef.current.toBase64Image();
                return base64Image;
            }
            return "";
        }, [chartRef]);

        useEffect(() => {
            const requestOperationTimesData = (): void => {
                if (state.user) {
                    fetchPost("get_overall_radar_status.php", {
                        params: {
                            token: state.user.token,
                            databaseName: props.radar.name,
                            dateFrom: props.dateFrom.format("YYYY-MM-DD HH:mm:ss"),
                            dateTo: props.dateTo.format("YYYY-MM-DD HH:mm:ss")
                        }
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            setoperationTimes(data);
                            setisLoading(false);
                        });
                }
            };

            requestOperationTimesData();

            const id = setInterval(() => {
                const image = getImage();
                if ("" !== image) {
                    props.onChangeDataImage(getImage());
                    clearInterval(id);
                }
            }, 500);

            return (): void => clearInterval(id);
        }, [props.radar.name, state.user, chartRef, getImage, props]);

        if (isLoading) {
            return <div />;
        }
        if (operationTimes === undefined) {
            return <div />;
        }

        const operationTime = parseFloat((operationTimes.operationTime / 60).toFixed(0));
        const blindTime = parseFloat((operationTimes.blindTime / 60).toFixed(0));
        const observationTime = parseFloat((operationTimes.observationTime / 60).toFixed(0));

        const totalDateRangeTime = props.dateTo.diff(props.dateFrom, "h");

        const systemDownTimePercentage = ((totalDateRangeTime - operationTime) / totalDateRangeTime) * 100;
        const observationTimePercentage = (observationTime / totalDateRangeTime) * 100;
        const blindTimePercentage = (blindTime / totalDateRangeTime) * 100;

        const data = {
            datasets: [
                {
                    data: [observationTimePercentage, blindTimePercentage, systemDownTimePercentage],
                    backgroundColor: [theme.palette.primary.main, theme.palette.grey[500], theme.palette.grey[800]]
                }
            ],

            labels: ["observation", "blind", "system down"]
        };

        interface Context {
            dataIndex: number;
        }

        const options = {
            animation: {
                animateRotate: false
            },
            plugins: {
                legend: {
                    labels: {
                        font :{
                            size: 60
                        },
                    }
                },
                datalabels: {
                legend: {
                    labels: {
                        font :{
                            size: 60
                        },
                    }
                },
                    display: true,
                    font: {
                        size: 60
                    },
                    color: "white",
                    formatter: function (value: number): string {
                        return Math.round(value) + "%";
                    }
                }
            }
        };

        return <Doughnut data={data} options={options} ref={chartRef} />;
    }
);
