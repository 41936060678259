import React, { useCallback, useContext } from "react";
import { Card, CardContent, Grid, Typography, Box, Button } from "@material-ui/core";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AppContext } from "../../store/context";
import { Mr1 } from "../../views/ProductMr1";

import { Trans } from "react-i18next";
import moment, { Moment } from "moment";
import { UITypes } from "../../store/reducer";

export interface FilterMtrChartHistoricData {
    startDate: Moment;
    endDate: Moment;
}

interface FilterMtrChartHistoricProps {
    onChangeFilterData: (newFilterData: FilterMtrChartHistoricData) => void;
    radar: Mr1;
}

export const FilterMtrChartHistoric = (props: FilterMtrChartHistoricProps): React.ReactElement => {
    const { state } = useContext(AppContext);
    const { dispatch } = useContext(AppContext);
    const selectedFilterData = state.ui.filter.historic;

    const setSelectedFilterData = useCallback(
        (data: FilterMtrChartHistoricData) => {
            dispatch({
                type: UITypes.SetHistoricFilter,
                payload: data
            });
        },
        [dispatch]
    );
    const handleDateStartChange = (date: Moment | null): void => {
        setSelectedFilterData({ ...selectedFilterData, startDate: moment(date).utc() });
    };
    const handleDateEndChange = (date: Moment | null): void => {
        setSelectedFilterData({ ...selectedFilterData, endDate: moment(date).utc() });
    };

    const { onChangeFilterData } = props;
    const updateFilterData = useCallback((): void => {
        onChangeFilterData(selectedFilterData);
    }, [selectedFilterData, onChangeFilterData]);

    return (
        <Card>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography variant="overline">
                            <Trans>filter.title</Trans>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterMoment} >
                            <DatePicker
                                format="DD.MM.YYYY"
                                label={<Trans>filter.dateStart</Trans>}
                                value={selectedFilterData.startDate}
                                onChange={handleDateStartChange}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterMoment} >
                            <DatePicker
                                format="DD.MM.YYYY"
                                label={<Trans>filter.dateEnd</Trans>}
                                value={selectedFilterData.endDate}
                                onChange={handleDateEndChange}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
                        <Box marginTop={2}>
                            <Button variant="contained" color="primary" type="submit" onClick={updateFilterData}>
                                <Trans>filter.reload</Trans>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
