import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import {
    Grid,
    Card,
    CardContent,
    Typography,
    makeStyles,
    useTheme,
    Theme,
    CircularProgress,
    Box,
    FormControlLabel,
    Checkbox,
    Divider
} from "@material-ui/core";
import { Line } from "react-chartjs-2";
import { Trans, useTranslation } from "react-i18next";

import { fetchPost } from "../../utils/fetch";
import { Mr1 } from "../../views/ProductMr1";
import { FilterMtrChartAnalytic, FilterMtrChartAnalyticData } from "../filter/FilterMtrChartAnalytic";
import { AppContext, UserRole } from "../../store/context";
import { Chart, ChartData, ChartOptions } from "chart.js";
import annotationPlugin, { AnnotationOptions } from 'chartjs-plugin-annotation';
import moment, { Moment } from "moment-timezone";
import { CustomLegend } from "./CustomLegend/CustomLegend";
import { ChartDirection } from "./ChartDirection";
import { CSVLink } from "react-csv";
import { getSunrise, getSunset } from "sunrise-sunset-js";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";

Chart.register(annotationPlugin);

interface DateRange {
    from: moment.Moment;
    to: moment.Moment;
}
interface MtrData {
    mtr: number[];
    time: string[];
    encounters: number[];
    name: string;
}
interface DayNightData {
    isDay: boolean[];
    time: string[];
}
interface ChartFiltersStatus {
    blindTimes: boolean;
    nightCycles: boolean;
}
enum ChartFiltersType {
    blindTimes = "blindtimes",
    nightCycles = "nightcycles"
}

interface CsvAnalyticExportData {
    mtr: string;
    time: string;
    class: string;
    minHeight: string;
    maxHeight: string;
    resolution: string;
}

interface MtrChartAnalyticProps {
    radar: Mr1;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    item: {
        marginBottom: theme.spacing(2)
    },
    circularProgress: {
        position: "absolute",
        top: "50%",
        left: "50%"
    }
}));

export const MtrChartAnalytic = (props: MtrChartAnalyticProps): React.ReactElement => {
    const { state } = useContext(AppContext);
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const [chartFilters, setChartFilters] = useState<ChartFiltersStatus>({
        blindTimes: false,
        nightCycles: false
    });
    const [mtrData, setmtrData] = useState<undefined | MtrData>(undefined);
    const [dayNightData, setdayNightData] = useState<undefined | DayNightData>(undefined);
    const [blindTimes, setBlindTimes] = useState<DateRange[] | undefined>([]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const chartRef = useRef<ChartJSOrUndefined<"line", { t: Moment, y: number }[], string>>(null);

    const [isLoading, setisLoading] = useState<boolean>(true);

    const [filterData, setfilterDate] = useState<FilterMtrChartAnalyticData>(state.ui.filter.analytic);

    useEffect(() => {
        const requestMtrData = (currentFilterData: FilterMtrChartAnalyticData): void => {
            if (state.user) {
                setisLoading(true);
                fetchPost("get_mtr_graph.php", {
                    params: {
                        token: state.user.token,
                        databaseName: props.radar.name,
                        siteId: props.radar.siteId,
                        classId: currentFilterData.class.toString(),
                        minAltitude: currentFilterData.minHeight.toString(),
                        maxAltitude: currentFilterData.maxHeight.toString(),
                        dateFrom: currentFilterData.startDate.format("YYYY-MM-DD") + " 00:00:00",
                        dateTo: currentFilterData.endDate.format("YYYY-MM-DD") + " 23:59:59",
                        resolution: currentFilterData.resolution.toString()
                    }
                })
                    .then((response) => response.json())
                    .then((data) => {
                        setmtrData(data);
                        setisLoading(false);
                    });
            }
        };
        requestMtrData(filterData);
    }, [filterData, props, state.user]);

    useEffect(() => {
        const requestDayNightData = (currentFilterData: FilterMtrChartAnalyticData): void => {
            if (state.user) {
                setisLoading(true);
                fetchPost("get_mtr_graph_real.php", {
                    params: {
                        token: state.user.token,
                        databaseName: props.radar.name,
                        siteId: props.radar.siteId,
                        dateFrom: currentFilterData.startDate.format("YYYY-MM-DD") + " 00:00:00",
                        dateTo: currentFilterData.endDate.format("YYYY-MM-DD") + " 23:59:59"
                    }
                })
                    .then((response) => response.json())
                    .then((data) => {
                        setdayNightData(data);
                        setisLoading(false);
                    });
            }
        };
        requestDayNightData(filterData);
    }, [filterData, props, state.user]);

    useEffect(() => {
        if (chartFilters.blindTimes && state.user) {
            //Make Request
            fetchPost("get_blind_times.php", {
                params: {
                    token: state.user.token,
                    databaseName: props.radar.name,
                    siteId: props.radar.siteId,
                    dateFrom: filterData.startDate.format("YYYY-MM-DD") + " 00:00:00",
                    dateTo: filterData.endDate.format("YYYY-MM-DD") + " 23:59:59"
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    setBlindTimes(
                        data.blindTimes.map((time: { blindFrom: string; blindTo: string }) => {
                            return {
                                from: moment(time.blindFrom, "YYYY-MM-DD HH:mm:ss"), //2020-09-15 22:30:06
                                to: moment(time.blindTo, "YYYY-MM-DD HH:mm:ss")
                            };
                        })
                    );
                });
        }
    }, [filterData, setBlindTimes, chartFilters.blindTimes, state.user, props.radar.name, props.radar.siteId]);

    const changeFilterData = useCallback(
        (newFilterData: FilterMtrChartAnalyticData): void => {
            setfilterDate(newFilterData);
            setisLoading(true);
        },
        [setfilterDate, setisLoading]
    );

    const onChartFilterChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const newChartFilterSettings = { ...chartFilters };
            switch (event.target.name) {
                case ChartFiltersType.blindTimes: {
                    newChartFilterSettings.blindTimes = !chartFilters.blindTimes;
                    if (newChartFilterSettings.blindTimes === false) {
                        setBlindTimes(undefined);
                    }
                    break;
                }
                case ChartFiltersType.nightCycles: {
                    newChartFilterSettings.nightCycles = !chartFilters.nightCycles;
                    break;
                }
                default: {
                    break;
                }
            }
            setChartFilters(newChartFilterSettings);
        },
        [chartFilters, setChartFilters, setBlindTimes]
    );

    if (mtrData === undefined) {
        return <div />;
    }

    const legend = [
        {
            label: t("classifier." + mtrData.name) + " (" + t("mtr.timezone") + " UTC)",
            color: theme.palette.primary.main
        }
    ];

    const data: ChartData<'line', { t: Moment, y: number }[]> = {
        datasets: [
            {
                xAxisID: "x",
                yAxisID: "Mtr",
                label: t("classifier." + mtrData.name),
                fill: false,
                borderColor: theme.palette.primary.light,
                data: mtrData.mtr.map((value, index) => ({
                    t: moment(mtrData.time[index], "DD.MM.YYYY HH:mm:ss"),
                    y: value
                })),
                parsing:
                {
                    xAxisKey: 't',
                    yAxisKey: 'y'
                }
            }
        ]
    };

    let annotationBoxes : AnnotationOptions[] = [];

    if (blindTimes && blindTimes.length !== 0 ) {
        const color = theme.palette.warning.dark;

        blindTimes.forEach((dates) => {
            annotationBoxes.push({
                    type: "box",
                    drawTime: "beforeDatasetsDraw",
                    xMin: dates.from.format("YYYY-MM-DD HH:mm:ss"), 
                    xMax: dates.to.format("YYYY-MM-DD HH:mm:ss"),
                    xScaleID: "x",
                    yScaleID: "Mtr",
                    borderColor: color,
                    backgroundColor: color
                }
            );
        });
        legend.push({ label: t("mtr.blindtimes"), color: color });
    }

    
    if (dayNightData && chartFilters.nightCycles && chartRef !== null && chartRef.current) {
        const ctx = chartRef.current.ctx;
        const axis = chartRef.current.scales["x"];
        const gradientDawn = ctx.createLinearGradient(axis.left, 0, axis.left + axis.width, 0);
        const colorNight = "rgba(0, 0, 0, 0.15)";
        const colorDay = "transparent";

        const start = moment(mtrData.time[0], "DD.MM.YYYY HH:mm:ss");
        const end = moment(mtrData.time[mtrData.time.length - 1], "DD.MM.YYYY HH:mm:ss");
        const totalTime = +end - +start;
        const current = moment(mtrData.time[0], "DD.MM.YYYY HH:mm:ss");

        let currentDayDawnStartDate = getSunrise(props.radar.siteLatitude, props.radar.siteLongitude, start.toDate());
        let currentDayDawnStart = moment(currentDayDawnStartDate);
        currentDayDawnStart.subtract(1, "hour");
        currentDayDawnStart.add(currentDayDawnStartDate.getTimezoneOffset(), "minutes");

        let currentDayDawnEnd = moment(currentDayDawnStartDate);
        currentDayDawnEnd.add(currentDayDawnStartDate.getTimezoneOffset(), "minutes");

        let currentDayDuskStartDate = getSunset(props.radar.siteLatitude, props.radar.siteLongitude, start.toDate());
        let currentDayDuskStart = moment(currentDayDuskStartDate);
        currentDayDuskStart.add(currentDayDuskStartDate.getTimezoneOffset(), "minutes");

        let currentDayDuskEnd = moment(currentDayDuskStartDate);
        currentDayDuskEnd.add(1, "hour");
        currentDayDuskEnd.add(currentDayDuskStartDate.getTimezoneOffset(), "minutes");

        while (currentDayDawnStart < end) {
            if (start < currentDayDawnStart) {
                const position = (+currentDayDawnStart - +start) / totalTime;
                gradientDawn.addColorStop(position, colorNight);
            }
            if (start < currentDayDawnEnd && currentDayDawnEnd < end) {
                const position = (+currentDayDawnEnd - +start) / totalTime;
                gradientDawn.addColorStop(position, colorDay);
            }
            if (start < currentDayDuskStart && currentDayDuskStart < end) {
                const position = (+currentDayDuskStart - +start) / totalTime;
                gradientDawn.addColorStop(position, colorDay);
            }
            if (start < currentDayDuskEnd && currentDayDuskEnd < end) {
                const position = (+currentDayDuskEnd - +start) / totalTime;
                gradientDawn.addColorStop(position, colorNight);
            }
            current.add(1, "day");

            currentDayDawnStartDate = getSunrise(props.radar.siteLatitude, props.radar.siteLongitude, current.toDate());
            currentDayDawnStart = moment(currentDayDawnStartDate);
            currentDayDawnStart.subtract(1, "hour");
            currentDayDawnStart.add(currentDayDawnStartDate.getTimezoneOffset(), "minutes");

            currentDayDawnEnd = moment(currentDayDawnStartDate);
            currentDayDawnEnd.add(currentDayDawnStartDate.getTimezoneOffset(), "minutes");

            currentDayDuskStartDate = getSunset(props.radar.siteLatitude, props.radar.siteLongitude, current.toDate());
            currentDayDuskStart = moment(currentDayDuskStartDate);
            currentDayDuskStart.add(currentDayDuskStartDate.getTimezoneOffset(), "minutes");

            currentDayDuskEnd = moment(currentDayDuskStartDate);
            currentDayDuskEnd.add(1, "hour");
            currentDayDuskEnd.add(currentDayDuskStartDate.getTimezoneOffset(), "minutes");
        }

        annotationBoxes.push({
            type: "box",
            drawTime: "beforeDatasetsDraw",
            xMin: start.format("YYYY-MM-DD HH:mm:ss"),
            xMax: end.format("YYYY-MM-DD HH:mm:ss"),
            xScaleID: "x",
            yScaleID: "Mtr",
            borderColor: "grey",
            backgroundColor: gradientDawn
        });
    }

    const options: ChartOptions<'line'> = {
        elements: { point: { radius: 1 } },
        spanGaps: false,
        scales: {
            x:
            {
                type: "time",
                time: {
                    unit: filterData.endDate.diff(filterData.startDate, "day") > 2 ? "day" : "hour",
                    tooltipFormat: "DD.MM.YYYY HH:mm",
                    displayFormats: {
                        day: "DD.MM.YYYY",
                        hour: "DD.MM.YYYY HH:mm"
                    }
                },
                ticks: {
                    maxRotation: 45,
                    padding: 0,
                    labelOffset: 0,
                    maxTicksLimit: 12
                }
            }
        },
        plugins: {
            datalabels: {
                display: false
            },
            legend: {
                display: false
            },
            annotation:{
                annotations: annotationBoxes
            }
        }
    };

    const showCsvDownloads =
        state.user?.role === UserRole.AnalystWithDataPermission || state.user?.role === UserRole.Admin;

    const csvHeaders = [
        { label: t("mtr.csvHeaderTime"), key: "time" },
        { label: t("mtr.csvHeaderMtr"), key: "mtr" },
        { label: t("filter.class"), key: "class" },
        { label: t("filter.minHeight") + " [m]", key: "minHeight" },
        { label: t("filter.maxHeight") + " [m]", key: "maxHeight" },
        { label: t("filter.resolution") + " [min]", key: "resolution" }
    ];

    const csvData: CsvAnalyticExportData[] = [];
    mtrData.mtr.forEach((value, index): void => {
        if (0 === index) {
            csvData.push({
                mtr: value.toString(),
                time: mtrData.time[index],
                class: t("classifier." + mtrData.name),
                minHeight: filterData.minHeight.toString(),
                maxHeight: filterData.maxHeight.toString(),
                resolution: filterData.resolution.toString()
            });
        } else {
            csvData.push({
                mtr: value.toString(),
                time: mtrData.time[index],
                class: "",
                minHeight: "",
                maxHeight: "",
                resolution: ""
            });
        }
    });
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
                <FilterMtrChartAnalytic onChangeFilterData={changeFilterData} radar={props.radar} />
            </Grid>
            <Grid item xs={12} md={10}>
                <Card className={classes.item}>
                    <CardContent style={{ position: "relative" }}>
                        <Typography variant="overline">
                            <Trans>mtr.title</Trans>
                        </Typography>
                        <Grid container direction="row" justifyContent="space-evenly">
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={chartFilters.blindTimes}
                                            onChange={onChartFilterChange}
                                            name={ChartFiltersType.blindTimes}
                                            color="primary"
                                        />
                                    }
                                    label={t("mtr.blindtimes")}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={chartFilters.nightCycles}
                                            onChange={onChartFilterChange}
                                            name={ChartFiltersType.nightCycles}
                                            color="primary"
                                        />
                                    }
                                    label={t("mtr.nightcycles")}
                                />
                            </Grid>
                        </Grid>
                        <CustomLegend legend={legend} />
                        <Line ref={chartRef} data={data} options={options} plugins={[annotationPlugin]} />
                        <br></br>
                        {showCsvDownloads ? (
                            <>
                                <Grid container direction="row" justifyContent="flex-end">
                                    <Grid item>
                                        <CSVLink
                                            style={{ color: theme.palette.primary.main }}
                                            data={csvData}
                                            headers={csvHeaders}
                                            separator={";"}
                                            filename={"mtrValuesAnalytic.csv"}
                                        >
                                            <Trans>mtr.downloadCsv</Trans>
                                        </CSVLink>
                                    </Grid>
                                </Grid>
                                <br></br>
                            </>
                        ) : (
                            ""
                        )}
                        <Divider />
                        <br></br>
                        <ChartDirection
                            radar={props.radar}
                            startDate={filterData.startDate}
                            endDate={filterData.endDate}
                            filtered={true}
                            class={filterData.class}
                            className={t("classifier." + mtrData.name)}
                            minHeight={filterData.minHeight}
                            maxHeight={filterData.maxHeight}
                        />
                        {isLoading && (
                            <Box className={classes.circularProgress}>
                                <CircularProgress></CircularProgress>
                            </Box>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};
