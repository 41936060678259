import React, { useState, useCallback, useContext } from "react";
import { Appbar, Tab } from "../components/Appbar";
import { Routes, Route } from "react-router";
import { AppRoutesPath } from "../utils/routes";
import { Home } from "./Home";
import { ProductMr1 } from "./ProductMr1";
import { ProductAc1 } from "./ProductAc1";
import { Administration } from "./Administration";
import { Logout } from "./Logout";
import { Container, makeStyles } from "@material-ui/core";
import { Live } from "./radar/Live";
import { Historic } from "./radar/Historic";
import { AppContext, UserRole } from "../store/context";
import { Analytic } from "./radar/Analytic";
import { SystemStatus } from "./radar/SystemStatus";
import { Shutdown } from "./radar/Shutdown";
import { Reporting } from "./radar/Reporting";
import { Dashboard } from "./administration/Dashboard";
import { ManageUsers } from "./administration/ManageUsers";
import { AddDatabase } from "./administration/AddDatabase";
import { Ac1Live } from "./aeroecologycam/Ac1Live";
import { Ac1SystemStatus } from "./aeroecologycam/Ac1SystemStatus";

type PageProps = React.PropsWithChildren<{}>;

export interface PageSettings {
    title: string;
    backRoute: AppRoutesPath | null;
    tabs: {
        tabs: Tab[];
    } | null;
}

const useStyles = makeStyles({
    container: {
        marginTop: 20
    }
});

export const Page = (props: PageProps): React.ReactElement => {
    const classes = useStyles();
    const [pageSettings, SetPageSettings] = useState<PageSettings>({ title: "", backRoute: null, tabs: null });
    const { state } = useContext(AppContext);

    const enterPage = useCallback(
        (newSettings: PageSettings) => {
            SetPageSettings({ ...newSettings });
        },
        [SetPageSettings]
    );


    const showShutdownStuff =
        state.user?.role === UserRole.Operator || state.user?.role === UserRole.Reader || state.user?.isAdmin;

    const showAnalyticStuff =
        state.user?.role === UserRole.Analyst ||
        state.user?.role === UserRole.AnalystWithDataPermission ||
        state.user?.isAdmin;

    return (
        <>
            <Appbar settings={pageSettings} />
            <Container maxWidth="xl" className={classes.container}>
                <Routes>
                    <Route path={AppRoutesPath.root} element={<Home onEnterPage={enterPage} />} />
                    <Route path={AppRoutesPath.radar} element={<ProductMr1 onEnterPage={enterPage} />} >
                        <Route path={AppRoutesPath.radarLive} element={<Live />} />
                        <Route path={AppRoutesPath.radarHistoric} element={<Historic />} />
                        {showAnalyticStuff ? (
                            <>
                                <Route path={AppRoutesPath.radarAnalytic} element={<Analytic />} />
                                <Route path={AppRoutesPath.radarSystem} element={<SystemStatus />} />
                            </>
                        ) : (
                            ""
                        )}
                        {showShutdownStuff ? (
                            <>
                                <Route path={AppRoutesPath.radarShutdown} element={<Shutdown />} />
                                <Route path={AppRoutesPath.radarReporting} element={<Reporting />} />
                            </>
                        ) : (
                            ""
                        )}
                    </Route>
                    <Route path={AppRoutesPath.aeroecologycam} element={<ProductAc1 onEnterPage={enterPage} />} >
                        <Route path={AppRoutesPath.aeroecologycamLive} element={<Ac1Live /> }/>
                        {showAnalyticStuff ? (
                            <Route path={AppRoutesPath.aeroecologycamSystem} element={<Ac1SystemStatus />} />
                        ) : (
                            ""
                        )}
                    </Route>
                    {state.user?.isAdmin ? (
                        <Route path={AppRoutesPath.administration} element={<Administration onEnterPage={enterPage} />} >
                            <Route path={AppRoutesPath.administrationRadarDashboard} element={<Dashboard />} />
                            <Route path={AppRoutesPath.administrationManageUsers} element={<ManageUsers />} />
                            <Route path={AppRoutesPath.administrationAddDatabase} element={<AddDatabase />} />
                        </Route>
                    ) : ("")}
                    <Route path={AppRoutesPath.logout} element={<Logout />} />
                </Routes>
            </Container>
        </>
    );
};
