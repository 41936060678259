import React, { useEffect, useState, useContext } from "react";
import { Card, CardContent, Grid, Typography, CircularProgress } from "@material-ui/core";
import { fetchPost } from "../../utils/fetch";
import { AppContext } from "../../store/context";
import { Trans, useTranslation } from "react-i18next";
import { Mr1 } from "../../views/ProductMr1";
import moment from "moment-timezone";

interface WindparkDataCurrent {
    CalcVWind: number;
    CalcWDir: number;
    CalcP: number;
    CalcMax: number;
    CalcCosPhi: number;
    CalcQ: number;
    SetWfOpRet: number;
    timestamp: string;
}

interface WindparkData {
    currentWindparkValues: WindparkDataCurrent;
}

interface CardTurbineProps {
    class: string;
    radar: Mr1;
}

export const CardTurbine = (props: CardTurbineProps): React.ReactElement => {
    const [windparkData, setwindparkData] = useState<undefined | WindparkData>(undefined);

    const { t } = useTranslation();
    const { state } = useContext(AppContext);

    useEffect(() => {
        const requestWindparkData = (): void => {
            if (state.user) {
                fetchPost("get_windpark_measurements_current.php", {
                    params: {
                        token: state.user.token,
                        databaseName: props.radar.name
                    }
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data && data.success === true) {
                            setwindparkData(data);
                        }
                    });
            }
        };
        requestWindparkData();

        const id = setInterval(() => {
            requestWindparkData();
        }, 10000);

        return (): void => clearInterval(id);
    }, [state.user, setwindparkData, props.radar]);

    if (windparkData === undefined) {
        return (
            <Card className={props.class}>
                <CardContent style={{ textAlign: "center" }}>
                    <CircularProgress></CircularProgress>
                </CardContent>
            </Card>
        );
    }

    if (windparkData.currentWindparkValues === null) {
        return (
            <Card className={props.class}>
                <CardContent style={{ textAlign: "center" }}>
                    <Typography variant="overline">
                        <Trans>sidebar.cardTurbine.title</Trans>
                    </Typography>
                    <Typography variant="body1">{t("sidebar.cardTurbine.noTurbine")}</Typography>
                </CardContent>
            </Card>
        );
    }
    const getWindparkDataEntry = (data: string, name: string): React.ReactElement => {
        return (
            <Grid justifyContent="space-between" container>
                <Grid item>
                    <Typography variant="body1">{t(name)}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">{data}</Typography>
                </Grid>
            </Grid>
        );
    };

    return (
        <Card className={props.class}>
            <CardContent>
                <Typography variant="overline">
                    <Trans>sidebar.cardTurbine.title</Trans>
                </Typography>
                {windparkData.currentWindparkValues.CalcP &&
                    getWindparkDataEntry(
                        parseFloat(windparkData.currentWindparkValues.CalcP.toString()).toFixed(2).toString() + " kW",
                        "sidebar.cardTurbine.performance"
                    )}
                {windparkData.currentWindparkValues.CalcVWind &&
                    getWindparkDataEntry(
                        parseFloat(windparkData.currentWindparkValues.CalcVWind.toString()).toFixed(2).toString() +
                            " m/s",
                        "sidebar.cardTurbine.windspeed"
                    )}
                {windparkData.currentWindparkValues.CalcWDir &&
                    getWindparkDataEntry(
                        parseFloat(windparkData.currentWindparkValues.CalcWDir.toString()).toFixed(0).toString() + "°",
                        "sidebar.cardTurbine.windDirection"
                    )}
                {windparkData.currentWindparkValues.timestamp &&
                    getWindparkDataEntry(
                        moment
                            .tz(windparkData.currentWindparkValues.timestamp, "YYYY-MM-DD HH:mm:ss", "UTC")
                            .format("DD.MM.YYYY HH:mm:ss"),
                        "sidebar.cardTurbine.timestamp"
                    )}
            </CardContent>
        </Card>
    );
};
