import { Grid, Typography, Box, makeStyles } from "@material-ui/core";
import React from "react";

interface CustomLegendProps {
    legend: {
        label: string;
        color: string;
    }[];
}
const useStyles = makeStyles({
    item: {
        display: "flex",
        alignItems: "center"
    },
    line: {
        width: 20,
        height: 2,
        marginRight: 8,
        marginLeft: 16
    }
});

export const CustomLegend = (props: CustomLegendProps): React.ReactElement => {
    const classes = useStyles();
    return (
        <Box my={2}>
            <Grid container direction="row" justifyContent="center">
                {props.legend.map((item, index) => {
                    return (
                        <Grid item key={index} className={classes.item}>
                            <Box style={{ backgroundColor: item.color }} className={classes.line}></Box>
                            <Typography variant="caption">{item.label}</Typography>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};
