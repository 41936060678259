import React, { useContext, useState, useCallback, useEffect } from "react";
import {
    Container,
    TextField,
    Button,
    Grid,
    CardContent,
    Card,
    makeStyles,
    Theme,
    Typography,
    Box,
    CircularProgress,
    InputLabel
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/Select";
import { AppContext } from "../../store/context";
import { Trans, useTranslation } from "react-i18next";
import { fetchPost } from "../../utils/fetch";
import { Config } from "../../config/app";

interface FormData {
    username: { value: string; error: boolean };
    password: { value: string; error: boolean };
    passwordConfirmation: { value: string; error: boolean };
    email: { value: string; error: boolean };
    role: string;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    root: {
        marginTop: 30
    },
    logo: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: "100%"
    },
    form: {
        width: "100%"
    },
    inputContainer: {
        marginBottom: theme.spacing(2)
    },
    fullWidth: {
        width: "100%"
    }
}));

interface UserRole {
    id: number;
    name: string;
    description: string;
}

export const CreateUser = (): React.ReactElement => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { state } = useContext(AppContext);
    const [form, setForm] = useState<FormData>({
        username: { value: "", error: false },
        password: { value: "", error: false },
        passwordConfirmation: { value: "", error: false },
        email: { value: "", error: false },
        role: ""
    });
    const [error, setError] = useState<string>("");
    const [success, setSuccess] = useState<string>("");
    const [userRoles, setuserRoles] = useState<UserRole[] | undefined>([]);

    const requestUserRoles = useCallback((): void => {
        if (state.user) {
            fetchPost("get_user_roles.php", {
                params: {
                    token: state.user.token
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    setuserRoles(data.roles);
                });
        }
    }, [setuserRoles, state.user]);

    useEffect(() => {
        requestUserRoles();
    }, [requestUserRoles]);

    const onChangeInput = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const fieldKey = event.currentTarget.dataset.field;
            if (fieldKey) {
                setForm({ ...form, [fieldKey]: { value: event.target.value, error: false } });
            }

            setSuccess("");
            setError("");
        },
        [setForm, form]
    );

    const handleRoleChange = (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ): void => {
        const roleId = event.currentTarget.value as number;
        setForm({ ...form, role: roleId.toString() });
    };

    const onSubmitForm = useCallback(
        (event: React.FormEvent) => {
            setError("");
            event.preventDefault();

            if (form.username.value === "") {
                setError(t("error.field_missing", { field: t("login.username") }));
                setForm({ ...form, username: { ...form.username, error: true } });
                return;
            }
            if (form.password.value === "") {
                setError(t("error.field_missing", { field: t("login.password") }));
                setForm({ ...form, password: { ...form.password, error: true } });
                return;
            }
            if (form.passwordConfirmation.value === "") {
                setError(t("error.field_missing", { field: t("administration.passwordConfirmation") }));
                setForm({ ...form, passwordConfirmation: { ...form.passwordConfirmation, error: true } });
                return;
            }
            if (form.passwordConfirmation.value !== form.password.value) {
                setError(t("administration.passwordConfirmationFailed"));
                setForm({ ...form, passwordConfirmation: { ...form.passwordConfirmation, error: true } });
                return;
            }
            if (form.email.value === "") {
                setError(t("error.field_missing", { field: t("login.email") }));
                setForm({ ...form, email: { ...form.email, error: true } });
                return;
            }
            if (form.role === "") {
                setError(t("error.field_missing", { field: t("administration.role") }));
                setForm({ ...form, role: form.role });
                return;
            }

            if (state.user) {
                fetchPost("create_user.php", {
                    params: {
                        token: state.user.token,
                        username: form.username.value,
                        password: form.password.value,
                        email: form.email.value,
                        roleId: form.role.toString()
                    }
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data) {
                            if (data.success) {
                                setForm({
                                    username: { value: "", error: false },
                                    password: { value: "", error: false },
                                    passwordConfirmation: { value: "", error: false },
                                    email: { value: "", error: false },
                                    role: ""
                                });
                                setSuccess(t("administration.createUserSuccess"));
                                return;
                            }
                        }
                        setError(data.error || t("error.couldNotCreateUser"));
                    })
                    .catch((error) => {
                        setError(error.message);
                    });
            }
        },
        [form, setError, t, state.user]
    );

    if (!userRoles) {
        return (
            <Card className={classes.root}>
                <CardContent style={{ textAlign: "center" }}>
                    <CircularProgress></CircularProgress>
                </CardContent>
            </Card>
        );
    }

    return (
        <Container maxWidth="xs" className={classes.root}>
            <Grid container>
                <form onSubmit={onSubmitForm} className={classes.form}>
                    <Grid item xs={12}>
                        <img
                            src="../../images/swiss-birdradar_logo.png"
                            className={classes.logo}
                            alt={Config.appname}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.inputContainer}>
                        <TextField
                            label={t("login.username")}
                            inputProps={{ "data-field": "username" }}
                            value={form.username.value}
                            onChange={onChangeInput}
                            fullWidth
                            error={form.username.error}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.inputContainer}>
                        <TextField
                            label={t("login.password")}
                            inputProps={{ "data-field": "password" }}
                            type="password"
                            value={form.password.value}
                            onChange={onChangeInput}
                            fullWidth
                            error={form.password.error}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.inputContainer}>
                        <TextField
                            label={t("administration.passwordConfirmation")}
                            inputProps={{ "data-field": "passwordConfirmation" }}
                            type="password"
                            value={form.passwordConfirmation.value}
                            onChange={onChangeInput}
                            fullWidth
                            error={form.passwordConfirmation.error}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.inputContainer}>
                        <TextField
                            label={t("login.email")}
                            inputProps={{ "data-field": "email" }}
                            type="email"
                            value={form.email.value}
                            onChange={onChangeInput}
                            fullWidth
                            error={form.email.error}
                        />
                    </Grid>
                    <Grid item className={classes.fullWidth}>
                        {userRoles && (
                            <Box marginBottom={1}>
                                <FormControl className={classes.fullWidth}>
                                    <InputLabel htmlFor="rolesSelectLabel">
                                        <Trans>administration.role</Trans>
                                    </InputLabel>
                                    <NativeSelect
                                        native
                                        label={t("administration.role")}
                                        value={form.role}
                                        labelId="rolesSelectLabel"
                                        id="rolesSelect"
                                        onChange={handleRoleChange}
                                    >
                                        <option label="" key={-1} value={""} />
                                        {userRoles.map((role: UserRole, index) => {
                                            return <option label={role.name} key={role.id} value={role.id} />;
                                        })}
                                    </NativeSelect>
                                </FormControl>
                            </Box>
                        )}
                    </Grid>
                    <Grid item xs={12} className={classes.inputContainer}>
                        <Button variant="contained" color="primary" type="submit" fullWidth>
                            <Trans>administration.createUser</Trans>
                        </Button>
                    </Grid>
                </form>
            </Grid>
            {error && (
                <Grid container>
                    <Grid item>
                        <Typography variant="body1" color="error">
                            {error}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {success && (
                <Grid container>
                    <Grid item>
                        <Typography variant="body1" color="primary">
                            {success}
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </Container>
    );
};
