import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const options = {
    order: ["querystring", "navigator"],
    lookupQuerystring: "lng"
};

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            "de-CH": {
                translation: require("../assets/translations/de-CH/translations.json")
            },
            en: {
                translation: require("../assets/translations/en/translations.json")
            }
        },
        lng: "en",
        detection: options,
        fallbackLng: "en",
        supportedLngs: ["de-CH", "en"],
        debug: false,
        load: "all",
        interpolation: {
            escapeValue: false
        },
        //react: { useSuspense: false }
       // initImmediate: true,
    });

export default i18n;