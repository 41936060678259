import React, { useCallback, useContext, useState } from "react";
import { AppBar, IconButton, Theme, Toolbar, Typography, Tabs, Tab as TabComponent, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import InfoIcon from "@mui/icons-material/Info";
import SettingsIcon from "@mui/icons-material/Settings";
import { PageSettings } from "../views/Page";
import { useLocation, useNavigate } from "react-router";
import { AppRoutesPath } from "../utils/routes";
import { Link } from "react-router-dom";
import { UITypes } from "../store/reducer";
import { AppContext } from "../store/context";
import { Trans, useTranslation } from "react-i18next";
import { InfoDialog } from "./ui/Dialogs/InfoDialog";
import { UserDialog } from "./ui/Dialogs/UserDialog";
import { LanguagePicker } from "./ui/LanguagePicker";

export interface Tab {
    label: string;
    to: AppRoutesPath | string;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    root: {
        flexGrow: 0,
        textAlign: "center"
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1
    },
    tabs: {
        background: theme.palette.grey[200]
    }
}));

export const Appbar = (props: { settings: PageSettings }): React.ReactElement => {
    const { tabs } = props.settings;
    const { t } = useTranslation();
    const classes = useStyles();
    const location = useLocation();
    const navigate = useNavigate();
    const { dispatch } = useContext(AppContext);
    const { state } = useContext(AppContext);
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [showUser, setShowUser] = useState<boolean>(false);
    const onOpenAdministration = useCallback((): void => {
        navigate(AppRoutesPath.administration);
    }, [navigate]);

    const onClickConfirmLogout = useCallback(() => {
        dispatch({
            type: UITypes.HideDialog,
            payload: {}
        });
        navigate(AppRoutesPath.logout);
    }, [navigate, dispatch]);

    const onClickLogout = useCallback(() => {
        dispatch({
            type: UITypes.ShowDialog,
            payload: {
                title: t("logout.confirm-logout-title"),
                message: t("logout.confirm-logout"),
                buttons: [
                    <Button variant="contained" color="primary" onClick={onClickConfirmLogout}>
                        <Trans>label.yes</Trans>
                    </Button>
                ]
            }
        });
    }, [dispatch, t, onClickConfirmLogout]);

    const onToggleInfoDialog = useCallback(() => {
        setShowInfo(!showInfo);
    }, [showInfo, setShowInfo]);

    const onToggleUserDialog = useCallback(() => {
        setShowUser(!showUser);
    }, [showUser, setShowUser]);

    return (
        <AppBar position="relative" className={classes.root} color="inherit">
            <Toolbar>
                {props.settings.backRoute && (
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        component={Link}
                        to={props.settings.backRoute}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                )}

                <Typography variant="h6" className={classes.title}>
                    {props.settings.title}
                </Typography>
                {state.user ? (
                    state.user.isAdmin ? (
                        <IconButton edge="end" onClick={onOpenAdministration}>
                            <SettingsIcon />
                        </IconButton>
                    ) : (
                        ""
                    )
                ) : (
                    ""
                )}
                <LanguagePicker />
                <IconButton edge="end" onClick={onToggleInfoDialog}>
                    <InfoIcon />
                </IconButton>
                <IconButton edge="end" onClick={onToggleUserDialog}>
                    <AccountCircleIcon />
                </IconButton>
                <IconButton edge="end" onClick={onClickLogout}>
                    <ExitToAppIcon />
                </IconButton>
            </Toolbar>
            {tabs && location.pathname !== AppRoutesPath.root && location.pathname !== AppRoutesPath.logout && (
                <Tabs className={classes.tabs} value={location.pathname} indicatorColor="primary" centered>
                    {tabs.tabs.map((tab: Tab, index: number) => (
                        <TabComponent
                            label={tab.label}
                            key={`tab-${index}`}
                            value={tab.to}
                            component={Link}
                            to={tab.to}
                        />
                    ))}
                </Tabs>
            )}
            <InfoDialog showDialog={showInfo} onCloseDialog={onToggleInfoDialog} />
            <UserDialog showDialog={showUser} onCloseDialog={onToggleUserDialog} />
        </AppBar>
    );
};
