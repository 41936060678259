import { makeStyles } from "@material-ui/core";
import React from "react";

export interface Gradient {
    value: number;
    color: { r: number; g: number; b: number };
    label: string;
}

interface RadarImageGradientProps {
    width: number;
    height: number;
    gradient: Gradient[];
    label?: string;
}
const useStyles = makeStyles({
    root: {
        position: "absolute",
        right: 0,
        bottom: 0,
        top: 0
    },
    gradient: {
        overflow: "visible"
    }
});

export const RadarImageGradient = (props: RadarImageGradientProps): React.ReactElement => {
    const { width, height } = props;
    const classes = useStyles();
    const space = 15;

    return (
        <div className={classes.root} style={{ right: -width }}>
            <svg
                className={classes.gradient}
                width={width - space}
                height={height}
                viewBox={`0 0 ${width - space} ${height}`}
            >
                <defs>
                    <linearGradient id="Gradient" x1="0" x2="0" y1="1" y2="0">
                        {props.gradient.map((c, index) => {
                            return (
                                <stop
                                    key={`c-${index}`}
                                    offset={`${c.value * 100}%`}
                                    stopColor={`rgb(${c.color.r},${c.color.g},${c.color.b})`}
                                />
                            );
                        })}
                    </linearGradient>
                </defs>
                <rect x="0" y="0" width={width - space} height={height} fill="url(#Gradient)" stroke="black" />
                {props.gradient.map((c, index) => {
                    return (
                        <g key={`c-${index}`} transform={`translate(35, ${(height / 1) * c.value})`}>
                            <line x1="-20" x2={-space + 5} y1="0" y2="0" stroke="black" />
                            <text y="5" x={-space + 10}>
                                {c.label}
                            </text>
                        </g>
                    );
                })}
                {props.label && (
                    <text
                        y={height / 2}
                        x={-space + 80}
                        textAnchor="middle"
                        transform={`rotate(90,${-space + 80},${height / 2})`}
                    >
                        {props.label}
                    </text>
                )}
            </svg>
        </div>
    );
};
