import React from "react";
import { Mr1 } from "../ProductMr1";
import { Grid } from "@material-ui/core";
import { MtrChartAnalytic } from "../../components/charts/MtrChartAnalytic";
import { MtrTable } from "../../components/ui/MtrTable";
import { useOutletContext } from "react-router";

export const Analytic = (): React.ReactElement => {
    const radar = useOutletContext< Mr1 >();
    
    return (
        <Grid item xs={12} md={10}>
            <Grid container direction="column">
                <Grid container direction="row">
                    <Grid item md={2} />
                    <Grid item md={10}>
                        <MtrTable radar={radar} />
                    </Grid>
                </Grid>
                <Grid item>
                    <MtrChartAnalytic radar={radar} />
                </Grid>
            </Grid>
        </Grid>
    );
};
