import React, { useState, useEffect, useCallback, useContext } from "react";
import {
    Card,
    CardContent,
    Grid,
    Typography,
    TextField,
    Box,
    Button,
    InputLabel,
    makeStyles,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel
} from "@material-ui/core";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import NativeSelect from "@material-ui/core/Select";
import { AppContext } from "../../store/context";
import { fetchPost } from "../../utils/fetch";
import { Mr1 } from "../../views/ProductMr1";

import { Trans, useTranslation } from "react-i18next";
import moment, { Moment } from "moment";
import { UITypes } from "../../store/reducer";

export interface FilterMtrChartAnalyticData {
    startDate: Moment;
    endDate: Moment;
    class: number;
    minHeight: number;
    maxHeight: number;
    resolution: number;
}

interface ClassData {
    classNames: string[];
    classIds: number[];
}

interface FilterMtrChartAnalyticProps {
    onChangeFilterData: (newFilterData: FilterMtrChartAnalyticData) => void;
    radar: Mr1;
}

const useStyles = makeStyles({
    fullWidth: {
        width: "100%"
    },
    radioGroupContainer: {
        display: "flex",
        "& fieldset": {
            flex: 1
        }
    },
    resolutionRadio: {
        marginLeft: 5,
        marginRight: 5,
        marginTop: 12
    },
    radioGroup: {
        flexWrap: "nowrap",
        textAlign: "center",
        justifyContent: "center"
    },
    radioGroupLabel: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    }
});

export const FilterMtrChartAnalytic = (props: FilterMtrChartAnalyticProps): React.ReactElement => {
    const classes = useStyles();
    const { state } = useContext(AppContext);
    const { dispatch } = useContext(AppContext);
    const { t } = useTranslation();
    const selectedFilterData = state.ui.filter.analytic;

    const [classData, setclassData] = useState<ClassData | undefined>(undefined);
    const setSelectedFilterData = useCallback(
        (data: FilterMtrChartAnalyticData) => {
            dispatch({
                type: UITypes.SetAnalyticFilter,
                payload: data
            });
        },
        [dispatch]
    );
    const handleDateStartChange = (date: Moment | null): void => {
        setSelectedFilterData({ ...selectedFilterData, startDate: moment(date).utc() });
    };
    const handleDateEndChange = (date: Moment | null): void => {
        setSelectedFilterData({ ...selectedFilterData, endDate: moment(date).utc() });
    };
    const handleClassChange = (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ): void => {
        const classId = event.currentTarget.value as number;

        if (classData) {
            setSelectedFilterData({ ...selectedFilterData, class: classId });
        }
    };

    const handleMinHeightChange = useCallback(
        (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: unknown;
            }>
        ): void => {
            setSelectedFilterData({ ...selectedFilterData, minHeight: event.currentTarget.value as number });
        },
        [selectedFilterData, setSelectedFilterData]
    );

    const handleMaxHeightChange = (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ): void => {
        setSelectedFilterData({ ...selectedFilterData, maxHeight: event.currentTarget.value as number });
    };

    const handleResolutionChange = (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: string;
        }>
    ): void => {
        setSelectedFilterData({ ...selectedFilterData, resolution: +event.currentTarget.value });
    };
    const { onChangeFilterData } = props;
    const updateFilterData = useCallback((): void => {
        onChangeFilterData(selectedFilterData);
    }, [selectedFilterData, onChangeFilterData]);

    useEffect((): void => {
        if (props.radar && state.user) {
            fetchPost("get_monitor_classes.php", {
                params: {
                    token: state.user.token,
                    databaseName: props.radar.name,
                    useNewClassifier: "true"
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    setclassData(data);
                });
        }
    }, [props.radar, state.user]);

    return (
        <Card>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography variant="overline">
                            <Trans>filter.title</Trans>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterMoment} >
                            <DatePicker                            
                                format="DD.MM.YYYY"
                                label={<Trans>filter.dateStart</Trans>}
                                value={selectedFilterData.startDate}
                                onChange={handleDateStartChange}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterMoment} >
                            <DatePicker
                                format="DD.MM.YYYY"
                                label={<Trans>filter.dateEnd</Trans>}
                                value={selectedFilterData.endDate}
                                onChange={handleDateEndChange}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item className={classes.fullWidth}>
                        {classData && (
                            <Box marginTop={1}>
                                <FormControl className={classes.fullWidth}>
                                    <InputLabel htmlFor="classSelect">
                                        <Trans>filter.class</Trans>
                                    </InputLabel>
                                    <NativeSelect
                                        native
                                        defaultValue={selectedFilterData.class}
                                        id="classSelect"
                                        onChange={handleClassChange}
                                        label={<Trans>filter.class</Trans>}
                                    >
                                        <option label={t("classifier.all_birds")} key={"allBirds"} value={-1} />
                                        {classData.classNames.map((className: string, index) => {
                                            return (
                                                <option
                                                    label={t("classifier." + className)}
                                                    key={className}
                                                    value={classData.classIds[index]}
                                                />
                                            );
                                        })}
                                    </NativeSelect>
                                </FormControl>
                            </Box>
                        )}
                    </Grid>
                    <Grid item className={classes.fullWidth}>
                        <Box marginTop={2}>
                            <TextField
                                key="MinHeight"
                                type="number"
                                variant="standard"
                                defaultValue={selectedFilterData.minHeight}
                                label={<Trans>filter.minHeight</Trans>}
                                inputProps={{
                                    "data-field": "MinHeight",
                                    step: "10",
                                    style: { textAlign: "center", padding: 10 }
                                }}
                                onChange={handleMinHeightChange}
                                fullWidth
                            />
                        </Box>
                    </Grid>
                    <Grid item className={classes.fullWidth}>
                        <Box marginTop={2}>
                            <TextField
                                key="MaxHeight"
                                type="number"
                                variant="standard"
                                defaultValue={selectedFilterData.maxHeight}
                                label={<Trans>filter.maxHeight</Trans>}
                                inputProps={{
                                    "data-field": "MaxHeight",
                                    step: "10",
                                    style: { textAlign: "center", padding: 10 }
                                }}
                                onChange={handleMaxHeightChange}
                                fullWidth
                            />
                        </Box>
                    </Grid>
                    <Grid item className={classes.fullWidth}>
                        <Box marginTop={2} className={classes.radioGroupContainer}>
                            <FormControl component="fieldset">
                                <InputLabel shrink htmlFor="resolutionRadio">
                                    <Trans>filter.resolution</Trans>:
                                </InputLabel>
                                <RadioGroup row className={classes.radioGroup} onChange={handleResolutionChange}>
                                    <FormControlLabel
                                        value={5}
                                        checked={selectedFilterData.resolution === 5}
                                        control={<Radio color="primary" />}
                                        label={
                                            <Typography variant="caption" className={classes.radioGroupLabel}>
                                                <Trans>filter.resolution5</Trans>
                                            </Typography>
                                        }
                                        labelPlacement="bottom"
                                        className={classes.resolutionRadio}
                                    />
                                    <FormControlLabel
                                        value={15}
                                        checked={selectedFilterData.resolution === 15}
                                        control={<Radio color="primary" />}
                                        label={
                                            <Typography variant="caption" className={classes.radioGroupLabel}>
                                                <Trans>filter.resolution15</Trans>
                                            </Typography>
                                        }
                                        labelPlacement="bottom"
                                        className={classes.resolutionRadio}
                                    />
                                    <FormControlLabel
                                        value={60}
                                        checked={selectedFilterData.resolution === 60}
                                        control={<Radio color="primary" />}
                                        label={
                                            <Typography variant="caption" className={classes.radioGroupLabel}>
                                                <Trans>filter.resolution60</Trans>
                                            </Typography>
                                        }
                                        labelPlacement="bottom"
                                        className={classes.resolutionRadio}
                                    />
                                    <FormControlLabel
                                        value={1440}
                                        checked={selectedFilterData.resolution === 1440}
                                        control={<Radio color="primary" />}
                                        label={
                                            <Typography variant="caption" className={classes.radioGroupLabel}>
                                                <Trans>filter.resolution1440</Trans>
                                            </Typography>
                                        }
                                        labelPlacement="bottom"
                                        className={classes.resolutionRadio}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
                        <Box marginTop={2}>
                            <Button variant="contained" color="primary" type="submit" onClick={updateFilterData}>
                                <Trans>filter.reload</Trans>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
