import { createTheme } from "@material-ui/core";

export const theme = createTheme({
    palette: {
        primary: {
            main: "#41617C"
        },
        secondary: {
            main: "#C5E5FF"
        },
        background: {
            default: "#E9EEF0"
        },
        error: {
            main: "#B00020"
        },
        success: {
            main: "#51900A"
        }
    },
    overrides: {
        MuiButton: {
            root: {
                height: 36
            }
        }
    }
});
theme.typography.h5 = {
    fontSize: "1.2rem",
    "@media (min-width:600px)": {
        fontSize: "1.5rem"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "2.4rem"
    }
};
