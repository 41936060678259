import React, { useState, useContext, useEffect, useCallback } from "react";
import { Grid, Typography, Card, CardContent, IconButton, makeStyles, CircularProgress } from "@material-ui/core";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { fetchPost } from "../../../utils/fetch";
import { AppContext } from "../../../store/context";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Image, RadarImage } from "../../../components/ui/RadarImage/RadarImage";
import { Mr1 } from "../../../views/ProductMr1";
import { Trans, useTranslation } from "react-i18next";
import { UITypes } from "../../../store/reducer";
import moment, { Moment } from "moment";

const useStyles = makeStyles({
    content: {
        flex: 1,
        display: "flex",
        minHeight: 420,
        justifyContent: "flex-start",
        alignItems: "flex-start"
    }
});

export interface FilterRadarImageDisplayData {
    date: Moment;
    showOnlyFiltered: boolean;
}

interface RadarImageDisplayProps {
    radar: Mr1;
    selectedDate: string | undefined;
}

export const RadarImageDisplay = (props: RadarImageDisplayProps): React.ReactElement => {
    const { dispatch } = useContext(AppContext);
    const { state } = useContext(AppContext);
    const { t } = useTranslation();
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [images, setImages] = useState<Image[]>([]);

    const [selectedDate, setselectedDate] = useState<string | undefined>(undefined);
    const selectedFilterData = state.ui.filter.live.radarImage;
    const setSelectedFilterData = useCallback(
        (data: FilterRadarImageDisplayData) => {
            dispatch({
                type: UITypes.SetLiveRadarFilter,
                payload: data
            });
        },
        [dispatch]
    );

    useEffect(() => {
        if (state.user && isLoading === true) {
            let getThumbnailsBackendFile = "get_thumbnails.php";
            if (props.radar.getPdpViaFtp) {
                getThumbnailsBackendFile = "get_thumbnails_via_ftp.php";
            }

            fetchPost(getThumbnailsBackendFile, {
                params: {
                    token: state.user.token,
                    databaseName: props.radar.name,
                    dateFrom: selectedFilterData.date.format("yyyy-MM-DD"),
                    dateTo: selectedFilterData.date.format("yyyy-MM-DD"),
                    onlyFiltered: selectedFilterData.showOnlyFiltered.toString()
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.files) {
                        setImages(
                            data.files.map((file: string) => {
                                const dateTimeString = file.substring(
                                    props.radar.siteCode.length + 1,
                                    props.radar.siteCode.length + 16
                                );
                                const dateTimeStringCorrected = moment(dateTimeString, "YYYY-MM-DD_Hmm").format(
                                    "DD.MM.YYYY HH:mm"
                                );
                                const isFiltered = file.indexOf("filtered") !== -1;
                                return { name: file, dateTime: dateTimeStringCorrected, isFiltered };
                            })
                        );
                        setIsLoading(false);
                    }
                });
        }
    }, [
        state.user,
        selectedFilterData,
        isLoading,
        setIsLoading,
        setImages,
        props.radar.name,
        props.radar.siteCode,
        props.selectedDate,
        props.radar.getPdpViaFtp
    ]);

    useEffect(() => {
        setselectedDate(props.selectedDate);
    }, [props.selectedDate]);

    const onChangeDateFilter = useCallback( (date: Moment | null): void => {
        if (date) {
            setSelectedFilterData({ ...selectedFilterData, date: date });
            onApplyFilter();
        }
    }, [selectedFilterData.date, setIsLoading, t]);

    const onIncreaseDateFilter = useCallback( (): void => {
        setSelectedFilterData({ ...selectedFilterData, date: selectedFilterData.date.add(1, "day") });
        onApplyFilter();
    }, [selectedFilterData.date, setIsLoading, t]);

    const onDecreaseDateFilter = useCallback( (): void => {
        setSelectedFilterData({ ...selectedFilterData, date: selectedFilterData.date.add(-1, "day") });
        onApplyFilter();
    }, [selectedFilterData.date, setIsLoading, t]);

    const onApplyFilter = useCallback(() => {
        if (moment().isBefore(selectedFilterData.date)) {
            dispatch({
                type: UITypes.ShowDialog,
                payload: {
                    title: t("error.title"),
                    message: t("error.dateInFuture")
                }
            });
            setSelectedFilterData({ ...selectedFilterData, date: selectedFilterData.date.add(-1, "day") })
            return;
        }
        setIsLoading(true);
    }, [selectedFilterData.date, dispatch, setIsLoading, t]);

    return (
        <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={2}>
                <Card>
                    <CardContent>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography variant="overline">
                                    <Trans>filter.title</Trans>
                                </Typography>
                            </Grid>
                            <Grid container direction="row">
                                <Grid item xs={2}>
                                    <IconButton edge="start" color="inherit" onClick={onDecreaseDateFilter}>
                                        <ChevronLeftIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={8}>
                                    <LocalizationProvider dateAdapter={AdapterMoment} >
                                        <DatePicker
                                            format="DD.MM.YYYY"
                                            label={t("radarImageDisplay.date") + ":"}
                                            value={selectedFilterData.date}
                                            onChange={onChangeDateFilter}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton edge="end" color="inherit" onClick={onIncreaseDateFilter}>
                                        <ChevronRightIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={10}>
                <Card>
                    <CardContent className={classes.content}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography variant="overline">
                                    <Trans>radarImageDisplay.title</Trans>
                                </Typography>
                            </Grid>

                            <Grid item>
                                {isLoading ? (
                                    <CircularProgress></CircularProgress>
                                ) : (
                                    <RadarImage
                                        radarName={props.radar.name}
                                        siteCode={props.radar.siteCode}
                                        getPdpViaFtp={props.radar.getPdpViaFtp}
                                        images={images}
                                        selectedDate={selectedDate}
                                        showThumbs={5}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};
