import React, { useContext } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import { Trans } from "react-i18next";
import { AppContext } from "../../../store/context";

interface UserDialogProps {
    showDialog: boolean;
    onCloseDialog: () => void;
}

export const UserDialog = (props: UserDialogProps): React.ReactElement => {
    const { onCloseDialog } = props;
    const { state } = useContext(AppContext);
    return (
        <Dialog open={props.showDialog} onClose={onCloseDialog} fullWidth maxWidth="sm">
            <DialogTitle>
                <Trans>user.title</Trans>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <p>
                        <Trans>user.name</Trans> : {(state.user && state.user.name) || "unbekannt"}
                    </p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseDialog} color="primary" autoFocus>
                    <Trans>label.ok</Trans>
                </Button>
            </DialogActions>
        </Dialog>
    );
};
