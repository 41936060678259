import React, { useState, useContext, useEffect } from "react";
import { Mr1 } from "../ProductMr1";
import { Box, Card, CardContent, FormControl, Grid, InputLabel, makeStyles, Theme } from "@material-ui/core";
import { MtrChartHistoric } from "../../components/charts/MtrChartHistoric";
import { AppContext } from "../../store/context";
import NativeSelect from "@material-ui/core/Select";
import { fetchPost } from "../../utils/fetch";
import { Trans, useTranslation } from "react-i18next";
import { useOutletContext } from "react-router";

interface Windmill {
    name: string;
    number: number;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    item: {
        marginBottom: theme.spacing(2)
    },
    fullWidth: {
        width: "100%"
    }
}));


export const Historic = (): React.ReactElement => {
    const { state } = useContext(AppContext);
    const { t } = useTranslation();
    const classes = useStyles();
    const radar = useOutletContext< Mr1 >();

    const [windmills, setWindmills] = useState<undefined | Windmill[]>(undefined);
    const [selectedWindmillNumber, setSelectedWindmillNumber] = useState<number>(-1);

    const handleSelectedWindmillChange = (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ): void => {
        const windmillId = event.currentTarget.value as number;

        setSelectedWindmillNumber(windmillId);
    };

    useEffect(() => {
        const requestWindmills = (): void => {
            if (state.user) {
                fetchPost("get_windmills.php", {
                    params: {
                        token: state.user.token,
                        databaseName: radar.name
                    }
                })
                    .then((response) => response.json())
                    .then((data) => {
                        setWindmills(data.windmills);
                    });
            }
        };

        requestWindmills();

        return;
    }, [radar.name, state.user, setWindmills]);

    return (
        <Grid item xs={12} md={10}>
            <Grid container direction="column">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={2} />
                    <Grid item xs={3}>
                        {windmills && 0 < windmills.length && (
                            <Card className={classes.item}>
                                <CardContent>
                                    <Box marginTop={1} marginLeft={2} marginBottom={3}>
                                        <FormControl className={classes.fullWidth}>
                                            <InputLabel htmlFor="windmillSelect">
                                                <Trans>shutdownParameters.windmills</Trans>
                                            </InputLabel>
                                            <NativeSelect
                                                native
                                                defaultValue={-1}
                                                id="windmillSelect"
                                                onChange={handleSelectedWindmillChange}
                                                label={<Trans>shutdownParameters.windmills</Trans>}
                                            >
                                                <option
                                                    label={t("shutdownParameters.default")}
                                                    key={"standard"}
                                                    value={-1}
                                                />
                                                {windmills.map((windmill: Windmill) => {
                                                    const className = windmill.name;
                                                    return (
                                                        <option
                                                            label={className}
                                                            key={className}
                                                            value={windmill.number}
                                                        />
                                                    );
                                                })}
                                            </NativeSelect>
                                        </FormControl>
                                    </Box>
                                </CardContent>
                            </Card>
                        )}
                    </Grid>
                </Grid>
                <Grid item>
                    <MtrChartHistoric radar={radar} selectedWindmillNumber={selectedWindmillNumber} />
                </Grid>
            </Grid>
        </Grid>
    );
};
