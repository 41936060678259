import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import RemoveIcon from "@mui/icons-material/Remove";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { Navigate, Route } from "react-router";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Map, Position, MarkerTypes } from "../components/ui/Map";
import { AppContext } from "../store/context";
import { fetchPost } from "../utils/fetch";
import { emptyRouteParameters, AppRoutesPath, to } from "../utils/routes";
import { PageSettings } from "./Page";
import { Mr1 } from "./ProductMr1";
import { Ac1 } from "./ProductAc1";
import {
  Status,
  getStatusFromLastContactTime,
} from "../components/ui/StatusLight";
import moment from "moment-timezone";

interface Mr1Internal {
  id: string;
  letter: string;
  nameDatabase: string;
  nameSite: string;
  codeSite: string;
  position: Position;
  displayName: string;
  status: { wea: number; radar: Status };
}

interface Ac1Internal {
  id: string;
  letter: string;
  nameDatabase: string;
  nameSite: string;
  position: Position;
  displayName: string;
  status: Status;
}

const statusItemWidth = 60;
const buttonItemWidth = 110;

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  products: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    minHeight: 460,
    alignItems: "center",
  },
  listHeaderStatus: {
    width: statusItemWidth,
    justifyContent: "left",
    alignItems: "left",
    marginRight: 10,
  },
  listHeaderButton: {
    width: buttonItemWidth + 12,
  },
  listActionsGridItemButton: {
    display: "flex",
    alignItems: "center",
    width: buttonItemWidth,
    justifyContent: "flex-end",
  },
  listActionsGridItemStatus: {
    display: "flex",
    alignItems: "center",
    width: statusItemWidth,
    justifyContent: "center",
  },
}));

export const Home = (props: {
  onEnterPage(settings: PageSettings): void;
}): React.ReactElement => {
  const { onEnterPage } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const [openMr1Entries, setOpenMr1Entries] = React.useState(true);
  const [openAc1Entries, setOpenAc1Entries] = React.useState(true);

  const handleopenMr1EntriesClick = (): void => {
    setOpenMr1Entries(!openMr1Entries);
  };

  const handleopenAc1EntriesClick = (): void => {
    setOpenAc1Entries(!openAc1Entries);
  };

  const [listMr1, setListMr1] = useState<{
    radars: Mr1Internal[];
    loaded: boolean;
    error: string;
  }>({
    radars: [],
    loaded: false,
    error: "",
  });
  const [listAc1, setListAc1] = useState<{
    aeroecologycams: Ac1Internal[];
    loaded: boolean;
    error: string;
  }>({
    aeroecologycams: [],
    loaded: false,
    error: "",
  });

  const { state } = useContext(AppContext);
  const user = state.user;

  useEffect(() => {
    onEnterPage({ title: t("home.title"), backRoute: null, tabs: null });
    if (user) {
      fetchPost("get_all_databases.php", {
        params: { token: user.token },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.databases.mr1 && data.databases.mr1.length !== 0) {
            const newRadarList: Mr1Internal[] = data.databases.mr1.map(
              (radar: Mr1, index: number) => {
                const lastContactMoment = moment.tz(
                  radar.lastContact,
                  "YYYY-MM-DD HH:mm:ss",
                  "UTC"
                );
                const radarStatus =
                  getStatusFromLastContactTime(lastContactMoment);

                return {
                  id: radar.id,
                  letter: String(index + 1),
                  nameDatabase: radar.name,
                  nameSite: radar.siteName,
                  codeSite: radar.siteCode,
                  position: {
                    longitude: +radar.siteLongitude,
                    latitude: +radar.siteLatitude,
                  },
                  displayName: radar.displayName,
                  status: { wea: +radar.numberOfWindmills, radar: radarStatus },
                };
              }
            );
            setListMr1({ loaded: true, error: "", radars: newRadarList });
          } else {
            setListMr1({ loaded: true, error: "", radars: [] });
          }
          if (data.databases.ac1 && data.databases.ac1.length !== 0) {
            const newRadarList: Ac1Internal[] = data.databases.ac1.map(
              (ac1: Ac1, index: number) => {
                const lastContactMoment = moment.tz(
                  ac1.lastContact,
                  "YYYY-MM-DD HH:mm:ss",
                  "UTC"
                );
                const ac1Status =
                  getStatusFromLastContactTime(lastContactMoment);

                return {
                  id: ac1.id,
                  letter: String(index + 1),
                  nameDatabase: ac1.name,
                  nameSite: ac1.siteName,
                  position: {
                    longitude: +ac1.siteLongitude,
                    latitude: +ac1.siteLatitude,
                  },
                  displayName: ac1.displayName,
                  status: ac1Status,
                };
              }
            );
            setListAc1({
              loaded: true,
              error: "",
              aeroecologycams: newRadarList,
            });
          } else {
            setListAc1({ loaded: true, error: "", aeroecologycams: [] });
          }
        })
        .catch((error) => {
          setListMr1({ loaded: true, error: error.message, radars: [] });
          setListAc1({
            loaded: true,
            error: error.message,
            aeroecologycams: [],
          });
        });
    }
  }, [user, onEnterPage]);

  let radarCounter = 0;
  let ac1Counter = 0;

  const renderCardContent = (): ReactNode => {
    if (listMr1.loaded === false || listAc1.loaded === false) {
      return (
        <Box className={classes.loadingContainer}>
          <CircularProgress />
        </Box>
      );
    }
    if (listMr1.error !== "") {
      return (
        <Typography variant="body2" color="error">
          {listMr1.error}
        </Typography>
      );
    }
    if (listAc1.error !== "") {
      return (
        <Typography variant="body2" color="error">
          {listAc1.error}
        </Typography>
      );
    }
    if (listAc1.aeroecologycams.length === 0) {
      if (listMr1.radars.length === 0) {
        return (
          <Typography variant="body2">{t("home.noRadarFound")}</Typography>
        );
      }
      if (listMr1.radars.length === 1) {
        return (
          <Navigate
            to={to(AppRoutesPath.radarLive, { ...emptyRouteParameters, radarid: listMr1.radars[0].id })}
          />
        );
      }
    }

    let markers = listMr1.radars.map((listItem) => ({
      letter: listItem.letter + ": " + listItem.displayName,
      position: listItem.position,
      type: MarkerTypes.Mr1,
    }));
    markers = markers.concat(
      listAc1.aeroecologycams.map((listItem) => ({
        letter: listItem.letter + ": " + listItem.displayName,
        position: listItem.position,
        type: MarkerTypes.Ac1,
      }))
    );

    return (
      <>
        <Map markers={markers} />
        <Grid container direction="column" spacing={2}>
          <Grid item className={classes.products} style={{ flex: 1 }}>
            <Card>
              <CardContent>
                <Typography variant="overline">{t("home.mr1Title")}</Typography>
                {listMr1.radars.length !== 0 ? (
                  <>
                    <Grid container spacing={3}>
                      <Grid item className={classes.listHeaderButton}>
                        <Button onClick={handleopenMr1EntriesClick}>
                          {openMr1Entries ? <ExpandLess /> : <ExpandMore />}
                        </Button>
                      </Grid>
                      <Grid item style={{ flex: 1 }} />
                      {openMr1Entries ? (
                        <>
                          <Grid item className={classes.listHeaderStatus}>
                            <Typography variant="caption">WEA</Typography>
                          </Grid>
                          <Grid item className={classes.listHeaderStatus}>
                            <Typography variant="caption">Radar</Typography>
                          </Grid>
                          <Grid
                            item
                            className={classes.listActionsGridItemButton}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Collapse in={openMr1Entries} timeout="auto" unmountOnExit>
                      <List>
                        {listMr1.radars.map((radar) => {
                          radarCounter++;
                          return (
                            <>
                              <ListItem key={radar.id}>
                                <ListItemAvatar>
                                  <Avatar>{radar.letter}</Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={radar.displayName} />
                                <ListItemSecondaryAction>
                                  <Grid container spacing={3}>
                                    <Grid
                                      item
                                      className={
                                        classes.listActionsGridItemStatus
                                      }
                                    >
                                      {radar.status.wea > 0 ? (
                                        <CheckIcon
                                          htmlColor={theme.palette.success.main}
                                        />
                                      ) : (
                                        <RemoveIcon
                                          htmlColor={theme.palette.grey[500]}
                                        />
                                      )}
                                    </Grid>
                                    <Grid
                                      item
                                      className={
                                        classes.listActionsGridItemStatus
                                      }
                                    >
                                      {radar.status.radar === 0 ? (
                                        <CheckIcon
                                          htmlColor={theme.palette.success.main}
                                        />
                                      ) : (
                                        <ErrorIcon
                                          htmlColor={theme.palette.error.main}
                                        />
                                      )}
                                    </Grid>
                                    <Grid
                                      item
                                      className={
                                        classes.listActionsGridItemButton
                                      }
                                    >
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        component={Link}
                                        to={to(AppRoutesPath.radarLive, {
                                          ...emptyRouteParameters,
                                          radarid: radar.id,
                                        })}
                                      >
                                        Detail
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </ListItemSecondaryAction>
                              </ListItem>
                              {radarCounter % 4 === 0 &&
                                listMr1.radars.length !== radarCounter ? (
                                <Divider />
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                      </List>
                    </Collapse>
                  </>
                ) : (
                  <Typography variant="body2">
                    {t("home.noRadarFound")}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item className={classes.products} style={{ flex: 1 }}>
            <Card>
              <CardContent>
                <Typography variant="overline">{t("home.ac1Title")}</Typography>
                {listAc1.aeroecologycams.length !== 0 ? (
                  <>
                    <Grid container spacing={3}>
                      <Grid item className={classes.listHeaderButton}>
                        <Button onClick={handleopenAc1EntriesClick}>
                          {openAc1Entries ? <ExpandLess /> : <ExpandMore />}
                        </Button>
                      </Grid>
                      <Grid item style={{ flex: 1 }} />
                      {openAc1Entries ? (
                        <>
                          <Grid item className={classes.listHeaderStatus}>
                            <Typography variant="caption">Camera </Typography>
                          </Grid>
                          <Grid item className={classes.listHeaderButton} />
                        </>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Collapse in={openAc1Entries} timeout="auto" unmountOnExit>
                      <List>
                        {listAc1.aeroecologycams.map((ac1) => {
                          ac1Counter++;
                          return (
                            <>
                              <ListItem key={ac1.id}>
                                <ListItemAvatar>
                                  <Avatar>{ac1.letter}</Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={ac1.displayName} />
                                <ListItemSecondaryAction>
                                  <Grid container spacing={3}>
                                    <Grid
                                      item
                                      className={
                                        classes.listActionsGridItemStatus
                                      }
                                    >
                                      {ac1.status === 0 ? (
                                        <CheckIcon
                                          htmlColor={theme.palette.success.main}
                                        />
                                      ) : (
                                        <ErrorIcon
                                          htmlColor={theme.palette.error.main}
                                        />
                                      )}
                                    </Grid>
                                    <Grid
                                      item
                                      className={
                                        classes.listActionsGridItemButton
                                      }
                                    >
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        component={Link}
                                        to={to(AppRoutesPath.aeroecologycamLive, {
                                          ...emptyRouteParameters,
                                          ac1id: ac1.id,
                                        })}
                                      >
                                        Detail
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </ListItemSecondaryAction>
                              </ListItem>
                              {ac1Counter % 4 === 0 &&
                                listAc1.aeroecologycams.length !== ac1Counter ? (
                                <Divider />
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                      </List>
                    </Collapse>
                  </>
                ) : (
                  <Typography variant="body2">
                    {t("home.noAc1Found")}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Card>
      <CardContent>{renderCardContent()}</CardContent>
    </Card>
  );
};
