import React, { useState, useMemo, useEffect } from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { RadarImageSlider } from "./RadarImageSlider";
import { PdpReader } from "./PdpReader";
import { Trans } from "react-i18next";
import moment from "moment";

export interface Image {
    name: string;
    dateTime: string;
    isFiltered: boolean;
}

interface RadarImageProps {
    radarName: string;
    siteCode: string;
    getPdpViaFtp: boolean;
    images: Image[];
    showThumbs: number;
    height?: number;
    selectedDate: string | undefined;
}
const useStyles = makeStyles({
    root: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    currentImage: {
        flex: 1,
        "& img": {
            width: "100%"
        }
    },
    thumbs: {
        flex: 0,
        position: "relative",
        width: "100%"
    },
    noimage: {
        textAlign: "center"
    }
});

export const RadarImage = (props: RadarImageProps): React.ReactElement => {
    const { images } = props;
    const [currentImage, setCurrentImage] = useState<string | undefined>(undefined);
    const [currentPdpImage, setCurrentPdpImage] = useState<string>("");
    const classes = useStyles();

    const [keyPressed, setKeyPressed] = useState<{ left: boolean; right: boolean }>({ left: false, right: false });

    const onChangeImage = (name: string): void => {
        setCurrentImage(name);
        setCurrentPdpImage(name.replace("_filtered.png", ".pdp"));
    };

    useEffect(() => {
        const downHandler = (ev: KeyboardEvent): void => {
            if (ev.key === "ArrowRight" && keyPressed.right === false) {
                setKeyPressed({ left: false, right: true });
            }
            if (ev.key === "ArrowLeft" && keyPressed.left === false) {
                setKeyPressed({ left: true, right: false });
            }
        };
        const upHandler = (ev: KeyboardEvent): void => {
            setKeyPressed({ left: false, right: false });
        };
        window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);

        return (): void => {
            window.removeEventListener("keydown", downHandler);
            window.removeEventListener("keyup", upHandler);
        };
    }, [setKeyPressed, keyPressed]);

    useEffect(() => {
        if (props.selectedDate) {
            const selectedImageName =
                props.siteCode +
                "-" +
                moment(props.selectedDate, "DD.MM.YYYY HH:mm:ss").format("YYYY-MM-DD_HHmm") +
                "_filtered.png";

            onChangeImage(selectedImageName);
        }
    }, [props.selectedDate, props.siteCode]);

    useMemo(() => {
        images.length !== 0 && onChangeImage(images[images.length - 1].name);
    }, [images]);

    useEffect(() => {
        if (keyPressed.right) {
            const currentImageIndex = images.findIndex((img) => img.name === currentImage);
            const nextImage = images[currentImageIndex + 1];
            if (nextImage) {
                setKeyPressed({ left: false, right: false });
                onChangeImage(nextImage.name);
            }
        }
        if (keyPressed.left) {
            const currentImageIndex = images.findIndex((img) => img.name === currentImage);
            const prevImage = images[currentImageIndex - 1];
            if (prevImage) {
                setKeyPressed({ left: false, right: false });
                onChangeImage(prevImage.name);
            }
        }
    }, [keyPressed.right, keyPressed.left, images, currentImage]);

    return (
        <Grid container direction="column" className={classes.root} spacing={2}>
            {props.images.length === 0 || currentImage === undefined ? (
                <Grid item className={classes.noimage}>
                    <Typography variant="body2">
                        <Trans>error.noData</Trans>
                    </Typography>
                </Grid>
            ) : (
                <>
                    <Grid item className={classes.thumbs} style={{ height: 120 + 40 }}>
                        <RadarImageSlider
                            radarImages={props.images}
                            showThumbs={props.showThumbs}
                            getPdpViaFtp={props.getPdpViaFtp}
                            radarName={props.radarName}
                            onClickImage={onChangeImage}
                            currentImage={currentImage}
                        />
                    </Grid>
                    <Grid item className={classes.currentImage}>
                        <PdpReader
                            radarName={props.radarName}
                            fileName={currentPdpImage}
                            getPdpViaFtp={props.getPdpViaFtp}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
};
