import React, { useState, useEffect, useContext, useCallback } from "react";

import { AppContext } from "../../store/context";
import {
    Card,
    CardContent,
    Grid,
    List,
    ListSubheader,
    ListItem,
    ListItemText,
    CircularProgress,
    makeStyles,
    useTheme,
    Theme,
    Typography
} from "@material-ui/core";
import { fetchPost } from "../../utils/fetch";
import { Trans } from "react-i18next";
import { Ac1 } from "../ProductAc1";
import { Ac1TrackAnimation } from "./Ac1TrackAnimation";
import moment from "moment-timezone";
import CheckIcon from "@mui/icons-material/Check";
import RemoveIcon from "@mui/icons-material/Remove";
import { useOutletContext } from "react-router";


interface Track {
    id: number;
    startTime: string;
    endTime: string;
    observation: string;
    humanClassification: string;
    comment: string;
    hasMatch: boolean;
}

interface TracksData {
    tracks: Track[];
}

/*
interface RegionOfInterests {
    height: number;
    left: number;
    top: number;
    width: number;
    area: number;
    image: string;
}
*/


const useStyles = makeStyles<Theme>((theme: Theme) => ({
    list: {
        width: "100%",
        backgroundColor: theme.palette.secondary.light,
        position: "relative"
    },
    itemPair: {
        backgroundColor: theme.palette.secondary.main
    },
    itemImpair: {
        backgroundColor: theme.palette.secondary.light
    },
    itemSelected: {
        backgroundColor: theme.palette.secondary.dark
    },
    listActionsGridItemStatus: {
        display: "flex",
        alignItems: "left",
        width: 100,
        justifyContent: "left"
    },
    listHeaderItem: {},
    listItem: {}
}));

export const Ac1Live = (): React.ReactElement => {
    const classes = useStyles();
    const { state } = useContext(AppContext);
    const theme = useTheme();
    const ac1 = useOutletContext<Ac1>();

    const [selectedTrackId, setselectedTrackId] = React.useState(0);

    const [tracksData, settracksData] = useState<undefined | TracksData>(undefined);

    useEffect(() => {
        const requestTrackData = (): void => {
            if (state.user) {
                fetchPost("get_track_data_ac1.php", {
                    params: {
                        token: state.user.token,
                        databaseName: ac1.name,
                        dateFrom: moment(moment().utc()).add(-30, "days").format("YYYY-MM-DD HH:mm:ss"),
                        dateTo: moment().utc().format("YYYY-MM-DD HH:mm:ss")
                    }
                })
                    .then((response) => response.json())
                    .then((data) => {
                        settracksData(data);
                    });
            }
        };

        requestTrackData();

        const id = setInterval(() => {
            requestTrackData();
        }, 60000);

        return (): void => clearInterval(id);
    }, [ac1.name, state.user]);

    const handleListItemClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>, trackId: number) => {
            console.log("Selected track: " + trackId);
            setselectedTrackId(trackId);
        },
        [setselectedTrackId]
    );

    if (!tracksData) {
        return <CircularProgress></CircularProgress>;
    }

    return (
        <>
            <Grid item xs={6}>
                <Card>
                    <CardContent>
                        <List>
                            <ListSubheader>
                                <Grid container direction="column">
                                    <Grid container spacing={10}>
                                        <Grid item style={{ flex: 1 }} xs={2} />
                                        <Grid item className={classes.listHeaderItem} xs={2}>
                                            <Typography variant="caption">
                                                <Trans>ac1.startTime</Trans>
                                            </Typography>
                                        </Grid>
                                        <Grid item className={classes.listHeaderItem} xs={2}>
                                            <Typography variant="caption">
                                                <Trans>ac1.stopTime</Trans>
                                            </Typography>
                                        </Grid>
                                        <Grid item className={classes.listHeaderItem} xs={2}>
                                            <Typography variant="caption">
                                                <Trans>ac1.observation</Trans>
                                            </Typography>
                                        </Grid>
                                        <Grid item className={classes.listHeaderItem} xs={2}>
                                            <Typography variant="caption">
                                                <Trans>ac1.classification</Trans>
                                            </Typography>
                                        </Grid>
                                        <Grid item className={classes.listHeaderItem} xs={2}>
                                            <Typography variant="caption">
                                                <Trans>ac1.hasMatch</Trans>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </ListSubheader>
                            <ListItem button selected={false} key={"Track list item " + 0}></ListItem>
                            {tracksData.tracks ?
                                tracksData.tracks.map((track: Track, index) => {
                                    let itemClass = index % 2 === 0 ? classes.itemPair : classes.itemImpair;
                                    if (selectedTrackId === track.id) {
                                        itemClass = classes.itemSelected;
                                        console.log("Selected: " + track.id);
                                    }
                                    return (
                                        <ListItem
                                            button
                                            onClick={(event): void => handleListItemClick(event, track.id)}
                                            key={"Track list item " + track.id}
                                            className={itemClass}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Grid container direction="column">
                                                        <Grid container spacing={10}>
                                                            <Grid item className={classes.listItem} xs={2}>
                                                                <Typography variant="body2">
                                                                    {"Track " + track.id}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item className={classes.listItem} xs={2}>
                                                                <Typography variant="body2">{track.startTime}</Typography>
                                                            </Grid>
                                                            <Grid item className={classes.listItem} xs={2}>
                                                                <Typography variant="body2">{track.endTime}</Typography>
                                                            </Grid>
                                                            <Grid item className={classes.listItem} xs={2}>
                                                                <Typography variant="body2">{track.observation}</Typography>
                                                            </Grid>
                                                            <Grid item className={classes.listItem} xs={2}>
                                                                <Typography variant="body2">
                                                                    {track.humanClassification}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item className={classes.listItem} xs={2}>
                                                                {track.hasMatch ? (
                                                                    <CheckIcon htmlColor={theme.palette.success.main} />
                                                                ) : (
                                                                    <RemoveIcon htmlColor={theme.palette.grey[500]} />
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            />
                                        </ListItem>
                                    );
                                }) :
                                <Typography variant="body2">
                                    <Trans>ac1.noTrack</Trans>
                                </Typography>
                            }
                        </List>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={4}>
                <Ac1TrackAnimation ac1={ac1} trackId={selectedTrackId} />
            </Grid>
        </>
    );
};
