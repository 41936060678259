import React, { useState, useEffect, useContext } from "react";
import { makeStyles, Card, CardContent, Theme, Typography, Box, CircularProgress } from "@material-ui/core";

import { Trans, useTranslation } from "react-i18next";
import { fetchPost } from "../../utils/fetch";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { formatNumber } from "../../utils/format";
import { Mr1 } from "../../views/ProductMr1";
import { AppContext } from "../../store/context";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    header: {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.common.white
    },
    item: {
        minWidth: 650,
        minHeight: 373,
        marginBottom: theme.spacing(2)
    },
    circularProgress: {
        position: "absolute",
        top: "50%",
        left: "50%"
    }
}));

interface MtrValues {
    class: string;
    mtrLastMinutes: number;
    mtrLastHour: number;
    mtrLastDay: number;
}

interface MtrTableProps {
    radar: Mr1;
}

export const MtrTable = (props: MtrTableProps): React.ReactElement => {
    const classes = useStyles();
    const { state } = useContext(AppContext);
    const { t } = useTranslation();

    const [mtrData, setmtrData] = useState<undefined | MtrValues[]>(undefined);

    useEffect(() => {
        const requestMtrData = (classNames: string[]): void => {
            if (state.user) {
                fetchPost("get_mtr_table.php", {
                    params: {
                        token: state.user.token,
                        databaseName: props.radar.name,
                        siteId: props.radar?.siteId,
                        minAltitude: "0",
                        maxAltitude: "1000",
                        classNames: JSON.stringify(classNames)
                    }
                })
                    .then((response) => response.json())
                    .then((data) => {
                        setmtrData(data.mtr);
                    });
            }
        };
        const requestClassNames = (): void => {
            if (props.radar && state.user) {
                fetchPost("get_monitor_classes.php", {
                    params: {
                        token: state.user.token,
                        databaseName: props.radar.name,
                        useNewClassifier: "true"
                    }
                })
                    .then((response) => response.json())
                    .then((data) => {
                        requestMtrData(data.classNames);
                    });
            }
        };
        requestClassNames();

        const id = setInterval(() => {
            requestClassNames();
        }, 10000);

        return (): void => clearInterval(id);
    }, [state.user, props.radar, setmtrData]);

    const sortedMtrData = mtrData?.sort((mtrValueA, mtrValueB) =>
        t("classifier." + mtrValueA.class).toLowerCase() < t("classifier." + mtrValueB.class).toLowerCase() ? -1 : 1
    );

    return (
        <Card className={classes.item}>
            <CardContent>
                <Typography variant="overline">
                    <Trans>mtr.tableTitle</Trans>
                </Typography>
                {sortedMtrData ? (
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead className={classes.header}>
                                <TableRow>
                                    <TableCell sortDirection="asc">
                                        <Trans>mtr.class</Trans>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Trans>mtr.mtrLastMinutes</Trans>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Trans>mtr.mtrLastHour</Trans>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Trans>mtr.mtrLastDay</Trans>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedMtrData.map((value: MtrValues) => {
                                    return (
                                        <TableRow key={value.class}>
                                            <TableCell component="th" scope="row" sortDirection="asc">
                                                {t("classifier." + value.class)}
                                            </TableCell>
                                            <TableCell align="right">{formatNumber(value.mtrLastMinutes)}</TableCell>
                                            <TableCell align="right">{formatNumber(value.mtrLastHour)}</TableCell>
                                            <TableCell align="right">{formatNumber(value.mtrLastDay)}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Box className={classes.circularProgress}>
                        <CircularProgress></CircularProgress>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};
