import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { IconButton, Icon, Select, FormControl, Theme, makeStyles } from "@material-ui/core";
import LanguageIcon from "@mui/icons-material/Language";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    languageSelect: {
        padding: -20,
        marginTop: -10,
        marginBottom: -10
    },
    selected: {
        height: "100%",
        margin: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transform: "transformY(-20%)"
    }
}));

export const LanguagePicker = (): React.ReactElement => {
    const { i18n } = useTranslation();
    const classes = useStyles();
    const [showSelect, setshowSelect] = useState(false);

    const handleLanguageChange = (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ): void => {
        if ( i18n )
        {
            i18n.changeLanguage(event.currentTarget.value as string);
        }
    };

    return (
        <>
            <IconButton
                edge="end"
                onClick={(): void => {
                    setshowSelect(!showSelect);
                }}
            >
                <Icon>
                    <LanguageIcon></LanguageIcon>
                </Icon>
                {showSelect ? (
                    <FormControl className={classes.languageSelect} variant="outlined">
                        <Select native defaultValue={i18n.language} id="languageSelect" onChange={handleLanguageChange}>
                            {Object.keys(i18n.services.resourceStore.data).map((language: string) => {
                                return <option label={language} key={language} value={language} />;
                            })}
                        </Select>
                    </FormControl>
                ) : (
                    <div className={classes.selected}>{i18n.resolvedLanguage?.substring(0, 2)}</div>
                )}
            </IconButton>
        </>
    );
};
