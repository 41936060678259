import React, { useEffect, useContext, useState } from "react";
import { Outlet, Route, Routes, useParams } from "react-router";
import { emptyRouteParameters, AppRoutesPath, to } from "../utils/routes";
import { Ac1Live } from "./aeroecologycam/Ac1Live";
import { Ac1SystemStatus } from "./aeroecologycam/Ac1SystemStatus";
import { PageSettings } from "./Page";
import { Grid } from "@material-ui/core";
import { Sidebar } from "../components/sidebar/Sidebar";
import { AppContext, UserRole } from "../store/context";
import { fetchPost } from "../utils/fetch";
import { useTranslation } from "react-i18next";
import { Moment } from "moment-timezone";

export interface Ac1Event {
    id: string;
    active: boolean;
    timestamp: Moment;
    stayActive: boolean;
    handledByUser: boolean;
    name: string;
    eventDescription: string;
    severity: number;
}

export interface Ac1 {
    id: number;
    name: string;
    active: boolean;
    getPdpViaFtp: boolean;
    comment: string;
    type: string;
    siteId: string;
    siteName: string;
    description: string;
    siteLongitude: number;
    siteLatitude: number;
    lastContact: string;
    displayName: string;
    lastDataTimestamp: string;
    events: Ac1Event[];
}

interface ProductAc1Props {
    onEnterPage(settings: PageSettings): void;
}

export const ProductAc1 = (props: ProductAc1Props): React.ReactElement => {
    const { ac1id } = useParams<{ ac1id: string }>();
    const { t } = useTranslation();
    const { onEnterPage } = props;

    const { state } = useContext(AppContext);
    const [ac1, setAc1] = useState<Ac1 | undefined>(undefined);

    useEffect(() => {
        if (state.user) {
            fetchPost("get_all_databases.php", { params: { token: state.user.token } })
                .then((response) => response.json())
                .then((data) => {
                    if (data.databases.ac1 && data.databases.ac1.length !== 0) {
                        data.databases.ac1.forEach((ac1: Ac1, index: number) => {
                            if (ac1.id.toString() === ac1id) {
                                setAc1({ ...ac1 });

                                const tabs = [{ label: "Live", to: to(AppRoutesPath.aeroecologycamLive, { ...emptyRouteParameters, ac1id: ac1id }) }];
                                if (
                                    state.user?.role === UserRole.Analyst ||
                                    state.user?.role === UserRole.AnalystWithDataPermission ||
                                    state.user?.isAdmin
                                ) {
                                    tabs.push({
                                        label: t("system.title"),
                                        to: to(AppRoutesPath.aeroecologycamSystem, { ...emptyRouteParameters, ac1id: ac1id })
                                    });
                                }

                                onEnterPage({
                                    title: ac1.siteName,
                                    backRoute: AppRoutesPath.root,
                                    tabs: {
                                        tabs: tabs
                                    }
                                });
                            }
                        });
                    }
                });
        }
    }, [ac1id, setAc1, onEnterPage, state.user, t]);

    const showAnalyticStuff =
        state.user?.role === UserRole.Analyst ||
        state.user?.role === UserRole.AnalystWithDataPermission ||
        state.user?.isAdmin;

    if (!ac1) {
        return <Grid container>Loading</Grid>;
    } else {
        return (
            <Grid container spacing={2}>
                <Outlet context={ac1}/>
                <Grid item xs={12} md={2}>
                    <Sidebar ac1={ac1} />
                </Grid>
            </Grid>
        );
    }
};
