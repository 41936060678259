import React, { useRef, useCallback, useEffect } from "react";

import { Chart } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";

Chart.register(ChartDataLabels);


interface ReportDataPieProps {
    id: number;
    values: number[];
    colors: string[];
    labels: string[];
    onChangeDataImage: (newImageData: string, id: number) => void;
}

export const ReportDataPie = React.memo(
    (props: ReportDataPieProps): React.ReactElement => {
        const chartRef = useRef<ChartJSOrUndefined<"doughnut", number[], string>>(null);

        const getImage = useCallback((): string => {
            if (chartRef.current) {
                const base64Image = chartRef.current.toBase64Image();
                return base64Image;
            }
            return "";
        }, [chartRef]);

        useEffect(() => {
            const id = setInterval(() => {
                const image = getImage();
                if ("" !== image) {
                    props.onChangeDataImage(getImage(), props.id);
                    clearInterval(id);
                }
            }, 500);

            return (): void => clearInterval(id);
        }, [props.id, chartRef, getImage, props]);

        if (props.values.length === 0 || props.labels.length === 0 || props.values.length !== props.labels.length) {
            return <div />;
        }

        const data = {
            datasets: [
                {
                    data: props.values,
                    backgroundColor: props.colors
                }
            ],

            labels: props.labels
        };

        const options = {

            animation: {
                animateRotate: false
            },
            plugins: {
                legend: {
                    labels: {
                        font :{
                            size: 60
                        },
                    }
                },
                datalabels: {
                    display: true,
                    font: {
                        size: 60
                    },
                    color: "white",
                    formatter: function (value: number): string {
                        return Math.round(value) + "%";
                    }
                }
            }
        };

        return <Doughnut data={data} options={options} ref={chartRef} />;
    }
);
