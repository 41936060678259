import React, { useContext, useState, useCallback, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Grid,
  CardContent,
  Card,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { AppContext, getUserRole } from "../store/context";
import { UserTypes } from "../store/reducer";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import { AppRoutesPath } from "../utils/routes";
import { Trans, useTranslation } from "react-i18next";
import { fetchPost } from "../utils/fetch";
import { Config } from "../config/app";

interface FormData {
  username: { value: string; error: boolean };
  password: { value: string; error: boolean };
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    marginTop: 150,
  },
  logo: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  form: {
    width: "100%",
  },
  inputContainer: {
    marginBottom: theme.spacing(2),
  },
}));

export const Login = (): React.ReactElement => {
  const classes = useStyles();
  const { dispatch } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form, setForm] = useState<FormData>({
    username: { value: "", error: false },
    password: { value: "", error: false },
  });
  const [error, setError] = useState<string>("");

  type LoginParams = {
    username: string | undefined;
    password: string | undefined;
  };
  const loginParams = useParams<LoginParams>();

  const doLogin = useCallback(
    (username: string, password: string) => {
      fetchPost("login.php", {
        params: { username: username, password: password },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.token) {
            const payload = {
              name: data.username,
              token: data.token,
              role: getUserRole(data.role),
              isAdmin: data.isAdmin,
            };
            dispatch({
              type: UserTypes.Add,
              payload: payload,
            });
            navigate(AppRoutesPath.root);
          } else {
            setError(data.error || t("login.error_generic"));
          }
        })
        .catch((error) => {
          setError(error.message);
        });
    },
    [setError, dispatch, t]
  );

  useEffect(() => {
    if (loginParams.username && loginParams.password) {
      console.log("automatic login");
      doLogin(loginParams.username, loginParams.password);
    }
  }, [loginParams, doLogin]);

  const onChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const fieldKey = event.currentTarget.dataset.field;
      if (fieldKey) {
        setForm({
          ...form,
          [fieldKey]: { value: event.target.value, error: false },
        });
      }
    },
    [setForm, form]
  );
  const onSubmitForm = useCallback(
    (event: React.FormEvent) => {
      setError("");
      event.preventDefault();

      if (form.username.value === "") {
        setError(t("error.field_missing", { field: t("login.username") }));
        setForm({ ...form, username: { ...form.username, error: true } });
        return;
      }
      if (form.password.value === "") {
        setError(t("error.field_missing", { field: t("login.password") }));
        setForm({ ...form, password: { ...form.password, error: true } });
        return;
      }

      doLogin(form.username.value, form.password.value);
    },
    [form, setError, doLogin, t]
  );
  return (
    <Container maxWidth="xs" className={classes.root}>
      <Card>
        <CardContent>
          <Grid container>
            <form onSubmit={onSubmitForm} className={classes.form}>
              <Grid item xs={12}>
                <img
                  src="images/swiss-birdradar_logo.png"
                  className={classes.logo}
                  alt={Config.appname}
                />
              </Grid>
              <Grid item xs={12} className={classes.inputContainer}>
                <TextField
                  label={t("login.username")}
                  variant="filled"
                  inputProps={{ "data-field": "username" }}
                  value={form.username.value}
                  onChange={onChangeInput}
                  fullWidth
                  error={form.username.error}
                />
              </Grid>
              <Grid item xs={12} className={classes.inputContainer}>
                <TextField
                  label={t("login.password")}
                  inputProps={{ "data-field": "password" }}
                  type="password"
                  value={form.password.value}
                  variant="filled"
                  onChange={onChangeInput}
                  fullWidth
                  error={form.password.error}
                />
              </Grid>
              <Grid item xs={12} className={classes.inputContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                >
                  <Trans>login.title</Trans>
                </Button>
              </Grid>
            </form>
          </Grid>
          {error && (
            <Grid container>
              <Grid item>
                <Typography variant="body1" color="error">
                  {error}
                </Typography>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Container>
  );
};
