import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Grid, Box } from "@material-ui/core";

import { Trans } from "react-i18next";
import moment from "moment";

interface CardDateProps {
    class: string;
}

export const CardDate = (props: CardDateProps): React.ReactElement => {
    const [dateNow, setDateNow] = useState(moment());
    const [dateNowUtc, setDateNowUtc] = useState(moment());

    useEffect(() => {
        const id = setInterval(() => {
            setDateNow(moment());
            setDateNowUtc(moment().utc());
        }, 1000);
        return (): void => clearInterval(id);
    }, []);

    return (
        <Card className={props.class}>
            <CardContent>
                <Typography variant="overline">
                    <Trans>sidebar.cardDate.title</Trans>
                </Typography>

                <Grid justifyContent="space-between" container>
                    <Grid item>
                        <Typography variant="body1">{dateNow.format("DD.MM.YYYY")}</Typography>
                    </Grid>

                    <Grid item>
                        <Typography variant="body1">{dateNow.format("HH:mm:ss")}</Typography>
                    </Grid>
                </Grid>

                <Box marginTop={2}>
                    <Typography variant="caption" align="justify">
                        UTC:
                    </Typography>
                </Box>
                <Grid justifyContent="space-between" container>
                    <Grid item>
                        <Typography variant="body1">{dateNowUtc.format("DD.MM.YYYY")}</Typography>
                    </Grid>

                    <Grid item>
                        <Typography variant="body1">{dateNowUtc.format("HH:mm:ss")}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
