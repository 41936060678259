import React, { useContext, useCallback } from "react";
import { AppContext } from "./store/context";
import { Page } from "./views/Page";
import { Content } from "./components/Content";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Login } from "./views/Login";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import { UITypes } from "./store/reducer";
import { Trans } from "react-i18next";
import { AppRoutesPath } from "./utils/routes";
import { Logout } from "@mui/icons-material";

export const AppRoutes = (): React.ReactElement => {
    const { state, dispatch } = useContext(AppContext);
    const loggedIn = state.user ? true : false;
    const onDialogClose = useCallback(() => {
        dispatch({
            type: UITypes.HideDialog,
            payload: {}
        });
    }, [dispatch]);
    return (
        <Content background={state.user === undefined ? "images/BackgroundImage.jpg" : undefined}>
            <BrowserRouter>
                {loggedIn ? (
                    <Page />
                ) : (
                    <Routes>
                        <Route path={AppRoutesPath.login} key={AppRoutesPath.login} element={<Login />} />
                        <Route path={AppRoutesPath.loginWithParameters} key={AppRoutesPath.loginWithParameters} element={<Login />} />
                        <Route path={AppRoutesPath.root} key={AppRoutesPath.login + "_fallthrough"} element={<Navigate to={AppRoutesPath.login} /> } />
                        <Route path={AppRoutesPath.logout} key={AppRoutesPath.logout} element={<Logout />} />
                    </Routes>
                )}
                <Dialog open={state.ui.dialog !== undefined} onClose={onDialogClose}>
                    {state.ui.dialog && (
                        <>
                            <DialogTitle>{state.ui.dialog.title}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>{state.ui.dialog.message}</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={onDialogClose} color="primary" autoFocus>
                                    <Trans>label.cancel</Trans>
                                </Button>
                                {state.ui.dialog.buttons}
                            </DialogActions>
                        </>
                    )}
                </Dialog>
            </BrowserRouter>
        </Content>
    );
};
