import React from "react";
import { Tooltip, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import "./StatusLight.css";
import moment, { Moment } from "moment";

import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import StopCircleIcon from "@mui/icons-material/Stop";

export enum Status {
    Running,
    Stopped,
    NoContact,
    Error
}

export const getStatusFromLastContactTime = (lastContactTime: Moment): Status => {
    if (lastContactTime.isValid()) {
        let radarStatus = Status.Running;
        if (lastContactTime.isBefore(moment(moment().utc()).add(-1, "hours"))) {
            radarStatus = Status.NoContact;
        }
        if (lastContactTime.isBefore(moment(moment().utc()).add(-1, "days"))) {
            radarStatus = Status.Error;
        }
        return radarStatus;
    } else {
        return Status.Error;
    }
};

export const getStatusFromLastDataUpdateTime = (lastContactTime: Moment): Status => {
    if (lastContactTime.isValid()) {
        let radarStatus = Status.Running;
        if (lastContactTime.isBefore(moment(moment().utc()).add(-1, "hours"))) {
            radarStatus = Status.NoContact;
        }
        if (lastContactTime.isBefore(moment(moment().utc()).add(-1, "days"))) {
            radarStatus = Status.Error;
        }
        return radarStatus;
    } else {
        return Status.Error;
    }
};

interface StatusLightProps {
    status: Status;
}

export const StatusLight = (props: StatusLightProps): React.ReactElement => {
    const { t } = useTranslation();

    let color = "red";
    let text = t("statusLight.genericError");

    switch (props.status) {
        case Status.Running:
            color = "green";
            text = t("statusLight.running");
            break;
        case Status.Stopped:
            color = "orange";
            text = t("statusLight.stopped");
            break;
        case Status.NoContact:
            color = "orange";
            text = t("statusLight.noContact");
            break;
        case Status.Error:
            color = "red";
            text = t("statusLight.genericError");
            break;
    }

    return (
        <Tooltip title={text} placement="top">
            <div className="StatusLight" style={{ background: color }} />
        </Tooltip>
    );
};

interface StatusIconProps {
    status: Status;
    tooltipText: string;
}

export const StatusIcon = (props: StatusIconProps): React.ReactElement => {
    const theme = useTheme();

    const getIcon = (): React.ReactElement => {
        switch (props.status) {
            case Status.Running:
                return <CheckIcon htmlColor={theme.palette.success.main} />;
            case Status.Stopped:
                return <StopCircleIcon htmlColor={theme.palette.error.main} />;
            case Status.NoContact:
                return <WarningIcon htmlColor={theme.palette.warning.main} />;
            case Status.Error:
                return <ErrorIcon htmlColor={theme.palette.error.main} />;
        }
    };

    return (
        <Tooltip title={props.tooltipText} placement="top">
            {getIcon()}
        </Tooltip>
    );
};
