import React, { useState, useEffect, useContext } from "react";
import { Card, CardContent, Grid, Typography, Box, Divider, CircularProgress, makeStyles } from "@material-ui/core";

import { Trans } from "react-i18next";
import moment from "moment-timezone";
import { fetchPost } from "../../utils/fetch";
import { AppContext } from "../../store/context";

import { StatusLight, getStatusFromLastContactTime } from "../ui/StatusLight";
import { Mr1 } from "../../views/ProductMr1";

interface StatusData {
    lastContactTimeStamp: string;
    siteName: string;
    mtr: {
        mtrLastMinutes: number;
        mtrLastHour: number;
        mtrLastDay: number;
        mtrLastWeek: number;
    };
}

interface CardRadarProps {
    class: string;
    radar: Mr1;
}

const useStyles = makeStyles({
    radarName: {
        display: "flex"
    }
});

export const CardRadar = (props: CardRadarProps): React.ReactElement => {
    const [statusData, setstatusData] = useState<undefined | StatusData>(undefined);
    const classes = useStyles();
    const { state } = useContext(AppContext);

    useEffect(() => {
        const requestStatusData = (): void => {
            if (state.user) {
                fetchPost("get_status_data_mr1.php", {
                    params: {
                        token: state.user.token,
                        databaseName: props.radar.name,
                        siteId: props.radar.siteId
                    }
                })
                    .then((response) => response.json())
                    .then((data) => {
                        setstatusData(data);
                    });
            }
        };
        requestStatusData();

        const id = setInterval(() => {
            requestStatusData();
        }, 10000);

        return (): void => clearInterval(id);
    }, [state.user, setstatusData, props.radar]);

    if (statusData === undefined) {
        return (
            <Card className={props.class}>
                <CardContent style={{ textAlign: "center" }}>
                    <CircularProgress></CircularProgress>
                </CardContent>
            </Card>
        );
    }

    const lastContactMoment = moment.tz(statusData.lastContactTimeStamp, "YYYY-MM-DD HH:mm:ss", "UTC");
    const radarStatus = getStatusFromLastContactTime(lastContactMoment);
    return (
        <Card className={props.class}>
            <CardContent>
                <Typography variant="overline">
                    <Trans>sidebar.cardRadar.title</Trans>
                </Typography>

                <Grid justifyContent="space-between" container spacing={4}>
                    <Grid item className={classes.radarName}>
                        <Typography style={{ flex: 1 }} variant="h6">
                            {"MR1 " + statusData.siteName}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.radarName}>
                        <StatusLight status={radarStatus}></StatusLight>
                    </Grid>
                </Grid>

                <Box>
                    <Typography variant="caption" align="justify">
                        <Trans>sidebar.cardRadar.lastContact</Trans> (UTC):{" "}
                        {lastContactMoment.format("DD.MM.YYYY HH:mm:ss")}
                    </Typography>
                </Box>

                <Divider />

                <Box marginTop={6}>
                    <Grid justifyContent="space-between" container>
                        <Grid item>
                            <Typography variant="body1">
                                <Trans>sidebar.cardRadar.mtrLastMinutes</Trans>
                            </Typography>
                        </Grid>

                        <Grid item>
                            <div>{statusData.mtr.mtrLastMinutes.toFixed(2)}</div>
                        </Grid>
                    </Grid>

                    <Grid justifyContent="space-between" container>
                        <Grid item>
                            <Typography variant="body1">
                                <Trans>sidebar.cardRadar.mtrLastHour</Trans>
                            </Typography>
                        </Grid>

                        <Grid item>
                            <div>{statusData.mtr.mtrLastHour.toFixed(2)}</div>
                        </Grid>
                    </Grid>

                    <Grid justifyContent="space-between" container>
                        <Grid item>
                            <Typography variant="body1">
                                <Trans>sidebar.cardRadar.mtrLastDay</Trans>
                            </Typography>
                        </Grid>

                        <Grid item>
                            <div>{statusData.mtr.mtrLastDay.toFixed(2)}</div>
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
        </Card>
    );
};
