import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import { Mr1 } from "../../../views/ProductMr1";
import { useTheme } from "@material-ui/core";

import { Bar } from "react-chartjs-2";
import { ChartData, ChartOptions, Color } from "chart.js";
import { fetchPost } from "../../../utils/fetch";
import moment, { Moment } from "moment";
import { AppContext } from "../../../store/context";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";

interface MtrDayWithPerformanceData {
    days: string[];
    mtrDay: number[];
    mtrNight: number[];
    performance: number[];
}

interface ReportDataChartMtrPerDayWithPerformanceProps {
    radar: Mr1;
    dateFrom: Moment;
    dateTo: Moment;
    onChangeDataImage: (newImageData: string) => void;
}

export const ReportDataChartMtrPerDayWithPerformance = React.memo(
    (props: ReportDataChartMtrPerDayWithPerformanceProps): React.ReactElement => {
        const theme = useTheme();
        const { state } = useContext(AppContext);
        const chartRef = useRef<ChartJSOrUndefined<"bar", number[], string>>(null);

        const [isLoading, setisLoading] = useState(true);
        const [mtrData, setmtrData] = useState<undefined | MtrDayWithPerformanceData>(undefined);

        const getImage = useCallback((): string => {
            if (chartRef.current) {
                return chartRef.current.toBase64Image();
            }
            return "";
        }, [chartRef]);

        useEffect(() => {
            const requestMtrData = (): void => {
                if (state.user) {
                    fetchPost("get_mtr_average_per_day_with_performance.php", {
                        params: {
                            token: state.user.token,
                            databaseName: props.radar.name,
                            dateFrom: props.dateFrom.format("YYYY-MM-DD HH:mm:ss"),
                            dateTo: props.dateTo.format("YYYY-MM-DD HH:mm:ss")
                        }
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            setmtrData(data);
                            setisLoading(false);
                        });
                }
            };

            requestMtrData();

            const id = setInterval(() => {
                const image = getImage();
                if ("" !== image) {
                    props.onChangeDataImage(getImage());
                    clearInterval(id);
                }
            }, 1000);

            return (): void => clearInterval(id);
        }, [props.radar.name, state.user, chartRef, getImage, props]);

        if (isLoading) {
            return <div />;
        }
        if (mtrData === undefined) {
            return <div />;
        }

        const data: ChartData<'bar'> = {
            labels: mtrData.days,
            datasets: [
                {
                    label: "Performance",
                    type: "bar",
                    xAxisID: "x",
                    yAxisID: "Performance",
                    backgroundColor: 'rgba(0, 80, 0, 0.2)',
                    borderColor: "green",
                    //lineTension: 0,
                    data: mtrData.performance
                }
            ]
        };

        data.datasets.push({
            label: "Mtr Day",
            type: "bar",
            xAxisID: "x",
            yAxisID: "Mtr",
            backgroundColor: theme.palette.primary.light,
            borderColor: theme.palette.primary.light,
            data: mtrData.mtrDay
        });

        data.datasets.push({
            label: "Mtr Night",
            type: "bar",
            xAxisID: "x",
            yAxisID: "Mtr",
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            data: mtrData.mtrNight
        });

        const fontSize = 22;

        const options: ChartOptions<'bar'> = {
            elements: { point: { radius: 1 } },
            scales: {
                x:
                {
                    type: "time",
                    time: {
                        unit: "day",
                        tooltipFormat: "DD.MM.YYYY",
                        displayFormats: {
                            day: "DD.MM.YYYY"
                        }
                    },
                    ticks: {
                        maxRotation: 45,
                        padding: 0,
                        labelOffset: 0,
                        maxTicksLimit: 20,
                        font: {
                            size: fontSize
                        }
                    }
                }
                ,
                Mtr:
                {
                    title: {
                        display: true,
                        text: "Migration trafic rate [1/h]",
                        font: {
                            size: fontSize
                        }
                    },
                    type: "linear",
                    position: "left",
                    ticks: {
                        maxTicksLimit: 6,
                        font: {
                            size: fontSize
                        }
                    }
                },
                Performance:
                {
                    title: {
                        display: true,
                        text: "Performance Average [kW]",
                        font: {
                            size: fontSize
                        }
                    },
                    type: "linear",
                    position: "right",
                    ticks: {
                        maxTicksLimit: 6,
                        font: {
                            size: fontSize
                        }
                    }
                }

            },
            animation: {
                duration: 0
            },
            plugins: {

                legend: {
                    labels: {
                        font: {
                            size: fontSize
                        },
                    }
                },
                datalabels: {
                    display: false
                }
            }
        };

        return <Bar data={data} options={options} ref={chartRef} />;
    }
);
